import { useState } from "react";
import { useDispatch } from "react-redux";

import axiosInstance from "../../Fbaseurl";
import Password from "../components/CommonComponents/Password";
import Button from "../components/CommonComponents/Button";


import "./index.css";
import LoadingButton from '@mui/lab/LoadingButton';
import resetPwdLogo from "../../../src/assets/resetPwdLogo.svg";
import Logo from "../../../src/assets/Taxraven.svg";
import HrlogicsLogo from "../../assets/HrLogicsLogo.svg"
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// import InputField from "../components/CommonComponents/InputField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { useMediaQuery } from '@mui/material';
import { showToast } from "../../store/reducers/ToastMessageReducer";

const ResetPassword = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const url = window.location.href;
  const searchParams = new URLSearchParams(new URL(url).search);
  let resetCode = searchParams.get("resetCode");
  let emailAddress = searchParams.get("emailAddress");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [firstRuleColor, setFirstRuleColor] = useState('#4E4F61');
  const [secondRuleColor, setSecondRuleColor] = useState('#4E4F61');
  const [isPwdNotMatched, setIsPwdNotMatched] = useState(false);
  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(  
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
        "Password must be alphanumeric"
      ),
    crmPassword: Yup.string()
      .required("Confirm Password is required")
      .min(8, "Confirm Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/,
        "Confirm Password must be alphanumeric"
      ),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      crmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async () => {
      if (isLoading) {
        return;
      }
      if (Object.keys(formik.errors).length === 0) {
        if(formik.values.password !== formik.values.crmPassword) {
          setIsPwdNotMatched(true)
          return
        }
        setIsPwdNotMatched(false)
        setLoader(true);
      
        try {
          const response = await axiosInstance.post("/account/resetPassword", {
            email: emailAddress,
            resetCode: resetCode,
            newPassword: formik.values.crmPassword,
          });
          if (response) {
            navigate("/");
          }
          setLoader(false);
        } catch (error) {
          if (error.response.data.errors.InvalidToken) {
            dispatch(
              showToast({
                type: "error",
                message:
                  "This link is no longer valid",
              })
            );
          }
          console.log(error);
          setLoader(false);
        }
      }
    },
  });

  const pwdValidation = (e) => {
    validatePassword();
    let val = e.target.value;
    if(val.length) {
      const regEx =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
      let isValid = regEx.test(val);
      let name = emailAddress?.split('@')
      let userName = name && name.length ? name[0] : '';
      let isValidUserName = val !== userName;
      if(isValid && (isValidUserName)) {
         setFirstRuleColor("green");
         setSecondRuleColor("green")
      } else if(isValid && !isValidUserName) {
        setFirstRuleColor("green");
        setSecondRuleColor("red")
      } else if (!isValid && isValidUserName) {
        setFirstRuleColor("red");
        setSecondRuleColor("green")
      } else if(!isValid && !isValidUserName) {
        setFirstRuleColor("red");
        setSecondRuleColor("red")
      }
    } 
    else {
      setFirstRuleColor("#4E4F61");
      setSecondRuleColor("#4E4F61")
    }
  };
  const validatePassword = () => {
    if(formik.values.password.length > 0 && formik.values.crmPassword.length > 0) {
      if(formik.values.password !== formik.values.crmPassword) {
        setIsPwdNotMatched(true)
        return
      }
    }
    setIsPwdNotMatched(false)
  }

  

  return (
    <div className="login-wrapper">
      <div className="sm-row login-container d-lg-flex d-md-flex justify-content-between position-absolute fixed-bottom h-100">
        {!isMobile && (
          <div className="col-lg-7 col-md-7 col-sm-12 left-container text-center overflow-hidden align-content-end">
            {/* <img src={HrlogicsLogo} className="hrlogicsLogo"></img> */}
            <img
              className="login-img"
              src={resetPwdLogo}
              alt="super-admin-login"
            />
          </div>
        )}
        <div className="col-lg-5 col-md-5 col-sm-12 right-container p-4">
          <div className="d-flex flex-column justify-content-around  p-3 h-100 ">
            <div>
              <img src={Logo} alt="logo" />
            </div>
            <div>
              {/* <h1 className="form-header fw-bold mb-3 lh-1">
                Reset Password ?
              </h1> */}
              <h1 className="text-blue title p-0 fw-bold mb-3 lh-1">
                Reset Password ?
              </h1>
              <form
                onSubmit={formik.handleSubmit}
                className="d-flex flex-column form-group"
              >
                <Password
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  label="Password"
                  name="password"
                  onBlur={pwdValidation}
                />
                {/* <InputField
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  title="Password"
                  type="password"
                /> */}
                {formik.touched.password && formik.errors.password && (
                  <div className="error">{formik.errors.password}</div>
                )}
                {formik.values.password && formik.values.crmPassword && isPwdNotMatched && (
                  <div className="error">Password must be matched</div>
                )}
                <div className="my-4">
                  <Password
                    value={formik.values.crmPassword}
                    onChange={formik.handleChange}
                    label="Confirm Password"
                    name="crmPassword"
                    onBlur={validatePassword}
                  />
                  {formik.touched.crmPassword && formik.errors.crmPassword && (
                    <span className="error">{formik.errors.crmPassword}</span>
                  )}
                  {formik.values.password && formik.values.crmPassword && isPwdNotMatched && (
                    <div className="error">Password must be matched</div>
                  )}
                </div>
                {/* <InputField
                  name="crmPassword"
                  value={formik.values.crmPassword}
                  onChange={formik.handleChange}
                  title="Confirm Password"
                  type="password"
                /> */}

                <Card
                  className="MuiCard-root "
                  sx={{ backgroundColor: "#F8F9FB" }}
                >
                  <CardContent>
                    <div className="font-12 font-500">
                      Your password must have:
                    </div>
                    <div style={{ color: firstRuleColor }} className="font-12">
                      <CheckCircleOutlinedIcon
                        fontSize="small"
                        sx={{ height: "14px", width: "14px" }}
                      ></CheckCircleOutlinedIcon>{" "}
                      Must contain atleast 8 characters, one uppercase letter,
                      one lowercase letter, one digit, one special character
                      (e.g.,!@#$%^&*){" "}
                    </div>
                    <div style={{ color: secondRuleColor }} className="font-12">
                      <CheckCircleOutlinedIcon
                        fontSize="small"
                        sx={{ height: "14px", width: "14px" }}
                      ></CheckCircleOutlinedIcon>{" "}
                      Must not be the same as the username
                    </div>
                  </CardContent>
                </Card>
                <div className="row">
                  <div className="d-flex justify-content-end mt-2">
                    {/* <button
                      className={`submit-btn border-0 fw-bold px-2 py-1`}
                      type="submit"
                      role="status"
                    >
                      {isLoading ? (
                        <div
                          className="spinner-border spinner-border-sm mx-2 p-1"
                          role="status"
                        ></div>
                      ) : (
                        "Submit"
                      )}
                    </button> */}
                    <Button
                      label="Submit"
                      className="fw-bold"
                      type="submit"
                      color="primary"
                      variant="contained"
                      isLoading={isLoading}
                      containerClass={isMobile ? "w-100" : ""}
                      sx={isMobile ? { marginTop: "40%" } : ""}
                    />
                  </div>
                  <div
                    className={`d-flex  ${
                      isMobile
                        ? "justify-content-center mt-2"
                        : "justify-content-end mt-4"
                    }`}
                  >
                    <button
                      className="border-0 bg-white"
                      onClick={() => navigate("/")}
                    >
                      Go to login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
