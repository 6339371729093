import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import React, { lazy, Suspense, useEffect } from "react";

//import ForgotPassword from './ForgotPassword';
import ForgotPassword from "../src/pages/ForgotPassword";
import OtpVerification from "../src/pages/OtpVerification";
// import BlogsComp from "../src/pages/SalesPersonUser/Blogs";

import ReviewDetails from "./pages/SalesPersonUser/ReviewDetails";

import Login from "./pages/Login";
import TaxCreditAnalysis from "./pages/SalesPersonUser/TaxCreditAnalysis";
import CreateTaxCredits from "./pages/SuperAdmin/CreateTaxCredits";
import UserSetUp from "./pages/SuperAdmin/UserSetUp";
import ResetPassword from "./pages/ResetPassword";
import AppLayout from "./pages/AppLayout";
import Header from "./pages/components/CommonComponents/Header";
import Dashboard from "./pages/SuperAdmin/Dashboard";
import UpdateTaxCredits from "./pages/SuperAdmin/UpdateTaxCredits";
import CreditMapGenerator from "./pages/SalesPersonUser/CreditMapGenerator";
import Blogs from "./pages/SalesPersonUser/Blogs";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import FindBusiNavTabs from "./pages/SalesPersonUser/findBusiNavTab";
import CompanySetUpForm from "./pages/SuperAdmin/CompanySetUp";
import { useSelector, useDispatch } from "react-redux";
import ProtectedRoute from "./ProtectedRoute";

import {
  setIsAuthorised,
  setAuthorisedUser,
} from "./store/reducers/AppReducer";

import LoginLayout from "./pages/LoginLayout";

function App() {
  const dispatch = useDispatch();

  // const ProtectedRoute = (Module, allowedRoles) => {
  //   const data = localStorage.getItem("user");
  //   const user = JSON.parse(data);
  //   console.log(user);
  //   let role;
  //   let authorized;
  //   if (user) {
  //     dispatch(setIsAuthorised(true));
  //     dispatch(setAuthorisedUser(user));
  //     authorized = true;
  //     role = user?.roles[0] || "";
  //   }
  //   if (authorized) {
  //     if (allowedRoles.includes(role)) {
  //       return (
  //         <Suspense fallback={<>...Loading</>}>
  //           <Module />
  //         </Suspense>
  //       );
  //     } else {
  //       return <Navigate to="/" />;
  //     }
  //   } else {
  //     return <Navigate to="/" />;
  //   }
  // };

  const router = createBrowserRouter([
    {
      path: "/",
      //  element: ProtectedRoute(Dashboard, ["SuperAdmin"]),
      element: <LoginLayout></LoginLayout>,
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<>...Loading</>}>
              <Login />
            </Suspense>
          ),
        },

        {
          path: "/forgotPassword",
          element: (
            <Suspense fallback={<>...Loading</>}>
              <ForgotPassword />
            </Suspense>
          ),
        },
        {
          path: "/OtpVerification",
          element: (
            <Suspense fallback={<>...Loading</>}>
              <OtpVerification />
            </Suspense>
          ),
        },

        {
          path: "/resetpassword",
          element: (
            <Suspense fallback={<>...Loading</>}>
              <ResetPassword />
            </Suspense>
          ),
        },
      ],
    },

    {
      path: "/superadmin",
      //  element: ProtectedRoute(Dashboard, ["SuperAdmin"]),
      element: (
        <ProtectedRoute
          element={AppLayout}
          allowedRoles={["SuperAdmin", "Salesperson"]}
        />
      ),
      children: [
        {
          path: "/superadmin/dashboard/:tab",
          element: (
            <ProtectedRoute element={Dashboard} allowedRoles={["SuperAdmin"]} />
          ),
          // element: <Dashboard></Dashboard>
        },
        {
          path: "/superadmin/companySetup",
          element: (
            <ProtectedRoute
              element={CompanySetUpForm}
              allowedRoles={["SuperAdmin"]}
            />
          ),
        },
        {
          path: "/superadmin/company/:type/:id",
          element: (
            <ProtectedRoute
              element={CompanySetUpForm}
              allowedRoles={["SuperAdmin"]}
            />
          ),
        },
        {
          path: "/superadmin/usersetup",
          element: (
            <ProtectedRoute element={UserSetUp} allowedRoles={["SuperAdmin"]} />
          ),
        },
        {
          path: "/superadmin/user/:type/:id",
          element: (
            <ProtectedRoute element={UserSetUp} allowedRoles={["SuperAdmin"]} />
          ),
        },
        {
          path: "/superadmin/createTaxCredit",
          element: (
            <ProtectedRoute
              element={UpdateTaxCredits}
              allowedRoles={["SuperAdmin"]}
            />
          ),
        },
        {
          path: "/superadmin/taxCredit/:type/:id",
          element: (
            <ProtectedRoute
              element={UpdateTaxCredits}
              allowedRoles={["SuperAdmin"]}
            />
          ),
        },
      ],
    },
    {
      path: "/salesperson",
      element: (
        <ProtectedRoute
          element={AppLayout}
          allowedRoles={["SuperAdmin", "Salesperson"]}
        />
      ),
      // element: ProtectedRoute(ReviewDetails, allowedRoles={["Salesperson","SuperAdmin"]} />,
      children: [
        {
          path: "/salesperson/findBusinavTab",
          element: (
            <ProtectedRoute
              element={FindBusiNavTabs}
              allowedRoles={["Salesperson", "SuperAdmin"]}
            />
          ),
          // element: <FindBusiNavTabs></FindBusiNavTabs>
        },
        //{
        //  path: "/salesperson/reviewPage",
        //  element: (
        //    <ProtectedRoute
        //      element={ReviewDetails}
        //      allowedRoles={["Salesperson", "SuperAdmin"]}
        //    />
        //  ),
        //},
        {
          path: "/salesperson/generateCredit",
          element: (
            <ProtectedRoute
              element={CreditMapGenerator}
              allowedRoles={["Salesperson", "SuperAdmin"]}
            />
          ),
          // element: ProtectedRoute(GenerateCreditMap, ["Salesperson", "SuperAdmin"]),
        },
        {
          path: "/salesperson/blogs",
          element: (
            <ProtectedRoute
              element={Blogs}
              allowedRoles={["Salesperson", "SuperAdmin"]}
            />
          ),
        },

        {
          path: "/salesperson/taxCreditAnalysis",
          element: (
            <ProtectedRoute
              element={TaxCreditAnalysis}
              allowedRoles={["Salesperson", "SuperAdmin"]}
            />
          ),
        },
        
      ],
    },

    {
      path: "/companyadmin",
      //  element: ProtectedRoute(Dashboard, ["SuperAdmin"]),
      element: (
        <ProtectedRoute
          element={AppLayout}
          allowedRoles={[
            "SuperAdmin",
            "Salesperson",
            "CompanyAdmin",
            "Manager",
          ]}
        />
      ),
      children: [
        {
          path: "/companyadmin/dashboard/:tab",
          element: (
            <ProtectedRoute
              element={Dashboard}
              allowedRoles={[
                "SuperAdmin",
                
                "CompanyAdmin",
                "Manager",
              ]}
            />
          ),
          // element: <Dashboard></Dashboard>
        },
        {
          path: "/companyadmin/usersetup",
          element: (
            <ProtectedRoute
              element={UserSetUp}
              allowedRoles={[
                "SuperAdmin",
                "CompanyAdmin",
                "Manager",
              ]}
            />
          ),
        },
        {
          path: "/companyadmin/user/:type/:id",
          element: (
            <ProtectedRoute
              element={UserSetUp}
              allowedRoles={["SuperAdmin", "CompanyAdmin", "Manager"]}
            />
          ),
        },
        {
          path: "/companyadmin/blogs",
          element: (
            <ProtectedRoute
              element={Blogs}
              allowedRoles={["CompanyAdmin", "Manager"]}
            />
          ),
        },

        {
          path: "/companyadmin/taxCreditAnalysis",
          element: (
            <ProtectedRoute
              element={TaxCreditAnalysis}
              allowedRoles={["CompanyAdmin", "Manager"]}
            />
          ),
        },
      ],
    },

    {
      path: "/manager",
      //  element: ProtectedRoute(Dashboard, ["SuperAdmin"]),
      element: (
        <ProtectedRoute
          element={AppLayout}
          allowedRoles={[
            "SuperAdmin",
            "Salesperson",
            "CompanyAdmin",
            "Manager",
          ]}
        />
      ),
      children: [
        {
          path: "/manager/dashboard/:tab",
          element: (
            <ProtectedRoute
              element={Dashboard}
              allowedRoles={[
                "SuperAdmin",
                "Salesperson",
                "CompanyAdmin",
                "Manager",
              ]}
            />
          ),
          // element: <Dashboard></Dashboard>
        },
        {
          path: "/manager/usersetup",
          element: (
            <ProtectedRoute
              element={UserSetUp}
              allowedRoles={[
                "SuperAdmin",
                "Salesperson",
                "CompanyAdmin",
                "Manager",
              ]}
            />
          ),
        },
        {
          path: "/manager/user/:type/:id",
          element: (
            <ProtectedRoute
              element={UserSetUp}
              allowedRoles={[
                "SuperAdmin",
                "Salesperson",
                "CompanyAdmin",
                "Manager",
              ]}
            />
          ),
        },
        {
          path: "/manager/blogs",
          element: (
            <ProtectedRoute
              element={Blogs}
              allowedRoles={["CompanyAdmin", "Manager"]}
            />
          ),
        },

        {
          path: "/manager/taxCreditAnalysis",
          element: (
            <ProtectedRoute
              element={TaxCreditAnalysis}
              allowedRoles={["CompanyAdmin", "Manager"]}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: (
        <Suspense fallback={<>...Loading</>}>
          <div>404 Not Found</div>
        </Suspense>
      ),
    },
  ]);

  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
