import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Fbaseurl";
import useDebounce from "../../../Hooks/useDebounce";
import { setSaCompanies } from "../../../store/reducers/SaCompanyReducer";
import { showToast } from "../../../store/reducers/ToastMessageReducer";
import SelectComp from "../../components/CommonComponents/SelectComp";
import { fetchStates } from "../../../store/reducers/TaxCreditsReducer";



import "./index.css";
import { useNavigate, useLocation } from "react-router-dom";
import CustomTable from "../../components/CommonComponents/Table";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "../../components/CommonComponents/Button";
import Search from "../../components/CommonComponents/Search";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationPopup from "../../components/CommonComponents/ConfirmationPopup";
import PersonDeactivate from "../../../assets/PersonDeactivate.svg";
import PersonActivate from "../../../assets/PersonActivate.svg";
import CircularProgress from "@mui/material/CircularProgress";
const TaxCredtisList = () => {
  const {
    statesList
  } = useSelector((state) => state.TaxCredits);
  const updatedStatesList = [{ id: 'Federal', key: 'Federal' }, ...statesList];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = Number(queryParams.get('page')) ;
  const [selectedPage, setSelectedPage] = useState(page || 1);
  const [searchTaxCredit, setSearchTaxCredit] = useState("");
  const [totalPages, setTotalPages] = useState(null);
  const debouncedSearchKey = useDebounce(searchTaxCredit, 500);
  const [taxCreditsList, setTaxCreditsList] = useState([]);
  const [dataFetchStatus, setDataFetchStatus] = useState("");
  // const [sortingType, setSroting] = useState(false);

  const [currentTaxCredit, setCurrentTaxCredit] = useState(null);
  const [confirmationContent, setConfirmationContent] = useState("");
  const [isPopupOpen, setPopup] = useState(false);
  const [selectedStates, setSelectedStates] = useState([]);

  const getTaxCreditsList = async (payload = {}, searchValue) => {
    setDataFetchStatus("TRIGGERED");
    const payloadWithSearch = {
      page: selectedPage,
      pageSize: 10,
      // search: debouncedSearchKey,
      ...payload,
    };
    if (debouncedSearchKey && debouncedSearchKey.length > 2) {
      payloadWithSearch.keyword = debouncedSearchKey;
    }
    try {
      const response = await axiosInstance.get(`/taxCredits?${searchValue}`, {
        params: payloadWithSearch,
      });
      if (response.status === 200) {
        let data = groupingByStates(response.data.items);
        const updatedTaxCredtisList = data.reduce((acc, cur) => {
          if (cur) {
            acc.push({
              id: cur.id,
              creditName: cur.name,
              state: cur.states[0]?.name || "Federal",
              status: cur.isActive ? "Active" : "Inactive",
              isActive: cur.isActive,
              // actions: () => navigate(`/superadmin/company/:${cur.companyId}`),
              actions: "",
            });
          }
          return acc;
        }, []);
        setTotalPages(response.data.totalPages);
        setTaxCreditsList(updatedTaxCredtisList);
        setDataFetchStatus("FETCHED_SUCCESSFULLY");
        if(selectedStates.length > 0){
          removeOtherStatesData(updatedTaxCredtisList)
        }
      } else {
        setTaxCreditsList([]);
        setTotalPages(null);
        setDataFetchStatus("FAILED_TO_FETCH");
      }
    } catch (error) {
      let errorMsg = error?.response?.data?.title;
      let msg = errorMsg
        ? errorMsg
        : `Failed to fetch the data. Please try again after some time`;
      dispatch(
        showToast({
          type: "error",
          message: msg,
        })
      );
      console.log(error);
      setDataFetchStatus("FAILED_TO_FETCH");
    }
  };

  const groupingByStates = (data) => {
    if(data && data.length > 0) {

      const noStates = data.filter((item) => item.states.length === 0);
  
      const withStates = data
        .filter((item) => item.states.length > 0)
        .flatMap((item) =>
          item.states.map((state) => ({
            ...item,
            states: [state],
          }))
        );
  
      withStates.sort((a, b) => a.states[0].name.localeCompare(b.states[0].name));
  
      const result = [...noStates, ...withStates];
  
      return result;
    } else {
      return [];
    }
  };

  const removeOtherStatesData = (data) => {
   const updatedData =  data.filter((item,index)=>{
      return selectedStates.includes(item.state)
    })
    console.log('updatedData', updatedData)
    setTaxCreditsList(updatedData)
  }

  const editHandling = (row) => {
    navigate(`/superadmin/taxCredit/edit/${row.id}?page=${selectedPage}`);
  };

  const navigateToView = (row) => {
    navigate(`/superadmin/taxCredit/view/${row.id}?page=${selectedPage}`);
  };

  const activationHandling = async (row) => {
    let payload = {
      isActive: !currentTaxCredit.isActive,
    };
    try {
      const response = await axiosInstance.patch(
        `/taxCredits/${currentTaxCredit.id}`,
        payload
      );
      if (response.status === 200) {
        let status = !currentTaxCredit.isActive ? "Active" : "Inactive";
        dispatch(
          showToast({
            message: `${currentTaxCredit.creditName} ${
              currentTaxCredit.isActive ? "inactivated" : "activated"
            } successfully!`,
          })
        );
        setTaxCreditsList((prevCompanies) => {
          return prevCompanies.map((company) =>
            company.id === currentTaxCredit.id
              ? {
                  ...company,
                  isActive: !currentTaxCredit.isActive,
                  status: status,
                }
              : company
          );
        });
      }
    } catch (error) {
      console.log(error);
      let errorMsg = error?.response?.data?.title;
      let msg = errorMsg
        ? errorMsg
        : `Failed to ${currentTaxCredit.isActive ? "deactivate" : "activate"} ${
            currentTaxCredit.creditName
          }`;
      dispatch(
        showToast({
          type: "error",
          message: msg,
        })
      );
    }
    setPopup(false);
  };

  const confirmActivation = (row) => {
    setCurrentTaxCredit(row);
    setPopup(true);
    let title = row.isActive
      ? "Are you sure you want to deactivate ?"
      : "Are you sure you want activate ?";
    setConfirmationContent(title);
  };

  const onCancelClick = () => {
    setPopup(false);
  };

  const setSearchValue = (e) => {
    setSearchTaxCredit(e.target.value);
    if (!e.target.value) {
      setSelectedPage(1);
    }
  };

  const dataSorting = (sorting = false) => {
    let sortedList;
    if (sorting) {
      sortedList = taxCreditsList.sort((a, b) => {
        if (a.creditName < b.creditName) {
          return -1;
        }
        if (a.creditName > b.creditName) {
          return 1;
        }
        return 0;
      });
    } else {
      sortedList = taxCreditsList.sort((a, b) => {
        if (a.creditName < b.creditName) {
          return 1;
        }
        if (a.creditName > b.creditName) {
          return -1;
        }
        return 0;
      });
    }
    setTaxCreditsList(sortedList);
  };

  const fetchTaxcreditsBasedOnStates = () => {
    const params = convertStatesIntoParams();
    if(selectedPage !== 1) {
      setSelectedPage(1) 
      return
    }
    getTaxCreditsList({page: 1}, params.toString());
  };

  const convertStatesIntoParams = () => {
    const params = new URLSearchParams();
    selectedStates.forEach((value) => {
      params.append("states", value);
    });
    return params;
  };
  useEffect(() => {
    if (!debouncedSearchKey.length) {
      // getTaxCreditsList();
      if(selectedStates.length > 0) {
        var params =  convertStatesIntoParams()
        }
        getTaxCreditsList({},params? params.toString(): '');
    }
  }, [debouncedSearchKey]);

  useEffect(() => {
    if(selectedStates.length > 0) {
    var params =  convertStatesIntoParams()
    }
    getTaxCreditsList({},params? params.toString(): '');
    dispatch(fetchStates())
    navigate(`/superadmin/dashboard/taxcredits?page=${selectedPage}`);
  }, [selectedPage]);



  const applySearch = () => {
    if (searchTaxCredit.length > 2 || !searchTaxCredit) {
      setSelectedPage(1);
      // getTaxCreditsList();
      if(selectedStates.length > 0) {
        var params =  convertStatesIntoParams()
        }
        getTaxCreditsList({},params? params.toString(): '');
    }
  };

  return (
    <div className="table-content">
      {dataFetchStatus === "FETCHED_SUCCESSFULLY" && (
        <>
          <div className="filters mb-3 d-lg-flex d-md-flex justify-content-between align-items-center">
            <div className="col-4 d-flex flex-column flex-md-row  justify-content-between gap-3 mb-4 mb-md-0 mb-lg-0">
              <Search
                value={searchTaxCredit}
                onChange={(e) => setSearchValue(e)}
                onClick={applySearch}
              />
              <SelectComp
                className="col-1"
                title="States"
                value={selectedStates}
                options={updatedStatesList}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setSelectedStates(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                name="states"
                isRequired={false}
                isMultiSelect={true}
                onClose={() => fetchTaxcreditsBasedOnStates()}
                optionValue="key"
              ></SelectComp>
            </div>
            <Button
              label="Add New"
              icon={<AddCircleOutlineIcon />}
              type="button"
              color="primary"
              onClick={() => navigate("/superadmin/createTaxCredit")}
            />
          </div>
          <CustomTable
            name="comapaniesList"
            columns={{
              creditName: "Credit Name",
              state: "State",
              status: "Status",
              actions: "Actions",
            }}
            data={taxCreditsList}
            showCount={false}
            totalPages={totalPages}
            pagination={true}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            title="Tax credit"
            editHandling={editHandling}
            activationHandling={confirmActivation}
            handleRowClick={navigateToView}
          />
        </>
      )}

      {/* sorting = {dataSorting} */}
      {dataFetchStatus === "TRIGGERED" && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
      <ConfirmationPopup
        isPopupOpen={isPopupOpen}
        content={confirmationContent}
        icon={currentTaxCredit?.isActive ? PersonDeactivate : PersonActivate}
        onCancel={onCancelClick}
        onConfirm={activationHandling}
      ></ConfirmationPopup>
    </div>
  );
};

export default TaxCredtisList;
