import { useState, useEffect } from "react";
import axiosInstance from "../../Fbaseurl";
import { setSelectedTab } from "../../store/reducers/SuperAdminReducer";
import { showToast } from "../../store/reducers/ToastMessageReducer";
import Password from "../components/CommonComponents/Password";

import LoadingButton from '@mui/lab/LoadingButton';
import Button from "../components/CommonComponents/Button";
import {setSalesPersonSelectedTab} from "../../store/reducers/SalespersonReducer";


import "./index.css";
import loginLogo from "../../../src/assets/loginLogo.svg";
import Logo from "../../../src/assets/Taxraven.svg";
import HrlogicsLogo from "../../assets/HrLogicsLogo.svg"
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputField from "../components/CommonComponents/InputField";
import ReCaptcha from "../components/CommonComponents/ReCaptcha";
import { useDispatch } from "react-redux";
import { useMediaQuery } from '@mui/material';

import {
  setAuthorisedUser,
  setCurrentRole,
  setIsAuthorised,
  setUserName,
} from "../../store/reducers/AppReducer";

const Login = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginInfo, setLoginInfo] = useState()
 
    const loginSchema = Yup.object().shape({
        email: Yup.string().trim().required("Email is required").matches(/.+@.+\..+/, "Invalid email"),
   /* email: Yup.string().email("Invalid email").required("Email is required"),*/
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/,
        "Password must be alphanumeric"
      ),
      recaptcha: Yup.boolean().oneOf([true], "ReCaptcha must be checked").required("ReCaptcha is required")
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      recaptcha: false
    },
    validationSchema: loginSchema,
    onSubmit: async () => {
      if (isLoading) {
        return;
      }
      if (Object.keys(formik.errors).length === 0) {
        setLoader(true);
        try {
          const response = await axiosInstance.post("/account/login", {
            email: formik.values.email,
            password: formik.values.password,
           
          }, {params: {
            useCookies: false
          }});
          if (response) {
            localStorage.setItem('accessToken',response.data.accessToken);
            localStorage.setItem('refreshToken', response.data.refreshToken)
            // if (response.data.isLockedOut) {
            //   dispatch(
            //     showToast({
            //       type: "error",
            //       message:
            //       "Account locked. Please wait for 30 minutes before trying again."
            //         // "Your account is locked. Please try again after some time.",
            //     })
            //   );
            //   setLoader(false);
            //   return;
            // }
            // setLoginInfo(response.data)
            fetchUserInfo();
           
            // localStorage.setItem('Permissions', JSON.stringify(response.data.permissions));
            console.log('login',response)
          
            // localStorage.setItem("currentRole",response.data.roles[0])
            dispatch(setIsAuthorised(true));
            // dispatch(setCurrentRole(response.data.roles[0]))
            // if (response.data.roles[0] === "SuperAdmin") {
            //   dispatch(setAuthorisedUser(response.data));
            //   dispatch(setSelectedTab(0));

            //   navigate("superadmin/dashboard");
            // } else {
            //   navigate("salesPerson/findBusinavTab");
            // }
          }
          setLoader(false);
        } catch (error) {
          let errorMsg = error?.response?.data?.detail;
          let message = errorMsg === 'LockedOut' ? 'Your account is locked. Please try again after some time.' : 'Invalid credentials'
          dispatch(
            showToast({
              type: "error",
              message: message
            })
            );
            console.log(error);
          setLoader(false);
        }
      }
    },
  });
  const [isLoading, setLoader] = useState(false);
  const handleCaptchaData = (data) => {
    formik.setFieldValue("recaptcha", data);
  };

  const fetchUserInfo = async () => {
    try {
      const response = await axiosInstance.get(`/users/${formik.values.email}`);
      if (response.status === 200) {
        let userName = `${response.data.firstName} ${response?.data?.lastName}`;
        localStorage.setItem("userName", userName);
        localStorage.setItem("userId",response.data.userName)
        localStorage.setItem("companyAssigned",response.data.companyId )
        localStorage.setItem("user", JSON.stringify(response.data));
        dispatch(setUserName(userName));
        getCurrentRole();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentRole = async () => {
    try {
      const response = await axiosInstance.get(`/users/currentRole`);
      const userInfo = JSON.parse(localStorage.getItem('user'));
      const userRole = userInfo.roles[0];
      if (response) {
        if (response.data !== 'None') {
          localStorage.setItem("currentRole", response.data);
          dispatch(setCurrentRole(response.data));
        } else {
          localStorage.setItem("currentRole", userRole);
          dispatch(setCurrentRole(userRole));
        }
        const role = response.data !=='None' ? response.data : userRole;
        dispatch(setSelectedTab(0));
        dispatch(setSalesPersonSelectedTab(0)); 
        switch (role) {
          case "SuperAdmin":
            return navigate("superadmin/dashboard/companies");
          case "Salesperson":
            return navigate("salesperson/findBusinavTab");
          case "Manager":
            return navigate("manager/dashboard/users");
          case "CompanyAdmin":
            return navigate("companyadmin/dashboard/users");
          default:
            return "";
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="login-wrapper">
      <div className="sm-row login-container d-lg-flex d-md-flex justify-content-between position-absolute fixed-bottom h-100">
       {!isMobile && <div className="col-lg-7 col-md-7 col-sm-12 left-container text-center overflow-hidden align-content-end">
          {/* <img src={HrlogicsLogo} className="hrlogicsLogo"></img> */}
          <img className="login-img" src={loginLogo} alt="super-admin-login" />
        </div> }
        <div className="col-lg-5 col-md-5 col-sm-12 right-container d-flex p-4">
          <div className="form-container d-flex flex-column justify-content-around p-3">
            <div>
              <img src={Logo} alt="logo" />
            </div>
            <div className="form-feilds">
              <h1 className="text-blue title p-0 fw-bold mb-3 lh-1">Login</h1>
              <form
                onSubmit={formik.handleSubmit}
                className="d-flex flex-column gap-4"
              >
                <InputField
                  placeholder="Email Address"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  title="Email address"
                  type="email"
                />

                {formik.touched.email && formik.errors.email && (
                  <div className="error ">{formik.errors.email}</div>
                )}
                <Password
                  placeholder="********"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  label="Password"
                  name="password"
                  type="password"
                />
                {/* <InputField
                  placeholder="********"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  title="Password"
                  type="password"
                /> */}

                {formik.touched.password && formik.errors.password && (
                  <div className="error">{formik.errors.password}</div>
                )}
                <div className="row">
                  <div className={!isMobile ? `d-flex justify-content-between` : ''}>
                    <ReCaptcha
                      name="recaptcha"
                      value={formik.values.recaptcha}
                      onDataReceived={handleCaptchaData}                     
                      setLoader={setLoader}
                    />

                    <div className="float-right" >
                      <button
                        className="border-0 bg-white text-navy-blue"
                        onClick={() => navigate("/forgotpassword")}
                      >
                        Forgot your password?{" "}
                      </button>
                    </div>
                  </div>
                  {formik.touched.recaptcha && formik.errors.recaptcha && (
                    <div className="error ">{formik.errors.recaptcha}</div>
                  )}
                  <div className="d-flex justify-content-end mt-2">
                    <Button
                      label="Login"
                      className="fw-bold"
                      type="submit"
                      color="primary"
                      variant="contained"
                      isLoading={isLoading}
                      containerClass={isMobile ? 'w-100' : ''}
                      sx={isMobile ? {marginTop: '40%'} : ''}
                    />
                  </div>
                </div>
              </form>

               {!isMobile && <p className="note">
                Note: This service researches tax credits available to
                businesses based on locations and headcounts
              </p> }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
