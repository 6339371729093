import {useEffect} from "react";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";


import UsersList from "../UsersList";
import Reports from "../Reports";
import TaxCreditsList from "../TaxCreditsList";
import Header from "../../components/CommonComponents/Header";
import TabsComponent from "../../components/CommonComponents/Tabs";
import CompaniesList from "../CompaniesList";
import { setSelectedTab } from "../../../store/reducers/SuperAdminReducer";
import ReviewDetails from "../../SalesPersonUser/ReviewDetails";
import CreditMapGenerator from "../../SalesPersonUser/CreditMapGenerator";


import "./index.css";
import companyIcon from "../../../assets/company.svg";
import handIcon from "../../../assets/hand.png";
import usersIcon from "../../../assets/users.svg";
import reportsIcon from "../../../assets/reports.svg";
import taxCreditsIcon from "../../../assets/taxCredits.svg";
import FindBusiness from "../../../assets/FindBusiness.svg";
import GenerateMap from "../../../assets/GenerateMap.svg";
import { useSelector } from "react-redux";

const SuperAdminDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { tab } = useParams();
  const { selectedTab } = useSelector((state) => state.superAdmin);
  const currentRole = useSelector((state)=> state.App.currentRole)
  const name = useSelector((state) => state.App.userName);

  const SuperAdminTabs = [
    {
      name: "Companies",
      img: <img src={companyIcon} alt="companyIcon" />,
      key: 0,
      tab: "companies"
    },
    { name: "Users", img: <img src={usersIcon} alt="usersIcon" />,
    key: 1,
    tab: "users" },
    {
      name: "Reports",
      img: <img src={reportsIcon} alt="reportsIcon" />,
      key: 2,
      tab: "reports"
    },
    {
      name: "Tax Credits",
      img: <img src={taxCreditsIcon} alt="taxCredtisIcon" />,
      key: 3,
      tab: "taxcredits"
    },
  ];

  const companyAdminTabs = [
    { name: "Users", img: <img src={usersIcon} alt="usersIcon" />,
    key:0,
    tab: "users" },
    {
      name: "Reports",
      img: <img src={reportsIcon} alt="reportsIcon" />,
      key: 1,
      tab: "reports"
    },
    {
      name: "Find a Business",
      img: <img src={FindBusiness} alt="findBusiness" />,
      key: 2,
      tab: 'findbusiness'
    },
    {
      name: "Generate a Credit Map",
      img: <img src={GenerateMap} alt="generateMap" />,
      key: 3,
      tab: 'generatecreditmap'
    },
  ];

  const renderTabs = ()=> {
    if(currentRole === 'SuperAdmin') {
      return SuperAdminTabs
    } else {
      return companyAdminTabs
    }
  }

  const getGreeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return "Good Morning!";
    } else if (currentHour < 18) {
      return "Good Afternoon!";
    } else {
      return "Good Evening!";
    }
  };
  const findComponent = () => {
    if(currentRole === 'SuperAdmin') {

      if (tab === "companies") {
        return <CompaniesList></CompaniesList>;
      } else if (tab === "users") {
        return <UsersList></UsersList>;
      } else if (tab === "reports") {
        return <Reports></Reports>;
      } else if (tab === "taxcredits") {
        return <TaxCreditsList></TaxCreditsList>;
      }
    } else {
      if (tab === "users") {
        return <UsersList></UsersList>;
      } else if (tab === "reports") {
        return <Reports></Reports>;
      } else if(tab === 'findbusiness') {
        return <ReviewDetails></ReviewDetails>
      } else if(tab === 'generatecreditmap') {
        return <CreditMapGenerator></CreditMapGenerator>
      }
    }
  };

  const navFunc = (selectedTab) => {
    const tabs = renderTabs();
  const val =  tabs.find((tab,index) => {
      return index === selectedTab
    });

  navigateToDashboard(val.tab);
  };

  const navigateToDashboard = (tab)=> {
    if (currentRole === "CompanyAdmin") {
      navigate(`/companyadmin/dashboard/${tab}`);
    } else if (currentRole === "Manager") {
      navigate(`/manager/dashboard/${tab}`);
    } else {
      navigate(`/superadmin/dashboard/${tab}`); 
    }
  }

  useEffect(() => {
    const tabs = renderTabs();
    const val =  tabs.find((item,index) => {
        return item.tab === tab;
      });
    console.log(val,'..');
    dispatch(setSelectedTab(val.key));
  }, [tab]);

  return (
    <div className="dashboardContainer d-flex flex-column h-100 flex-grow-1">
      <Header />
      <div className="content-wrapper py-4">
        <div className="col-11 m-auto">
          {/* <div className="mb-3 d-flex align-items-center gap-1">
            <span className="fs-1 fw-bold">Hello {name}</span>
            <img src={handIcon} alt="handIcon" />
            <span className="fs-1">{getGreeting()}</span>
          </div> */}
          <TabsComponent
            tabs={renderTabs()}
            withImage={true}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            navFunc={navFunc}
          />
          {findComponent()}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminDashboard;
