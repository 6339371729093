import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Fbaseurl";
import useDebounce from "../../../Hooks/useDebounce";
import { setSaCompanies } from "../../../store/reducers/SaCompanyReducer";
import { showToast } from "../../../store/reducers/ToastMessageReducer";
import ConfirmationPopup from "../../components/CommonComponents/ConfirmationPopup";

import "./index.css";
import { useNavigate, useLocation } from "react-router-dom";
import CustomTable from "../../components/CommonComponents/Table";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "../../components/CommonComponents/Button";
import Search from "../../components/CommonComponents/Search";
import Filter from "../../components/CommonComponents/Filter";
import { useDispatch } from "react-redux";
import PersonDeactivate from "../../../assets/PersonDeactivate.svg";
import PersonActivate from "../../../assets/PersonActivate.svg";
import CircularProgress from "@mui/material/CircularProgress";
const CompaniesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = Number(queryParams.get('page'));
  const [selectedPage, setSelectedPage] = useState(page || 1);
  const [totalPages, setTotalPages] = useState(null);
  const [companiesList, setCompaniesList] = useState([]);
  const [searchCompany, setSearchCompany] = useState("");
  const [showActiveCompanies, setShowActiveCompanies] = useState(false);
  const [showInactiveCompanies, setShowInactiveCompanies] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [confirmationContent, setConfirmationContent] = useState("");
  const [isPopupOpen, setPopup] = useState(false);
  const [dataFetchStatus, setDataFetchStatus] = useState("");
  const debouncedSearchKey = useDebounce(searchCompany, 500);

  const getCompaniesList = async (payload = {}) => {
    setDataFetchStatus("TRIGGERED");
    const payloadWithSearch = {
      page: selectedPage,
      pageSize: 10,
      // search: debouncedSearchKey,
      ...payload,
    };
    if (debouncedSearchKey && debouncedSearchKey.length > 2) {
      payloadWithSearch.companyName = debouncedSearchKey;
    }
    try {
      const response = await axiosInstance.get(`/companies`, {
        params: payloadWithSearch,
      });
      if (response.status === 200) {
        const updatedCompaniesList = response.data.items.reduce((acc, cur) => {
          if (cur) {
            acc.push({
              id: cur.companyId,
              companyName: cur.companyName,
              status: cur.isActive ? "Active" : "Inactive",
              // actions: () => navigate(`/superadmin/company/:${cur.companyId}`),
              actions: "",
              isActive: cur.isActive,
            });
          }
          return acc;
        }, []);
        setTotalPages(response.data.totalPages);
        setCompaniesList(updatedCompaniesList);
        dispatch(setSaCompanies(updatedCompaniesList));
        setDataFetchStatus("FETCHED_SUCCESSFULLY");
      } else {
        setCompaniesList([]);
        setTotalPages(null);
        setDataFetchStatus("FAILED_TO_FETCH");
      }
    } catch (error) {
      let errorMsg = error?.response?.data?.title;
      let msg = errorMsg
        ? errorMsg
        : `Failed to fetch the data. Please try again after some time`;
      dispatch(
        showToast({
          type: "error",
          message: msg,
        })
      );
      console.log(error);
      setDataFetchStatus("FAILED_TO_FETCH");
    }
  };

  useEffect(() => {
    if (!debouncedSearchKey.length) {
      searchData();
    }
  }, [debouncedSearchKey]);

  useEffect(() => {
    let payload = {};
    if (
      (!showActiveCompanies && showInactiveCompanies) ||
      (showActiveCompanies && !showInactiveCompanies)
    ) {
      payload.active = showActiveCompanies;
    }
    getCompaniesList(payload);
    navigate(`/superadmin/dashboard/companies?page=${selectedPage}`);
  }, [selectedPage]);

  const handleApply = () => {
    let payload = {};
    if (debouncedSearchKey) {
      payload.companyName = debouncedSearchKey;
    }
    setSelectedPage(1);
    // let payload = {
    //   search: debouncedSearchKey,
    // };

    if (
      (!showActiveCompanies && showInactiveCompanies) ||
      (showActiveCompanies && !showInactiveCompanies)
    ) {
      payload.active = showActiveCompanies;
    }

   if(selectedPage == 1) {
     getCompaniesList(payload);
   }
  };

  const handleClear = () => {
    if((!showActiveCompanies && showInactiveCompanies) ||
    (showActiveCompanies && !showInactiveCompanies)) {
      if(selectedPage === 1) {
      getCompaniesList();
    }
  }
    setShowActiveCompanies(false);
    setShowInactiveCompanies(false);
    setSelectedPage(1)
  };

  const editHandling = (row) => {
    navigate(`/superadmin/company/edit/${row.id}?page=${selectedPage}`);
  };

  const navigateToView = (row) => {
    navigate(`/superadmin/company/view/${row.id}?page=${selectedPage}`);
  };

  const confirmActivation = (row) => {
    setCurrentCompany(row);
    setPopup(true);
    let title = row.isActive
      ? "Are you sure you want to deactivate ?"
      : "Are you sure you want activate ?";
    setConfirmationContent(title);
  };

  const activationHandling = async (row) => {
    let payload = {
      isActive: !currentCompany.isActive,
    };
    try {
      const response = await axiosInstance.patch(
        `/companies/${currentCompany.id}`,
        payload
      );
      if (response.status === 200) {
        let status = !currentCompany.isActive ? "Active" : "Inactive";
        dispatch(
          showToast({
            message: `${currentCompany.companyName} ${
              currentCompany.isActive ? "deactivated" : "activated"
            } successfully!`,
          })
        );
        setCompaniesList((prevCompanies) => {
          return prevCompanies.map((company) =>
            company.id === currentCompany.id
              ? {
                  ...company,
                  isActive: !currentCompany.isActive,
                  status: status,
                }
              : company
          );
        });
      }
    } catch (error) {
      let errorMsg = error?.response?.data?.title;
      let msg = errorMsg
        ? errorMsg
        : `Failed to ${currentCompany.isActive ? "deactivate" : "activate"} ${
            currentCompany.companyName
          } `;
      dispatch(
        showToast({
          type: "error",
          message: msg,
        })
      );
      console.log(error);
    }
    setPopup(false);
  };

  const onCancelClick = () => {
    setPopup(false);
  };

  const setSearchValue = (e) => {
    setSearchCompany(e.target.value);
    if(!e.target.value) {
      setSelectedPage(1);
    }
  };

  const searchData = ()=> {
    let payload = {};
    if (
      (!showActiveCompanies && showInactiveCompanies) ||
      (showActiveCompanies && !showInactiveCompanies)
    ) {
      payload.active = showActiveCompanies;
    }
    getCompaniesList(payload);
  
  }

  const applySearch = () => {
    if (searchCompany.length > 2 || !searchCompany) {
      setSelectedPage(1);
      searchData();
    } 
  }

  return (
    <div className="table-content">
      {dataFetchStatus === "FETCHED_SUCCESSFULLY" && (
        <>
          <div className="filters mb-3 d-lg-flex d-md-flex justify-content-between align-items-center">
            <div className="d-flex flex-column flex-md-row  justify-content-between gap-4 mb-4 mb-md-0 mb-lg-0">
              <Search
                value={searchCompany}
                onChange={(e) => setSearchValue(e)}
                onClick={applySearch}
              />
              <Filter
                handleApply={handleApply}
                handleClear={handleClear}
                filters={[
                  {
                    type: "Type",
                    options: [
                      {
                        name: "Active",
                        value: showActiveCompanies,
                        onChange: setShowActiveCompanies,
                      },
                      {
                        name: "Inactive",
                        value: showInactiveCompanies,
                        onChange: setShowInactiveCompanies,
                      },
                    ],
                  },
                ]}
              />
            </div>
            <Button
              label="Add Company"
              icon={<AddCircleOutlineIcon />}
              type="button"
              color="primary"
              onClick={() => navigate("/superadmin/companysetup")}
            />
          </div>
          <CustomTable
            name="comapaniesList"
            columns={{
              companyName: "Company Name",

              status: "Status",
              actions: "Actions",
            }}
            data={companiesList}
            showCount={true}
            title="company"
            editHandling={editHandling}
            activationHandling={confirmActivation}
            handleRowClick={navigateToView}
            totalPages={totalPages}
            pagination={true}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />{" "}
        </>
      )}

      {dataFetchStatus === "TRIGGERED" && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
      <ConfirmationPopup
        isPopupOpen={isPopupOpen}
        content={confirmationContent}
        icon={currentCompany?.isActive ? PersonDeactivate : PersonActivate}
        onCancel={onCancelClick}
        onConfirm={activationHandling}
      ></ConfirmationPopup>
    </div>
  );
};

export default CompaniesList;
