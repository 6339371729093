import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  saCompniesList: [],
};

const SaCompanySlice = createSlice({
  name: "superAdmin",
  initialState,
  reducers: {
    setSaCompanies: (state, actions) => {
      state.saCompniesList = actions.payload;
    },
  },
});

export const { setSaCompanies } = SaCompanySlice.actions;

export default SaCompanySlice.reducer;
