import ExcelJS from "exceljs";
import React, { useEffect, useState } from "react";
import styles from "./CreditMapGenerator.module.css";
import axios from "axios";
import axiosInstance from "../../Fbaseurl";
import NavDownloadArrow from "../../assets/NavDownloadArrow.svg";
import DownloadShape from "../../assets/DownloadShape.svg";
import Union from "../../assets/Union.svg";
import NavUploadArrow from "../../assets/NavUploadArrow.svg";
import FileIcon from "../../assets/FileIcon.svg";
import DeleteIcon from "../../assets/DeleteIcon.svg";
import ArrowUpload from "../../assets/ArrowUpload.svg";
import MaskedIcon from "../../assets/MaskedIcon.svg";
import CSV from "../../assets/CSV.svg";
import PDF from "../../assets/PDF.svg";
import Person from "../../assets/Person.svg";
import AnnualHires from "../../assets/AnnualHires.svg";
import { showToast } from "../../store/reducers/ToastMessageReducer";
import { useDispatch } from "react-redux";
import MapView from "./MapView";
import LoadingButton from "@mui/lab/LoadingButton";
import TaxCreditAnalysis from "../components/SalesPersonComp/TaxCreditAnalysisComp";
import html2pdf from "html2pdf.js";
import { display } from "@mui/system";
import Checkbox from "../components/CommonComponents/Checkbox";
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import {prepareRequiredJsonToExcelDownload, convertJsonToExcel} from "../SalesPersonCommonMethods/taxCreditsReport";
import { useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';

const CreditMapGenerator = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const dispatch = useDispatch();
  const [uploadStatus, setUploadStatus] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [activeTab, setActiveTab] = useState("findBusiness");
  const [processing, setProcessing] = useState(false); // State to track processing status
  const [uploadedFileName, setUploadedFileName] = useState(""); // State to store uploaded file name
  const [showMap, setShowMap] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showUpload, setShowUpload] = useState(true); // State to track whether to show upload input and file name
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTaxcredits, setSelectedTaxcredits] = useState([])

  // useEffect(() => {
  //   console.log("Businesses state updated:", businesses);
  // }, [businesses]);

  // const handleFindBusiness = () => {
  //   console.log("Finding a business...");
  //   // Add logic for finding a business
  // };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const { size, type } = file;
    console.log("36::", type);
    const validFileTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel", // .xls
    ];
    if (file && validFileTypes.includes(file.type)) {
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(file);

      // Assuming the first worksheet contains the data
      const worksheet = workbook.getWorksheet(1);
      const rowCount = worksheet.rowCount;
      console.log('ROW', rowCount)
      if (rowCount <= 1) {
        dispatch(
          showToast({
            type: "error",
            message:
              "Please upload a file with valid data",
          })
        );
        return;
      }

      if (rowCount > 500) {
        dispatch(
          showToast({
            type: "error",
            message:
              "The file contains more than 500 records. Please upload a file with 500 records or less.",
          })
        );
        return;
      }
      setSelectedFile(file);
      setUploadedFileName(file.name);
    } else {
      dispatch(
        showToast({
          type: "error",
          message: "Please upload excel format files only",
        })
      );
    }

    event.target.value = "";
  };

  const handleGenerateCreditMap = () => {
    console.log("Generating credit map...");
    handleUpload();
  };

  const handleDownload = () => {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add header row
    worksheet.addRow([
      "Company Name",
      "Annual number of hires",
      "Street Address",
      "City",
      "State",
      "Zip Code",
    ]);

    // Generate a unique filename
    const fileName = "company_info_template.xlsx";

    // Save the workbook
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        // Convert the buffer to a Blob
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        // Simulate a click event to trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error generating Excel file:", error);
      });
  };

  const handleUpload = async (event) => {
    if (!selectedFile) return;

    setProcessing(true);
    setUploadedFileName(selectedFile.name);
    // const apiUrlLogin =
    //   "https://api-lrportal-dev-eus.azurewebsites.net/api/v1/account/login";
    // const loginData = {
    //   email: "faraz.shaikabdul@kaaratech.com",
    //   password: "Rain1!@#$",
    // };

    try {
      // Perform login and set cookies
      let business = [];
      // const loginResponse = await axios.post({
      //   withCredentials: true,
      // });
      // console.log("Login response:", loginResponse.data);

      // // Extract cookies from login response
      // const cookies = loginResponse.headers["set-cookie"];
      // console.log(cookies);
      // if (!cookies) {
      //   console.error('No cookies found in login response');
      //   return;
      // }

      const reader = new FileReader();
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = async () => {
        const buffer = reader.result;
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(buffer);
        const worksheet = workbook.getWorksheet("Sheet1");
        worksheet.eachRow(async (row, rowNumber) => {
          if (rowNumber !== 1) {
            // Skip the header row
            const [
              _,
              companyName,
              annualHiresOverride,
              streetAddress,
              city,
              state,
              zipCode,
            ] = row.values;
            console.log(`Row ${rowNumber}:`, row.values);
            const apiUrlBusinessSearch = `https://api-lrportal-dev-eus.azurewebsites.net/api/v1/businessSearch`;
            const params = {
              BusinessName: companyName.trim(),
              StreetAddress: streetAddress.trim(),
              City: city.trim(),
              State: state.trim(),
              Zip: zipCode,
              UseBusinessWildCardSearch: true,
            };

            try {
              // Include cookies in the request headers
              const response = await axiosInstance.get(`/businessSearch`, {
                params,
              });
              var annualHires = null
              if(Number(annualHiresOverride) || annualHiresOverride == 0) {
                annualHires = Number(annualHiresOverride)
              } 
              
              const extractedData = response.data.map((entry) => ({
                businessName: entry.businessName,
                locationEmployeesCount: entry.locationEmployeesCount,
                physicalAddress: entry.physicalAddress,
                physicalCity: entry.physicalCity,
                state: entry.state,
                physicalZip: entry.physicalZip,
                lat: entry.latitude,
                lng: entry.longitude,
                prospectBusinessId: entry.prospectBusinessId,
                censusTract: entry.censusTract,
                // employeesTotal: entry.locationEmployeesCount,
                siC01Description: entry.siC01Description,
                url: entry.url,
                annualHires: (annualHires || annualHires === 0) ? annualHires : entry.annualHires,
                email: entry.email,
                phone: entry.phone,
                salesVolume: entry.salesVolume,
                annualHiresOverride:  annualHires
              }));

              // Process each extracted data for the second API call
              const detailedData = await Promise.all(
                extractedData.map(async (data) => {
                  var annualHires = null
                    if(Number(annualHiresOverride) || annualHiresOverride == 0) {
                      annualHires = Number(annualHiresOverride)
                  }
                  const taxCreditResponse = await axiosInstance.post(
                    "/taxCredits/lookup",
                    [
                      {
                        prospectBusinessId: data.prospectBusinessId,
                        censusTract: data.censusTract,
                        annualHiresOverride:  annualHires
                      },
                    ]
                  );

                  // Assuming the tax credit data should be merged into the business data
                  return {
                    ...data,
                    taxCredits: taxCreditResponse.data, // Add this data based on your API response structure
                    isSelected: false,
                  };
                })
              );

              // Append the new detailed data to the existing state
              setBusinesses((prevBusinesses) => [
                ...prevBusinesses,
                ...detailedData,
              ]);
              setUploadStatus(true);
              setProcessing(false);
              setShowMap(true);
              console.log(`Detailed Data for Row ${rowNumber}:`, detailedData);
            } catch (error) {
              console.error(`Error fetching data for Row ${rowNumber}:`, error);
            }
          }
        });
        console.log(business);
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
    } catch (error) {
      console.error("Error during login:", error);
    }
  }; 

  const handleGenerate = () => {
    console.log("Generating credit map...");
    // Add logic for generating credit map
  };
  const handleGoBackToUpload = () => {
    setUploadStatus(false);
    setProcessing(true);
    setProcessing(false);
    setShowMap(false);
    setShowUpload(true);
    setSelectedFile(null);
    setUploadedFileName("");
    setBusinesses([]);
    setSelectedTaxcredits([])
  };

  const handleCancel = () => {
    setProcessing(false);
    setShowUpload(true); // Set showUpload to true when cancel button is clicked
  };
  const handleDelete = () => {
    setUploadedFileName("");
    setSelectedFile(null);
  };

  const NavigateTowebsite = (url) => {
    const fullUrl =
      url.startsWith("http://") || url.startsWith("https://")
        ? url
        : `https://${url}`;
    window.open(fullUrl, "_blank");
  };

  
const handleCheckBoxChange = (post) => {
  const updatedList = businesses.map((checkbox) =>
    checkbox.prospectBusinessId === post.prospectBusinessId
      ? { ...checkbox, isSelected: !checkbox.isSelected }
      : checkbox
  );
    setBusinesses(updatedList)
    const selectedList =  updatedList.filter((item,index)=>{
    return item.isSelected
  })

  setSelectedTaxcredits(selectedList);
  if(selectedList.length === 10) {
    dispatch(
      showToast({
        type: "success",
        message: "You have successfully selected the maximum limit of 10 records.",
        duration: 20000
      })
    );
  }
  // dispatch(updateCompanies(updatedList));

};

//   const downloadTaxCredits = ()=> {
//  // };
//  setIsLoading(true);
//   const element = document.getElementById("pdf-content");
//   const options = {
//     margin: [10, 0, 10, 0],
//     filename: "document.pdf",
//     image: { type: "jpeg", quality: 0.98 },
//     html2canvas: { scale: 2 },
//     jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
//     pagebreak: { mode: ["avoid-all", "css", "legacy"] },
//   };

//   html2pdf().set(options).from(element).save();
//   setIsLoading(false)
//   }

// const downloadTaxCredits = () => {
//   const element = document.getElementById("pdf-content");

//   // Select elements to modify
//   const aboutUsElement = document.querySelector('.aboutus');
//   const taxCards = document.querySelectorAll('.tax-card');
//   const headers = document.querySelectorAll('.header');
//   const contentElements = document.querySelectorAll('.content');
//   const footers = document.querySelectorAll('.footer');

//   // Function to store and apply styles
//   const storeAndApplyStyles = (elements, newStyles) => {
//     const originalStyles = [];
//     elements.forEach((element, index) => {
//       originalStyles[index] = element.style.cssText;
//       element.style.cssText = newStyles;
//     });
//     return originalStyles;
//   };

//   // Store original styles
//   const originalAboutUsStyle = aboutUsElement.style.cssText;
//   const originalTaxCardsStyles = storeAndApplyStyles(taxCards, 'position: relative; page-break-after: always;');
//   const originalHeadersStyles = storeAndApplyStyles(headers, 'width: 100%; position: absolute; top: 10px;  background-color: #2434D0;');
//   const originalContentStyles = storeAndApplyStyles(contentElements, 'margin-top: 220px; margin-bottom: 133px; position: relative;');
//   const originalFootersStyles = storeAndApplyStyles(footers, 'width: 100%; position: absolute; bottom: 10px; background-color: #F4F4F4;');

//   // Apply temporary styles
//   aboutUsElement.style.cssText = 'z-index: 1000 !important; position: relative !important; margin-top: -100px !important;';

//   // Generate the PDF
//   const options = {
//     margin: [0,0,0,0],
//     filename: "document.pdf",
//     image: { type: "jpeg", quality: 0.98 },
   
//     html2canvas: { scale: 2, logging: true, useCORS: true },
//     jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
//     pagebreak: { mode: ["avoid-all", "css", "legacy"] },
//     // pagebreak: { mode: "avoid-all", after: ".content", always: true },
// };
//   html2pdf().set(options).from(element).save().finally(() => {
//     // Restore original styles after PDF generation
//     aboutUsElement.style.cssText = originalAboutUsStyle;
//     taxCards.forEach((el, i) => el.style.cssText = originalTaxCardsStyles[i]);
//     headers.forEach((el, i) => el.style.cssText = originalHeadersStyles[i]);
//     contentElements.forEach((el, i) => el.style.cssText = originalContentStyles[i]);
//     footers.forEach((el, i) => el.style.cssText = originalFootersStyles[i]);
//   });
// };


const downloadTaxCredits = () => {
  const element = document.getElementById("pdf-content");

  // Temporarily adjust styles
  const disclaimerElement = document.querySelector('.disclaimer');
  const originalDisclaimerStyles = disclaimerElement.style.cssText;
  disclaimerElement.style.cssText = `
   page-break-inside: avoid;
     min-height: 96px;
  `;
  const aboutUsElement = document.querySelector('.aboutus');
  const originalStyles = aboutUsElement.style.cssText;
  aboutUsElement.style.cssText = `
      z-index: 1000 !important;
      position: relative !important;
      margin-top: -100px !important;
  `;
  const contentElements = document.querySelectorAll('.content');
  const originalContentStyles = [];
  
  contentElements.forEach((contentElement, index) => {
      originalContentStyles[index] = contentElement.style.cssText;
      contentElement.style.cssText = `
      `;
  });
  const textWrapElement = document.querySelectorAll('.text-wrap');
  const originalTextWrapStyles = [];
  textWrapElement.forEach((card, index) => {
    originalTextWrapStyles[index] = card.style.cssText;
    card.style.cssText += `
    width: 100%        
    `;
  });

  const taxCards = document.querySelectorAll('.tax-card');
  const originalTaxCardsStyles = [];

  taxCards.forEach((card, index) => {
    originalTaxCardsStyles[index] = card.style.cssText;
    card.style.cssText += `
    
      position: relative;
      page-break-before: always;
      margin: 0px !important;
      
    `;
  });



  let date = dayjs(new Date()).format('DD/MM/YYYY T HH:mm:ss') ;
  const options = {
      margin: [0,0,-10,0],
      // margin: [10, 0, 10, 0],
      filename: `Tax Credits Analysis ${date}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
     
      html2canvas: { scale: 2, logging: true, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      // pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      // pagebreak: { mode: "avoid-all", after: ".content", always: true },
  };

  // After generating the PDF, pass the `pdfBytes` to the function:

    html2pdf().set(options).from(element).save().finally(() => {
      // Restore original styles after PDF generation
      aboutUsElement.style.cssText = originalStyles;
      originalDisclaimerStyles.style.cssText = originalDisclaimerStyles;
      contentElements.forEach((contentElement, index) => {
        contentElement.style.cssText = originalContentStyles[index];
    });
    textWrapElement.forEach((contentElement, index) => {
      contentElement.style.cssText = originalTextWrapStyles[index];
  });

    // html2pdf().from(element).toPdf().get('pdf').then(async (pdf) => {
    //   const pdfBytes = await pdf.output('arraybuffer');
    //   await handleRemoveEmptyPages(pdfBytes);
    // });

    taxCards.forEach((card, index) => {
      card.style.cssText = originalTaxCardsStyles[index];
    });

  });
};

const downloadTaxCreditsasCsv = async () => {
  const jsonData = await prepareRequiredJsonToExcelDownload(selectedTaxcredits);
  await convertJsonToExcel(jsonData, "Tax Credits Analysis");
};

const downloadTaxCreditsasPdf = async () => {
  setIsLoading(true)
  try {
    var payload = [];
    selectedTaxcredits.map((item, index) => {
      payload.push({
        prospectBusinessId: item.prospectBusinessId,
        censusTract: item.censusTract,
        annualHiresOverride: item.annualHiresOverride,
      });
    });
    const response = await axiosInstance.post(`/taxCredits/lookup`, payload, {
      headers: {
        Accept: "application/pdf",
        "content-type": "application/json",
      },
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "Report.zip"; // Default file name if none provided
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    console.log(error);
    dispatch(showToast({
      type: 'error',
      message: 'Failed to download. Please try again'
    }))
  }
};



  return (
    <div>
      {showMap && (
        <div
          className=" text-right "
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <LoadingButton
            variant="contained"
            color="primary"
            size="medium"
            sx={{ textTransform: "none", borderRadius: "8px" }}
            onClick={(e) => handleGoBackToUpload(e)}
            className="mt-3"
          >
            Generate again
          </LoadingButton>
        </div>
      )}
      <div className={styles.container}>
        <header className={styles.header}>{/* Header content */}</header>
        <div className="d-lg-flex d-md-flex">
          {!showMap && (
            <div className={`col-lg-6 col-md-6 col-sm-12 ${!isMobile ? 'px-3' : ''}`}>
              <div className={`${styles.downloadCard} ${styles.firstcard} `}>
                <h3
                  className={`${styles.card_title} ${styles.textTitleForDocument}`}
                >
                  Download Company Information Template
                </h3>
                <div
                  className={`${styles.card} ${styles.docummentCard} $ gap-3`}
                >
                  <img
                    src={DownloadShape}
                    width="20px"
                    height="20px"
                    alt="donwmload"
                  ></img>

                  <LoadingButton
                    variant="contained"
                    color="primary"
                    size="medium"
                    sx={{ textTransform: "none", borderRadius: "8px" }}
                    onClick={handleDownload}
                  >
                    <img
                      src={NavDownloadArrow}
                      width="20px"
                      height="20px"
                      alt="donwmload"
                    ></img>{" "}
                    Download document
                  </LoadingButton>
                  {/* <button
                onClick={handleDownload}
                className={styles.download_button}
              >
                <img
                  src={NavDownloadArrow}
                  width="20px"
                  height="20px"
                  alt="donwmload"
                ></img>{" "}
                Download document
              </button> */}
                </div>
              </div>
            </div>
          )}
          {!showMap && !uploadStatus && (
            <div className={`col-lg-6 col-md-6 col-sm-12 ${!isMobile ? 'px-3' : ''}`}>
              <div className={`${styles.card} ${styles.firstcard} ${isMobile ? 'border-0 p-0 pt-4' : ''}`}>
                <h3
                  className={`${styles.card_title} ${styles.textTitleForDocument}`}
                >
                  Upload Company Information File
                </h3>
                <div
                  className={`${styles.card} ${styles.docummentCardUplod} $ gap-3`}
                >
                  <img src={Union} width="20px" height="20px" alt="union"></img>
                  <h3 className={styles.card_title}>Add a document</h3>
                  <input
                    type="file"
                    onInput={handleFileChange}
                    id="upload_input"
                    className={styles.upload_input}
                  />

                  {!processing && (
                    <label
                      htmlFor="upload_input"
                      className={styles.upload_label}
                    >
                      {/* <img src={NavDownloadArrow}
               width="20px"
             height="20px"

           alt="upload"></img> */}
                      <img
                        src={ArrowUpload}
                        width="20px"
                        height="20px"
                        alt="donwmload"
                      ></img>{" "}
                      Upload
                    </label>
                  )}
                  {processing && (
                    <div className={styles.loader_container}>
                      <div className={styles.loader}></div>
                      <button
                        className={styles.cancel_genreate_button}
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
                {uploadedFileName && !processing && (
                  <div className={` ${styles.uploadDocumnetFile} col-12`}>
                    <div style={{ float: "left" }}>
                      <div className={`${styles.card_title } ${isMobile ? styles.ellipsis : ''}`}>
                       {!isMobile &&  <img
                          src={FileIcon}
                          width="40px"
                          height="40px"
                          alt="File"
                          style={{ marginRight: "8px" }}
                        ></img>}
                        {uploadedFileName}
                      </div>
                    </div>
                    <div>
                      <img
                        onClick={handleDelete}
                        style={{ float: "right" }}
                        src={DeleteIcon}
                        width="36px"
                        height="36px"
                        alt="Delete"
                      ></img>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* <div className="card-footer d-flex text-right align-self-end gap-3 mt-auto">
        
        <LoadingButton
          variant="contained"
          color="primary"
          size="medium"
          sx={{ textTransform: "none" }}
          onClick={(e) => handleGenerateCreditMap(e)}
          disabled={
            !selectedFile
          }
          loading={processing}
          >
          Generate
        </LoadingButton>
      </div> */}

        {showMap && <MapView businesses={businesses} />}
        <div className={`card-footer d-flex text-right align-self-end gap-3 mt-auto ${isMobile ? 'px-0 pt-3' : 'px-3'}`}>
          {!showMap && (
            // <button
            //   onClick={handleGenerateCreditMap}
            //   className={styles.generate_button}
            // >
            //   Generate
            // </button>

            <LoadingButton
              variant="contained"
              color="primary"
              size="medium"
              sx={{ textTransform: "none", borderRadius: "8px" }}
              onClick={(e) => handleGenerateCreditMap(e)}
              disabled={!selectedFile}
              loading={processing}
            >
              Generate
            </LoadingButton>
          )}
          {/* {showMap && (
            

            <LoadingButton
              variant="contained"
              color="primary"
              size="medium"
              sx={{ textTransform: "none", borderRadius: "8px" }}
              onClick={(e) => handleGoBackToUpload(e)}
            >
              Go Back
            </LoadingButton>
          )} */}
        </div>
      </div>

      {showMap && (
        <div className="my-4">
          <div className="d-flex justify-content-between">
            <div className={`font-700 ${isMobile ? 'font-16' : 'font-32'}`}>
              All Tax Credit Details ({businesses.length})
            </div>
            {/* <LoadingButton
              variant="contained"
              color="primary"
              size="medium"
              sx={{ textTransform: "none", borderRadius: "8px" }}
              onClick={downloadTaxCredits}
              className="my-auto"
              loading={isLoading}
            >
              Download{" "}
              <img
                src={NavDownloadArrow}
                width="20px"
                height="20px"
                alt="donwmload"
              ></img>
            </LoadingButton> */}
          </div>
          {console.log("467", businesses)}
          <hr></hr>
          <div className="row d-lg-flex d-md-flex">
            {businesses.map((item, index) => {
              return (
                // <>
                <div
                  className={`col-lg-4 col-md-6 col-sm-12 p-right ${isMobile ? 'pb-3' : 'pb-4'}`}
                  key={item.prospectBusinessId}
                >
                  <div
                    className={`card p-3 ${
                      item.isSelected
                        ? " selected-card"
                        : selectedTaxcredits.length === 10
                        ? styles.disableCard
                        : ""
                    }`}
                  >
                    <div className="d-flex">
                      <div className="px-2">
                        <Checkbox
                          checked={item.isSelected}
                          onChange={() => handleCheckBoxChange(item)}
                          name="subscribe"
                          className="mb-2"
                          disabled={
                            selectedTaxcredits.length >= 10 && !item.isSelected
                          }
                        />
                      </div>
                      <div>
                        <div className="pb-3">
                          <div className={`${styles.title}`}>
                            {item.businessName}
                          </div>
                          <div className="font-14">
                            {" "}
                            {item.physicalAddress},{item.physicalCity},
                            {item.state}
                          </div>
                        </div>
                        <div className="font-500">
                          Category: {item.siC01Description}
                        </div>
                        <div
                        className={` ${!isMobile ? ' card-title pt-3' : 'pt-2'}`}
                        >
                        {/* style={{ position: " absolute", bottom: "0" }} */}
                        <div className="p-right">
                          <img
                            src={Person}
                            width="20px"
                            height="20px"
                            alt="Person"
                            className="my-auto"
                          ></img>
                          <span className="font-14 font-500 my-auto">
                            Employees:
                            <span className="font-700 ">
                              {item?.locationEmployeesCount
                                ? item.locationEmployeesCount
                                : "No Data "}
                            </span>
                          </span>
                        </div>
                        <div>
                          <img
                            src={AnnualHires}
                            width="20px"
                            height="20px"
                            alt="PersonAccount"
                          ></img>
                          <span className="font-14 font-500">
                            Annual hires:
                           
                              <span className="font-700">
                                {item?.annualHires
                                  ? item?.annualHires
                                  : "No Data "}
                              </span>
                           
                              
                          </span>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                // </>
              );
            })}
          </div>
          {selectedTaxcredits.length > 0 && (
            <div className={`fixed-bottom  ${styles.footer} ${isMobile? 'p-3' : 'p-4'}`}>
              <div className="d-flex justify-content-between ">
                <div className={`${isMobile ? 'col-7' : 'col-9'}`}>
                  <div className={`d-flex gap-3 flex-wrap ${isMobile? ' gap-2' : ' gap-3'}`}>
                    {selectedTaxcredits.map((item, index) => {
                      return (
                        <div>
                          <Chip
                            sx={{ borderRadius: "8px" }}
                            label={item.businessName}
                            variant="outlined"
                            deleteIcon={<CloseIcon />}
                            onDelete={() => handleCheckBoxChange(item)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className="d-flex align-self-end gap-3"
                  style={{ float: "right", padding: "4px" }}
                >
                    <LoadingButton
                  variant="contained"
                  color="primary"
                  size="medium"
                  sx={{ textTransform: "none", borderRadius: "8px" }}
                  onClick={downloadTaxCreditsasCsv}
                >
                  {!isMobile && <img
                    src={CSV}
                    width="24px"
                    height="24px"
                    alt="donwmload"
                  ></img>}
                  {isMobile ? 'Excel' : 'Download  Excel'}
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  size="medium"
                  sx={{ textTransform: "none", borderRadius: "8px" }}
                  onClick={downloadTaxCreditsasPdf}
                  loading={isLoading}
                >
                 {!isMobile && <img
                    src={PDF}
                    width="24px"
                    height="24px"
                    alt="donwmload"
                  ></img>}
                  {isMobile ? 'PDF' : 'Download PDF'}
                </LoadingButton>
                  {/* {!isMobile && <div className="font-primary-blue fw-bold my-auto">
                    Download as
                  </div>}
                  <img
                    style={{ float: "right", padding: "4px" }}
                    src={CSV}
                    height="48px"
                    width="48px"
                    alt=""
                    className="cursor-pointer"
                    onClick={downloadTaxCreditsasCsv}
                  ></img>
                  <img
                    style={{ float: "right", padding: "4px" }}
                    src={PDF}
                    height="48px"
                    width="48px"
                    onClick={downloadTaxCreditsasPdf}
                    alt=""
                    className="cursor-pointer"
                  ></img> */}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div style={{ display: "none" }}>
        {/* <TaxCreditAnalysis businesses={selectedTaxcredits}></TaxCreditAnalysis> */}
      </div>
    </div>
  );
};

export default CreditMapGenerator;
