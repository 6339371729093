import { useEffect } from 'react';

const useScript = (url, async = true) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = url;

        if (async) {
            script.async = true;
        }
        else {
            script.defer = true;
        }

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url, async]);
};

export default useScript;
