import { useParams } from "react-router-dom";

import InputField from "../../CommonComponents/InputField";
import CustomMultiSelect from "../../CommonComponents/MultiSelect";
import SelectComp from "../../CommonComponents/SelectComp";
import Checkbox from "../../CommonComponents/Checkbox";

const BusinessQualification = ({
  formik,
  businessSizeCodesList,
  headcountCodesList,
  yearsInBusinessCodesList,
  eligibilityCriteria,
  onChecked,
  isPropertyPresent,
}) => {
  const { id, type } = useParams();

  return (
    <form>
      <div className="row pb-4">
        <div className="col-6">
          <InputField
            placeholder="Enter code"
            name="includedNaicsCodes"
            value={formik.values.includedNaicsCodes}
            onChange={formik.handleChange}
            title="NAICS Code"
            type="text"
            isRequired={false}
            className="form-control"
            disabled={type === "view" ? true : false}
          />
          {/* {formik.touched.includedNaicsCodes &&
            formik.errors.includedNaicsCodes && (
              <div className="error px-1">
                {formik.errors.includedNaicsCodes}
              </div>
            )} */}
          {/* checked={post.isSelected}
                        onChange={() => handleCheckBoxChange(post)} */}
        </div>
        <div className="col-6">
          <Checkbox
            checked={isPropertyPresent("IncludedNaicsCodes")}
            name="IncludedNaicsCodes"
            className="mb-2"
            label="Eligibility criteria"
            onChange={() => onChecked("IncludedNaicsCodes")}
            disabled={type === "view" ? true : false}
          />
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-6">
          <InputField
            placeholder="Enter codes"
            name="excludedNaicsCodes"
            value={formik.values.excludedNaicsCodes}
            onChange={formik.handleChange}
            title="Exclude NAICS Codes"
            type="text"
            className="form-control"
            disabled={type === "view" ? true : false}
          />
        </div>
        <div className="col-6">
          <Checkbox
            checked={isPropertyPresent("ExcludedNaicsCodes")}
            name="ExcludedNaicsCodes"
            className="mb-2"
            label="Eligibility criteria"
            onChange={() => onChecked("ExcludedNaicsCodes")}
            disabled={type === "view" ? true : false}
          />
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-6">
          {/* <InputField
            placeholder="Enter number"
            name="businessSizes"
            value={formik.values.businessSizes}
            onChange={formik.handleChange}
            title="Size of business"
            type="text"
            className="form-control"
            disabled = {type === 'view' ? true : false}
          /> */}
          {/* <CustomMultiSelect
            placeholder="Select business size"
            onChange={(value) => formik.setFieldValue("businessSizes", value)}
            selectedValues={formik.values.businessSizes}
            options={businessSizeCodesList}
            label="Size of business"
            disabled={type === "view" ? true : false}
          /> */}
          <SelectComp
            title="Select business size"
            value={formik.values.businessSizes}
            options={businessSizeCodesList}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              formik.setFieldValue(
                "businessSizes",
                typeof value === "string" ? value.split(",") : value
              );
            }}
            name="businessSizes"
            isRequired={false}
            isMultiSelect={true}
            disabled={type === "view" ? true : false}
          ></SelectComp>
        </div>
        <div className="col-6">
          {/* <InputField
            placeholder="Enter location"
            name="locationHeadcounts"
            value={formik.values.locationHeadcounts}
            onChange={formik.handleChange}
            title="Headcount Locatin"
            type="text"
            className="form-control"
            disabled={type === "view" ? true : false}
          /> */}
          {/* <CustomMultiSelect
            placeholder="Select Location Head count"
            onChange={(value) =>
              formik.setFieldValue("locationHeadcounts", value)
            }
            selectedValues={formik.values.locationHeadcounts}
            options={headcountCodesList}
            label="Headcount Location"
            disabled={type === "view" ? true : false}
          /> */}
          <Checkbox
            checked={isPropertyPresent("BusinessSizes")}
            name="BusinessSizes"
            className="mb-2"
            label="Eligibility criteria"
            onChange={() => onChecked("BusinessSizes")}
            disabled={type === "view" ? true : false}
          />
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-6">
          <SelectComp
            title="Select Location Head count"
            value={formik.values.locationHeadcounts}
            options={headcountCodesList}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              formik.setFieldValue(
                "locationHeadcounts",
                typeof value === "string" ? value.split(",") : value
              );
            }}
            name="locationHeadcounts"
            isRequired={false}
            isMultiSelect={true}
            disabled={type === "view" ? true : false}
          ></SelectComp>
        </div>
        <div className="col-6">
          <Checkbox
            checked={isPropertyPresent("LocationHeadcounts")}
            name="LocationHeadcounts"
            className="mb-2"
            label="Eligibility criteria"
            onChange={() => onChecked("LocationHeadcounts")}
            disabled={type === "view" ? true : false}
          />
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-6">
          {/* <InputField
            placeholder="Enter headcount"
            name="totalHeadcounts"
            value={formik.values.totalHeadcounts}
            onChange={formik.handleChange}
            title="Headcount Total"
            type="text"
            className="form-control"
            disabled = {type === 'view' ? true : false}
          /> */}
          {/* <CustomMultiSelect
            placeholder="Select Head count"
            onChange={(value) => formik.setFieldValue("totalHeadcounts", value)}
            selectedValues={formik.values.totalHeadcounts}
            options={headcountCodesList}
            label="Headcount Total"
            disabled={type === "view" ? true : false}
          /> */}
          <SelectComp
            title="Select Head count"
            value={formik.values.totalHeadcounts}
            options={headcountCodesList}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              formik.setFieldValue(
                "totalHeadcounts",
                typeof value === "string" ? value.split(",") : value
              );
            }}
            name="totalHeadcounts"
            isRequired={false}
            isMultiSelect={true}
            disabled={type === "view" ? true : false}
          ></SelectComp>
        </div>
        <div className="col-6">
          {/* <InputField
            placeholder="Enter year"
            name="yearsInbusiness"
            value={formik.values.yearsInbusiness}
            onChange={formik.handleChange}
            title="Years in Business"
            type="text"
            className="form-control"
            disabled = {type === 'view' ? true : false}
          /> */}
          {/* <CustomMultiSelect
            placeholder="Select your company"
            onChange={(value) => formik.setFieldValue("yearsInbusiness", value)}
            selectedValues={formik.values.yearsInbusiness}
            options={yearsInBusinessCodesList}
            label="Years in Business"
            disabled={type === "view" ? true : false}
          /> */}
          <Checkbox
            checked={isPropertyPresent("TotalHeadcounts")}
            name="TotalHeadcounts"
            className="mb-2"
            label="Eligibility criteria"
            onChange={() => onChecked("TotalHeadcounts")}
            disabled={type === "view" ? true : false}
          />
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-6">
          <SelectComp
            title="Select Years in Business"
            value={formik.values.yearsInbusiness}
            options={yearsInBusinessCodesList}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              formik.setFieldValue(
                "yearsInbusiness",
                typeof value === "string" ? value.split(",") : value
              );
            }}
            name="yearsInbusiness"
            isRequired={false}
            isMultiSelect={true}
            disabled={type === "view" ? true : false}
          ></SelectComp>
        </div>
        <div className="col-6">
          <Checkbox
            checked={isPropertyPresent("YearsInBusiness")}
            name="YearsInBusiness"
            className="mb-2"
            label="Eligibility criteria"
            onChange={() => onChecked("YearsInBusiness")}
            disabled={type === "view" ? true : false}
          />
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-6">
          <InputField
            placeholder="Enter annual hire"
            name="annualNewHires"
            value={formik.values.annualNewHires}
            onChange={formik.handleChange}
            title="Annual New hires"
            type="text"
            className="form-control"
            isRequired={false}
            disabled={type === "view" ? true : false}
          />
          {formik.touched.annualNewHires &&
            formik.errors.annualNewHires && (
              <div className="error px-1">
                {formik.errors.annualNewHires}
              </div>
            )}
        </div>
        <div className="col-6">
          <Checkbox
            checked={isPropertyPresent("AnnualNewHires")}
            name="AnnualNewHires"
            className="mb-2"
            label="Eligibility criteria"
            onChange={() => onChecked("AnnualNewHires")}
            disabled={type === "view" ? true : false}
          />
        </div>
      </div>
    </form>
  );
};

export default BusinessQualification;
