import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "./index.css";
import Button from "../../../components/CommonComponents/Button";
import CloseIcon from "@mui/icons-material/Close";
import support from "../../../../assets/support.svg";
import InputField from "../InputField";
import RButton from "@mui/material/Button";
import supportUpload from "../../../../assets/supportUpload.svg";
import FileIcon from "../../../../assets/FileIcon.svg";
import DeleteIcon from "../../../../assets/DeleteIcon.svg";
export default function ConfirmationPopup({
  isPopupOpen,
  onCancel,
}) {
  const [query, setQuery] = useState("");
  const [documents, setDocuments] = useState([]);
  const handleFileUpload = (event) => {
    var file = event.target.files[0];
    setDocuments([...documents, file]);
    event.target.value = null;
  };
  const removeDocument = (item) => {
    setDocuments(documents.filter((a) => a.name !== item.name));
  };

  const submitQuery = ()=>{
    onCancel()
  }
  return (
    <React.Fragment>
      <Dialog
        open={isPopupOpen}
      
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        sx={{ "& .MuiPaper-root": { borderRadius: "24px !important" } }}
      >
        <DialogTitle
          className="p-3"
          id="alert-dialog-title"
          sx={{ paddingBottom: "0px !important", backgroundColor: "#162395" }}
        >
          <CloseIcon
            className="back-btn float-end"
            style={{ color: "white" }}
            onClick={() => {
              onCancel();
            }}
          />
          <div className="d-flex justify-content-between gap-4 pt-2 pb-4">
            <img src={support} alt="support"></img>
            <div className="text-white">
              <div className="fw-bold font-24">How can we help you!</div>
              <div className="font-16">
                Please provide your query or issue below, and we'll get back to
                you shortly.
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="p-4">
          <InputField
            placeholder="Enter name"
            name="query"
            value={query}
            onChange={setQuery}
            title="Describe Your issue or Query"
            isRequired={false}
            type="text"
            className="form-control"
            multiline={true}
          />
          <RButton
            variant="outlined"
            size="medium"
            className="mt-3"
            color="secondary"
            sx={{
              textTransform: "none",
              borderRadius: "8px",
            }}
            component="label"
          >
            <img src={supportUpload} alt="upload"></img> Upload
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
          </RButton>
          <div className="d-flex flex-wrap gap-3 pt-3">
            {documents.map((item, index) => {
              return (
                <div className="d-flex p-1 fileName">
                  <img src={FileIcon} alt="file"></img>
                  <div className="px-1 my-auto font-14">{item.name}</div>
                  <img
                    src={DeleteIcon}
                    alt="delete"
                    onClick={() => removeDocument(item)}
                    className="cursor-pointer"
                  ></img>
                </div>
              );
            })}
          </div>
        </DialogContent>
        <hr></hr>
        <DialogActions className="mb-3 gap-3 d-flex justify-content-end mx-3">
          <Button
            variant=""
            color="primary"
            label="Cancel"
            onClick={onCancel}
            className={`rounded-pill col-6 fw-bold py-1 `}
          />
          <Button
            label="Submit"
            onClick={submitQuery}
            className={`rounded-pill col-6 fw-bold py-1 `}
            type="submit"
            color="primary"
            variant="contained"
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
