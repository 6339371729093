import {UPDATE_COMPANIESLIST, UPDATE_SELECTEDCOMPANIES} from '../taskActions.js'


export const updateCompanies = (task) => {
    return {
        type: UPDATE_COMPANIESLIST,
        payload: task,
    };
};

export const updateSelectedCompnies = (value) => {
    return {
        type: UPDATE_SELECTEDCOMPANIES,
        payload: value,
    };
};

