import * as React from "react";
import {useState} from "react";

import "./index.css";
// import { InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";


const InputField = (props) => {
  // return (
  //   <div className="input-container form-group">
  //     <label className="label mb-2 fw-bold" htmlFor="my-input">
  //       {props.title}
  //       {props.isRequired && <span className="mandtoryAsterik"> * </span>}
  //     </label>
  //     <input
  //       className=" px-3 py-2 form-control"
  //       name={props.name}
  //       type={props.type}
  //       placeholder={props.placeholder}
  //       value={props.value}
  //       onChange={props.onChange}
  //       disabled={props.disabled}
  //     />
  //   </div>
  // );
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    setIsFocused(false);
    if (props.onBlur) {
      props.onBlur(event);
    }
  };
  return (
    <div className=" form-group">
    
      <TextField
        id="outlined-basic"
        label={
          props.title && <span>
            {props.title}
            {props.isRequired && <span className="mandtoryAsterik"> *</span>}
          </span>
        }
        variant="outlined"
        className="form-control"
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        disabled={props.disabled}
        inputProps={{
          max: props.max,
          min: props.min,
          maxLength: props.maxLength
        }}
        
        size="small"
        color="secondary"
        InputLabelProps={{
          shrink: isFocused || !!props.value || !!(props?.value?.toString()),
        }}
        multiline = {props.multiline}
        helperText={props.maxLength ? `${props?.value?.length}/${props?.maxLength}` : ''} 
      />
    </div>
  );
};

export default InputField;
