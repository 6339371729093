import React, { useState } from "react";
import "./index.css";
import { InputLabel } from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";

import IconButton from "@mui/material/IconButton";

import InputAdornment from "@mui/material/InputAdornment";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
const Password = ({
  value,
  onChange,
  onBlur,
  placeholder,
  label,
  name,
  title,
  type,
  disabled,
  showHidePasswordIcon = true,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const handleBlur = (event) => {
    setShowPassword(false)
  };
  // return (
  //   <div className="password-input-container">
  //     <InputLabel className="label mb-2 fw-bold" htmlFor={name}>
  //       {label}
  //     </InputLabel>
  //     <div className="password-feild d-flex align-items-center rounded-pill">
  //       <input
  //         className="w-100"
  //         name={name}
  //         type={showPassword || !showHidePasswordIcon ? "text" : "password"}
  //         placeholder={placeholder}
  //         value={value}
  //         onChange={onChange}
  //         id={name}
  //       />
  //       {showHidePasswordIcon && (
  //         <>
  //           {!showPassword ? (
  //             <VisibilityOffOutlinedIcon
  //               onClick={() => setShowPassword(!showPassword)}
  //             />
  //           ) : (
  //             <VisibilityOutlinedIcon
  //               onClick={() => setShowPassword(!showPassword)}
  //             />
  //           )}
  //         </>
  //       )}
  //     </div>
  //   </div>
  // );
  return (
    <div className=" form-group">
      <FormControl variant="outlined" size="small" className="form-control">
        <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          label={label}
          name={name}
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={onChange}
          disabled={disabled}
          size="small"
          color="secondary"
          onBlur={onBlur}
         
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ?   <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
};

export default Password;
