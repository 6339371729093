import * as React from "react";
import "./index.css";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const CustomPagination = ({ count, selectedPage, setSelectedPage }) => {
    return (
        <div className={`pagination-container p-3`}>
            {count && (
                <Pagination
                    count={count}
                    showFirstButton
                    showLastButton
                    page={selectedPage}
                    renderItem={(item) => (
                        <PaginationItem
                            slots={{
                                first: KeyboardDoubleArrowLeftIcon,
                                last: KeyboardDoubleArrowRightIcon,
                            }}
                            // selected={ selectedPage}
                            {...(item.onClick = () => {
                                setSelectedPage(item.page);
                               
                            })}
                            {...item}
                        />
                    )}
                />
            )}
        </div>
    );
};

export default CustomPagination;
