import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {formatCurrency} from "../../CommonUtils/currencyUtils";


export const convertJsonToExcel = (jsonData, fileName) => {
  // Prepare the worksheet data from JSON
  const worksheetData = jsonData.map((row) => {
    // Handle arrays of objects within columns
    if(row["Credits identified"]) {

      return {
        ...row,
        "Credits identified": row["Credits identified"]
          .map((obj) => `${obj.name}\n${obj.description}`)
          .join("\n\n"),
      };
    } else {
      return {
        ...row}
    }
  });

  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(worksheetData);

  // Apply styles to the worksheet
  const range = XLSX.utils.decode_range(worksheet["!ref"]);

  for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
    for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
      var cellAddress;
      cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
      if (!worksheet[cellAddress]) continue;

      // Example: Make the first row bold (assuming it’s the header)
      console.log("RANGE", worksheet[cellAddress]);
      if (colNum === 1) {
        worksheet[cellAddress] = {
          v: worksheet[cellAddress].v,
          l: { Target: worksheet[cellAddress].v }, // Set the hyperlink target
          s: { font: { color: { rgb: "0000FF" }, underline: true } }, // Optional: style as a typical link
        };
      }
    }
  }

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Write the workbook and save as a file
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(data, `${fileName}.xlsx`);
};

export const prepareRequiredJsonToExcelDownload = (selectedTaxcredits) =>{
    const jsonData = 
    selectedTaxcredits.map((taxCredit, index)=> {
      let credits = taxCredit.taxCredits[0]?.availableTaxCredits.map((item)=> {
        return {
          name: item.name,
          description: item.description
        }
        })
      return {
        Name: taxCredit.businessName,
        Website: `https://${taxCredit.url}`,
        Category: `${taxCredit.siC01Description}`,
        Address: `${taxCredit.physicalAddress}, ${taxCredit.physicalCity}, ${taxCredit.state}, ${taxCredit.physicalZip}`,
  
        "Projected credit": taxCredit.taxCredits[0]?.totalProjectedTaxCreditAmount ? "$" + formatCurrency(taxCredit.taxCredits[0]?.totalProjectedTaxCreditAmount) : 'No Data Available',
        Employees: taxCredit?.locationEmployeesCount ? formatCurrency(taxCredit?.locationEmployeesCount) : 'No Data Available',
        "Annual hires": taxCredit.annualHires ? formatCurrency(taxCredit.annualHires) : 'No Data Available',
        "Contact phone": taxCredit.phone ? taxCredit.phone : 'No Data Available',
        "Contact email": taxCredit.email ? taxCredit.email : 'No Data Available',
        "Annual sales": taxCredit.salesVolume ? "$" +  formatCurrency(taxCredit.salesVolume) : 'No Data Available',
        "Credits identified": credits && credits.length ? credits : 'No Data Available',
      };
    })
    
    return jsonData
    // convertJsonToExcel(jsonData, fileName);
}
