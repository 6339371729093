import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Title from "../../components/SuperAdminComp/Title";
import CreditInfo from "../../components/SuperAdminComp/CreditInfo";
import BusinessQualification from "../../components/SuperAdminComp/BusinessQualification";
import LocationQualification from "../../components/SuperAdminComp/LocationQualification";
import axiosInstance from "../../../Fbaseurl";

import handIcon from "../../../assets/hand.png";
import Button from "../../components/CommonComponents/Button";
import { showToast } from "../../../store/reducers/ToastMessageReducer";
import ConfirmationPopup from "../../components/CommonComponents/ConfirmationPopup";
import Header from "../../components/CommonComponents/Header";


import "./index.css";
import navBackArrow from "../../../assets/navBackArrow.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setSelectedTab } from "../../../store/reducers/SuperAdminReducer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  fetchStates,
  fetchBusinessSizeCodes,
  fetchHeadCountCodes,
  fetchYearsInBusinessCodes,
} from "../../../store/reducers/TaxCreditsReducer";
import QuestionCircle from "../../../assets/QuestionCircle.svg";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
const UpdateTaxCredits = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, type } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('page');
  const {
    statesList,
    businessSizeCodesList,
    headcountCodesList,
    yearsInBusinessCodesList,
    loading,
    error,
  } = useSelector((state) => state.TaxCredits);
  const [confirmationContent, setConfirmationContent] = useState(
    "Are you sure you want to Cancel ?"
  );
  const [isPopupOpen, setPopup] = useState(false);
  const [taxCreditName, setTaxCreditName] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [eligibilityCriteria, setEligibilityCriteria] = useState([])

  const [titleInitialValues, setTitleInitialValues] = useState({
    name: "",
    description: "",
  });

  const isPropertyPresent = (val) => {
    return eligibilityCriteria.some(property => property.propertyName.toLowerCase() === val.toLowerCase())
  }
  const handleCheckBoxChange = (val)=> {
   let isPresent =  isPropertyPresent(val)
    if(isPresent) {
      setEligibilityCriteria(oldValues => oldValues.filter(item => item.propertyName !== val)
      )
    } else {
      setEligibilityCriteria((data)=>[...data,{propertyName: val} ])
    }
  }
  const [creditInitialValues, setCreditInitialValues] = useState({
    qualificationDifficulty: null,
    requiresNegotiation: "no",
    avgCreditPerEmployee: null,
    avgCreditPerNewHire: null,
    avgJobGrowthCreditPerNewHire: null,
    lookBackYears: null,
    expirationDate: null,
  });
  const [businessInitialValues, setBusinessInitialValues] = useState({
    includedNaicsCodes: null,
    excludedNaicsCodes: null,
    businessSizes: [],
    locationHeadcounts: [],
    totalHeadcounts: [],
    yearsInbusiness: [],
    annualNewHires: null,
  });
  const [locationInitialValues, setLocationInitialValues] = useState({
    states: [],
    cities: null,
    fipsCodes: null,
    censusTracts: null,
  });

  const titleSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
  });
  // const creditInfoSchema = Yup.object().shape({
  //   expirationDate: Yup.string().required("Expire date is required"),
  //   requiresNegotiation: Yup.string().required(
  //     "Requires negotiation is required"
  //   ),
  // });
  // const businessesSchema = Yup.object().shape({
  //   annualNewHires: Yup.string().trim().required("Annual hires is required"),
  // });
  const locationSchema = Yup.object().shape({
    // name: Yup.string().required("Name is required"),
  });

  const titleFormik = useFormik({
    initialValues: titleInitialValues,
    enableReinitialize: true,
    validationSchema: titleSchema,
    onSubmit: async () => {
      if (isLoading) {
        return;
      }
      if (Object.keys(titleFormik.errors).length === 0) {
      }
    },
  });

  const creditInfoFormik = useFormik({
    initialValues: creditInitialValues,
    enableReinitialize: true,
    // validationSchema: creditInfoSchema,
    onSubmit: async () => {
      if (Object.keys(creditInfoFormik.errors).length === 0) {
        setCurrentTab(currentTab + 1);
      }
    },
  });

  const businessFormik = useFormik({
    initialValues: businessInitialValues,
    enableReinitialize: true,
    // validationSchema: businessesSchema,
    onSubmit: async () => {
      if (Object.keys(businessFormik.errors).length === 0) {
        setCurrentTab(currentTab + 1);
      }
    },
  });
  const convertDataIntoReqFormat = (data) => {
    if(!data?.length) {
      return []
    }
    const list = data.map((item, index) => {
      let obj = {
        code: item,
      };
      return obj;
    });
    return list;
  };

  // const convertStatesResponseIntoReqFormat = (data) => {
  //   if(data.length) {

  //     const updatedStates = data.reduce((acc, cur) => {
  //       if (cur) {
  //         acc.push(
  //         //   {
  //         //   id: cur.code,
  //         //   key: cur.name,
  //         // }
  //         cur.code
  //         );
  //       }
  //       return acc;
  //     }, []);
  //     return updatedStates;
  //   } else {
  //     return []
  //   }
  // };

  const convertResponseIntoReqFormat = (data) => {
    if (!data?.length) {
      return [];
    }
    const updatedStates = data.reduce((acc, cur) => {
      if (cur) {
        acc.push(
        //   {
        //   id: cur.code,
        //   key: cur.description,
        // }
        cur.code
        );
      }
      return acc;
    }, []);
    return updatedStates;
  };

  const convertNaicsDataIntoReqFormat = (data, type) => {
    if (data?.length) {
      let updatedData = [];
      let modifiedData = data.split(",");
      modifiedData.forEach((ele) => {
        let obj;
        if(type === 'CITIES') {
           obj = {
            name: ele.trim(),
          };
        } else {
           obj = {
            code: ele.trim(),
          };
        }
        updatedData.push(obj)
      });
      return updatedData
    }
  };

  const locationFormik = useFormik({
    initialValues: locationInitialValues,
    enableReinitialize: true,
    validationSchema: locationSchema,
    onSubmit: async () => {
      if (isLoading) {
        return;
      }
      if (
        Object.keys(titleFormik.errors).length === 0 &&
        Object.keys(creditInfoFormik.errors).length === 0 &&
        Object.keys(businessFormik.errors).length === 0 &&
        Object.keys(locationFormik.errors).length === 0
      ) {
        setLoader(true);
        let payload = {
          name: titleFormik.values.name.trim(),
          description: titleFormik.values.description,
          qualificationDifficulty: Number(
            creditInfoFormik.values.qualificationDifficulty
          ),
          requiresNegotiation:
            creditInfoFormik.values.requiresNegotiation === "yes"
              ? true
              : false,
          isActive: id ? isActive : true,
          avgCreditPerEmployee: Number(
            creditInfoFormik.values.avgCreditPerEmployee
          ),
          avgCreditPerNewHire: Number(
            creditInfoFormik.values.avgCreditPerNewHire
          ),
          avgJobGrowthCreditPerNewHire: Number(
            creditInfoFormik.values.avgJobGrowthCreditPerNewHire
          ),
          lookBackYears: Number(creditInfoFormik.values.lookBackYears),
          expirationDate: creditInfoFormik.values.expirationDate ? creditInfoFormik.values.expirationDate : null,
          cchDetailLink: "",
          minimumAnnualNewHires: businessFormik.values.annualNewHires || null,
          locationHeadcounts: convertDataIntoReqFormat(
            businessFormik.values.locationHeadcounts
          ),
          totalHeadcounts: convertDataIntoReqFormat(
            businessFormik.values.totalHeadcounts
          ),
          yearsInBusiness: convertDataIntoReqFormat(
            businessFormik.values.yearsInbusiness
          ),
          businessSizes: convertDataIntoReqFormat(
            businessFormik.values.businessSizes
          ),
          states: convertDataIntoReqFormat(locationFormik.values.states),
          cities: convertNaicsDataIntoReqFormat(locationFormik.values.cities, 'CITIES'),
          fipsCodes: convertNaicsDataIntoReqFormat(locationFormik.values.fipsCodes),
          // [
          //   {
          //     code: Number(locationFormik.values.fipsCodes),
          //   },
          // ],
          censusTracts: convertNaicsDataIntoReqFormat(locationFormik.values.censusTracts),
          //  [
          //   {
          //     code: Number(locationFormik.values.censusTracts),
          //   },
          // ],
          includedNaicsCodes: convertNaicsDataIntoReqFormat(
            businessFormik.values.includedNaicsCodes
          ),
          // [
          //   {
          //     code: Number(businessFormik.values.includedNaicsCodes),
          //   },
          // ],
          excludedNaicsCodes: convertNaicsDataIntoReqFormat(businessFormik.values.excludedNaicsCodes),
          //  [
          //   {
          //     code: Number(businessFormik.values.excludedNaicsCodes),
          //   },
          // ],
          eligibilityCriteria: eligibilityCriteria
        };
        console.log('256', payload)
     
        let url = id ? `/taxCredits/${id}` : `/taxCredits`;
        try {
          const response = id
            ? await axiosInstance.put(url, payload)
            : await axiosInstance.post(url, payload);
          if (response) {
            setLoader(false);
            dispatch(
              showToast({
                message: `${titleFormik.values.name} has ${
                  id ? "updated" : "added"
                } successfully!`,
              })
            );
            navigate(`/superadmin/dashboard/taxcredits?page=${page}`);
            dispatch(setSelectedTab(3));
          }
        } catch (error) {
          setLoader(false);
          let errorMsg = error?.response?.data?.title;
          dispatch(
            showToast({
              type: "error",
              message:
                errorMsg ||
                `Failed to ${id ? "update" : "add"} ${
                  titleFormik.values.name
                } tax credit`,
            })
          );
          console.log(error);
        }
      }
    },
  });

  const [currentTab, setCurrentTab] = useState(1);
  const [isLoading, setLoader] = useState(false);

  const navNext = async () => {
    titleFormik.handleSubmit();
    creditInfoFormik.handleSubmit();
    businessFormik.handleSubmit();
    locationFormik.handleSubmit();
  };
  const navBack = () => {
    navigate(`/superadmin/dashboard/taxcredits?page=${page}`);
    dispatch(setSelectedTab(3));
  };

  const convertNaicsRespIntoReqFormat = (data, type) => {
    if (!data?.length) {
      return;
    }
    let modifiedData = [];
    data.forEach((element) => {
      if(type === 'CITIES') {
        modifiedData.push(element.name);
      } else {
        modifiedData.push(element.code);
      }
    });
    return modifiedData.toString();
  };

  const updateForm = (data) => {
    setTitleInitialValues({
      name: data.name,
      description: data.description,
    });
    console.log("289;;", data);
    setCreditInitialValues({
      qualificationDifficulty: data.qualificationDifficulty,
      requiresNegotiation: data.requiresNegotiation === true ? "yes" : "no",
      avgCreditPerEmployee: data.avgCreditPerEmployee,
      avgCreditPerNewHire: data.avgCreditPerNewHire,
      avgJobGrowthCreditPerNewHire: data.avgJobGrowthCreditPerNewHire,
      lookBackYears: data.lookBackYears,
      expirationDate: data.expirationDate
        ? data.expirationDate.split("T")[0]
        : null,
    });
    setBusinessInitialValues({
      includedNaicsCodes: convertNaicsRespIntoReqFormat(
        data.includedNaicsCodes
      ),
      excludedNaicsCodes: convertNaicsRespIntoReqFormat(
        data.excludedNaicsCodes
      ),
      businessSizes: convertResponseIntoReqFormat(data.businessSizes),
      locationHeadcounts: convertResponseIntoReqFormat(data.locationHeadcounts),
      totalHeadcounts: convertResponseIntoReqFormat(data.totalHeadcounts),
      yearsInbusiness: convertResponseIntoReqFormat(data.yearsInBusiness),
      annualNewHires: data.minimumAnnualNewHires
    });
    setLocationInitialValues({
      states: convertResponseIntoReqFormat(data.states) ,
      cities: convertNaicsRespIntoReqFormat(data.cities, 'CITIES'),
      fipsCodes: convertNaicsRespIntoReqFormat(data.fipsCodes), //It will change w.r.to new ui
      censusTracts: convertNaicsRespIntoReqFormat(data.censusTracts),
    });
    setEligibilityCriteria(data.eligibilityCriteria)
  };

  const getTaxCreditInfo = async () => {
    try {
      const response = await axiosInstance.get(`/taxCredits/${id}`);
      if (response.status === 200) {
        setTaxCreditName(response.data.name);
        setIsActive(response.data.isActive);
        updateForm(response.data);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const cancelConfirmation = () => {
    setPopup(true);
  };

  const onConfirmCancel = () => {
    navigate(`/superadmin/dashboard/taxcredits?page=${page}`);
    dispatch(setSelectedTab(3));
    setPopup(false);
  };

  const onCancelClick = () => {
    setPopup(false);
  };

  useEffect(() => {
    if (id) {
      getTaxCreditInfo();
    }
  }, [id]);

  useEffect(() => {
    dispatch(fetchStates());
    dispatch(fetchBusinessSizeCodes());
    dispatch(fetchHeadCountCodes());
    dispatch(fetchYearsInBusinessCodes());
  }, [dispatch]);

  const getGreeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return "Good Morning!";
    } else if (currentHour < 18) {
      return "Good Afternoon!";
    } else {
      return "Good Evening!";
    }
  };
  return (
    <>
      <div className="dashboardContainer d-flex flex-column h-100 flex-grow-1">
        <Header />
        <div className="content-wrapper pt-4">
          <div className="col-11 m-auto">
            <div className="form-back-control d-flex align-items-center justify-content-between gap-4">
              <div className="form-back-control d-flex align-items-center gap-4 pb-2">
                <ArrowBackIcon
                  className="back-btn"
                  onClick={() => {
                    navigate(`/superadmin/dashboard/taxcredits?page=${page}`);
                    dispatch(setSelectedTab(3));
                  }}
                />
                <p className="rowtext-wrap fw-bold fs-4 m-0">{taxCreditName}</p>
              </div>
              {type === "view" && (
                <Button
                  className="float-end"
                  variant="contained"
                  color="primary"
                  primary={true}
                  isLoading={isLoading}
                  label="Edit"
                  icon={<EditOutlinedIcon></EditOutlinedIcon>}
                  onClick={() => {
                    navigate(`/superadmin/taxcredit/edit/${id}?page=${page}`);
                  }}
                />
                // <LoadingButton
                //   variant="contained"
                //   color="primary"
                //   size="medium"
                //   sx={{ textTransform: "none" }}
                //   onClick={() => {
                //     navigate(`/superadmin/taxcredit/edit/${id}`);
                //   }}
                //   loading={isLoading}
                // >
                //   <EditOutlinedIcon></EditOutlinedIcon> Edit
                // </LoadingButton>
              )}
            </div>
            {/* <div className="mb-3 d-flex align-items-center gap-1">
              <span className="fs-1 fw-bold">Hello John</span>
              <img src={handIcon} alt="handIcon" />
              <span className="fs-1">{getGreeting()}</span>
            </div> */}

            <div className="table-content ">
              <div className="tax-credit">
                <div className="fw-bold py-2 pt-4 px-4 font-20">Title</div>
                <div className="border rounded p-4 m-4">
                  <Title formik={titleFormik}></Title>
                </div>
                <div className="fw-bold py-2 pt-4  px-4 font-20">
                  Credit Information
                </div>
                <div className="border rounded p-4 m-4">
                  <CreditInfo formik={creditInfoFormik}></CreditInfo>
                </div>
                <div className="fw-bold py-2 pt-4  px-4 font-20">
                  Business Qualification
                </div>
                <div className="border rounded p-4 m-4">
                  <BusinessQualification
                    formik={businessFormik}
                    businessSizeCodesList={businessSizeCodesList}
                    headcountCodesList={headcountCodesList}
                    yearsInBusinessCodesList={yearsInBusinessCodesList}
                    eligibilityCriteria={eligibilityCriteria}
                    onChecked={handleCheckBoxChange}
                    isPropertyPresent={isPropertyPresent}
                  ></BusinessQualification>
                </div>
                <div className="fw-bold py-2 pt-4  px-4 font-20">
                  Location Qualification
                </div>
                <div className="border rounded p-4 m-4">
                  <LocationQualification
                    formik={locationFormik}
                    statesList={statesList}
                    eligibilityCriteria={eligibilityCriteria}
                    onChecked={handleCheckBoxChange}
                    isPropertyPresent={isPropertyPresent}
                  ></LocationQualification>
                </div>
              </div>

              {type !== "view" && (
                <div className="d-flex justify-content-end mt-4 float-end gap-3">
                  {/* <button
                      className="border-0 bg-white py-1 cancel mr-1"
                      onClick={cancelConfirmation}
                    >
                      Cancel
                    </button> */}
                  {/* <button
                      className={`nextBtn border-0 p-5 py-1 mt-auto px-4`}
                      type="button"
                      role="status"
                      onClick={navNext}
                    >
                      {isLoading ? (
                        <div
                          className="fw-bold spinner-border spinner-border-sm mx-2 p-1"
                          role="status"
                        ></div>
                      ) : (
                        "Save"
                      )}
                    </button> */}
                  <Button
                    variant="text"
                    color="primary"
                    label="Cancel"
                    onClick={cancelConfirmation}
                    className="mr-1"
                  ></Button>
                  <Button
                    variant="contained"
                    color="primary"
                    label="Save"
                    onClick={navNext}
                    isLoading={isLoading}
                  ></Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <ConfirmationPopup
          isPopupOpen={isPopupOpen}
          content={confirmationContent}
          icon={QuestionCircle}
          onCancel={onCancelClick}
          onConfirm={onConfirmCancel}
        ></ConfirmationPopup>
      </div>
    </>
  );
};

export default UpdateTaxCredits;
