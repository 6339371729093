import * as React from "react";
import "./index.css";

const Radio = ({ checked, onChange, label, name, value, disabled }) => {
  return (
    <div className="bulgy-radios">
      <label>
        <input type="radio" name={name} value={value} checked={checked} onChange={onChange} disabled={disabled} />
        <span className="radio"></span>
        {label &&<span className="label">{label}</span>}
      </label>
    </div>
  );
};

export default Radio;
