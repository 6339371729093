import { useState } from "react";
import axiosInstance from "../../Fbaseurl";
import {showToast} from "../../store/reducers/ToastMessageReducer";
import Button from "../components/CommonComponents/Button";



import "./index.css";

import forgotPwdLogo from "../../../src/assets/forgotPwdLogo.svg";
import HrlogicsLogo from "../../assets/HrLogicsLogo.svg"
import superAdminLogo from "../../../src/assets/HrLogicsLogo.svg";
import Logo from "../../../src/assets/Taxraven.svg";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from '@mui/lab/LoadingButton';
import InputField from "../components/CommonComponents/InputField";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from '@mui/material'; 

const ForgotPassword = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEmailSent, setIsEmailSent] = useState(false)
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async () => {
      if (isLoading) {
        return;
      }
      if (Object.keys(formik.errors).length === 0) {
        setLoader(true);
        try {
          const response = await axiosInstance.post("/account/forgotPassword", {
            email: formik.values.email,
          });
          // if (response) {
          //   navigate("/resetPassword");
          // }
          setIsEmailSent(true);
          setLoader(false);
          dispatch(
            showToast({
              message: `If the email is registered, you will receive a password reset link`,
            })
          );
        } catch (error) {
          console.log(error);
          setLoader(false);

          setTtitle("WRONG_EMAIL");
        }
      }
    },
  });

  const [isLoading, setLoader] = useState(false);
  const [title, setTtitle] = useState("FORGOT_PASSWORD");

  return (
    <div className="login-wrapper">
      <div className="sm-row login-container d-lg-flex d-md-flex justify-content-between position-absolute fixed-bottom h-100">
      {!isMobile && <div className="col-lg-7 col-md-7 col-sm-12 left-container text-center overflow-hidden align-content-end">
          {/* <img src={HrlogicsLogo} className="hrlogicsLogo"></img> */}
          <img
            className="login-img"
            src={forgotPwdLogo}
            alt="super-admin-login"
          />
        </div> }
        <div className="col-lg-5 col-md-5 col-sm-12 right-container p-4">
          <div className="d-flex flex-column justify-content-around  p-3 h-100">
            <div>
              <img src={Logo} alt="logo" />
            </div>
            <div>
              {title === "FORGOT_PASSWORD" && (
                <>
                  {/* <h1 className="form-header fw-bold mb-3 lh-1">
                    Forgot Password ?
                  </h1> */}
                  <h1 className="text-blue title p-0 fw-bold mb-3 lh-1">
                    Forgot Password ?
                  </h1>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="d-flex flex-column gap-3 form-group"
                  >
                    <InputField
                      placeholder="John doe"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      title="Email address"
                      type="email"
                      className="form-control"
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="error ">{formik.errors.email}</div>
                    )}
                    <div className="row d-flex align-items-end">
                      <div className="d-flex justify-content-end mt-2">
                        {/* <button
                          className={`submit-btn border-0 fw-bold px-2 py-1`}
                          type="submit"
                          role="status"
                        >
                          {isLoading ? (
                            <div
                              className="spinner-border spinner-border-sm mx-2 p-1"
                              role="status"
                            ></div>
                          ) : (
                            "Submit"
                          )}
                        </button> */}
                        <Button
                          label="Submit"
                          className="fw-bold"
                          type="submit"
                          color="primary"
                          variant="contained"
                          isLoading={isLoading}
                          disabled={isEmailSent}
                          containerClass={isMobile ? 'w-100' : ''}
                          sx={isMobile ? {marginTop: '100%'} : ''}
                        />
                      </div>
                      <div className={`d-flex  ${isMobile ? 'justify-content-center mt-2' : 'justify-content-end mt-4'}`}>
                        <button
                          className="border-0 bg-white"
                          onClick={() => navigate("/")}
                        >
                          Go to login
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )}
              {title === "WRONG_EMAIL" && (
                <>
                  <h1 className="form-header fw-bold mb-3 lh-1">Oops!</h1>
                  <div>{formik.values.email}</div>
                  <div>Email id not found in our server</div>
                  <div className="row">
                    <div className="d-flex justify-content-end">
                      <button
                        className={`nextBtn border-0 px-5 py-1 mt-4`}
                        onClick={() => navigate("/")}
                      >
                        Back To Login
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
