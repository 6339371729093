import { useSelector } from "react-redux";

import { formatCurrency } from "../../../../CommonUtils/currencyUtils";

import Logo from "../../../../assets/Taxraven.svg";
import "./index.css";

import html2pdf from "html2pdf.js";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HrlogicsTitle from "../../../../assets/HrlogicsTitle.svg";
import HrlogicsLogo from "../../../../assets/HrlogicsLogoBlue.svg";
import AsureLogo from "../../../../assets/AsureLogo.svg";
import HrlogicsSmallLogo from "../../../../assets/HrlogicsSmallLogo.svg";
import { todayDateStartsWithDate } from "../../../../CommonUtils/dateUtils";
import { useMediaQuery } from '@mui/material';

const TaxCreditAnalysis = ({ businesses, companyInfo }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTab =  useMediaQuery('(max-width:769px)');
  const name = useSelector((state) => state.App.userName);
 

  return (
    <div className="taxCreditWrapper">
      <div className="px-4">
        <div className="documentViewer">
          <div className="row ">
            <div className="card taxCreditCard px-2">
              <div
                id="pdf-content"
                className={` my-2 pb-1 mb-0 mx-auto ${isMobile || isTab ? 'px-1 py-1' : 'px-5 mx-5 py-2'}`}
                style={ !isMobile || !isTab ? {padding: "4rem", maxWidth: "947px" }: {}}
              >
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    paddingTop: "18px",
                  }}
                >
                  <img height="32px" width="140px" src={Logo} alt=""></img>
                </div>

                <h1 className="taxTitle">Tax Credit Analysis</h1> */}
                <div className="bg-white">
                  <div className="intro-card bg-white">
                    <div className="d-flex justify-content-between">
                      <div className="tax-left">
                        <div className="d-flex">

                        <img
                          src={companyInfo.logoUrl ? companyInfo.logoUrl : AsureLogo}
                          alt="Hrlogics"
                        width={isMobile ? "60px" : isTab ? '80px' : '100px'}
                        height={isMobile ? "60px" : isTab ? '80px' : '100px'}
                        className="my-auto"
                          // width={isMobile ? "124px" : isTab ? '240px' : ''}
                        ></img>
                        {/* <div  className="mx-1 company-title my-auto">{companyInfo.companyName}</div> */}
                        </div>
                        <div className="taxcredit-title ">
                          Tax Credit <br></br>
                          Analysis
                        </div>
                      </div>
                      <div>
                        <img
                          src={HrlogicsLogo}
                          alt="Logo"
                          height={isMobile ? "250px" : "400px"}
                          width={isMobile ? "110px" : "240px"}
                          className="hrlogo"
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between intro-content">
                    <div className="tax-left font-primary-blue">
                      <div className="borders py-4">
                        <div className={`${isMobile ? 'font-16' : 'font-20'}`}>Provided by:</div>
                        <div className={`fw-bold ${isMobile ? ' font-20' : 'font-24'}`}>{name}</div>
                        <div className={`pt-3 ${isMobile ? ' font-16' : ' font-20'}`}>Presented:</div>
                        <div className={`fw-bold ${isMobile ? ' font-20' : 'font-24'}`}>
                          {todayDateStartsWithDate()}
                        </div>
                      </div>
                    </div>

                    <div className={`right ${isMobile ? ' col-4' : ' col-4'}`}>
                      <div
                        className={`aboutus ${isMobile ? ' p-2' : ' p-3'}`}
                        style={{ zIndex: "1000", marginTop: "-100px" }}
                      >
                        <div className={` fw-bold pb-2 ${isMobile ? 'font-16' : 'font-20'}`}>ABOUT US:</div>
                        <p className={`${isMobile ? 'font-12' : 'font-12'}`}>
                          With more than 3,000 credits available to employers,
                          your organization deserves a skilled tax credit team
                          to guide your organization to maximize credit yield.
                          The key to unlocking the full potential of tax credits
                          lies in the partnering with a provider capable of
                          screening for all applicable credits.
                        </p>
                        <p className={`${isMobile ? 'font-12' : 'font-12'}`}>
                          As your trusted profitablility partner with over 150
                          years of collective credit experience and over $3B in
                          tax credits captured, HRlogics handles all the
                          paperwork and administration, so you can focus on
                          growing your business.
                        </p>
                      </div>
                      
                    </div>
                  </div>

                  <div className={`bottom-card p-left-80  ${isMobile ? 'py-2 my-3' : 'py-4 mt-5 mb-20'}`} >
                    <div className="font-20 fw-bold">POWERED BY:</div>
                    <img src={HrlogicsTitle} alt="Asure"   
                          width={isMobile ? "124px" : ""}></img>
                  </div>
                </div>

                <div>
                  {businesses.map((post, index) => {
                    return (
                      // <>
                      <div key={postMessage.id} className="tax-card mt-4">
                        <div className="intro-card mb-4 ">
                          <div className="d-flex justify-content-between">
                            <div className="pt-4 px-4">
                              <div className="d-flex">

                              <img 
                              // src={AsureLogo} alt="Hrlogics"    
                              //   height={isMobile ? "50px" : isTab ? '80px' : ''}
                              //    width={isMobile ? "124px" : isTab ? "190px" : ""}>
                              src={companyInfo.logoUrl ? companyInfo.logoUrl : AsureLogo}
                              alt="Hrlogics"
                              width={isMobile ? "60px" : isTab ? '80px' : '100px'}
                              height={isMobile ? "60px" : isTab ? '80px' : '100px'}>
                                 </img>
                                 {/* <div  className="mx-1 company-title my-auto">{companyInfo.companyName}</div> */}
                              </div>
                              <div className="review">Tax Credit Review</div>
                            </div>
                            <div>
                              <img src={HrlogicsSmallLogo} alt="Logo" height={isMobile ? "124px" : ""} 
                                width={isMobile ? "84px" : ""}></img>
                            </div>
                          </div>
                        </div>
                        <div className="content">
                          <div className="card">
                            <div
                              style={{ padding: "unset" }}
                              className="card-body"
                            >
                              <div
                                style={{
                                  backgroundColor: "#EBEBEC",
                                  padding: "1rem",
                                }}
                              >
                                <div className="card-title">
                                  <p
                                    className="business-name"
                                  >
                                    {post.businessName}{" "}
                                    <a
                                      href={`https://${post.url}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className={`btn-link text-blue font-500 ${isMobile ? ' font-14' : ' font-16'}`}
                                    >
                                      {" "}
                                      {post.url}
                                    </a>
                                  </p>
                                </div>
                                <div className={`card-title ${isMobile ? ' font-14' : ' font-16'}`}>
                                  <p className="docuinfo">
                                    {post.siC01Description} located at{" "}
                                    {post.physicalAddress}, {post.physicalCity},{" "}
                                    {post.state}, {post.physicalZip}
                                  </p>
                                  {/*  <p>{post.physicalAddress},{post.physicalCity},{post.state}{post.physicalZip}</p>*/}
                                </div>
                              </div>

                              <div className={`documentInfo ${isMobile ? ' font-14' : ' font-16'}`}>
                                <div className="form_text1">
                                  <span className={`${isMobile ? 'col-12' : 'col-2'}`}>
                                    Projected credit
                                  </span>{" "}
                                  {isMobile ? '' : ':'}
                                  <span className={`font-700 ${!isMobile ? 'docViewForTitle' : ''}`}>
                                    {post?.taxCredits[0]
                                      ?.totalProjectedTaxCreditAmount
                                      ? "$" +
                                        formatCurrency(
                                          post?.taxCredits[0]
                                            ?.totalProjectedTaxCreditAmount
                                        )
                                      : "No Data Available"}
                                  </span>
                                </div>
                                <div className={`form_text1 ${isMobile ? 'pt-2' : ''}`}>
                                  <span className={`${isMobile ? 'col-12' : 'col-2'}`}>Employees</span> 
                                  {isMobile ? '' : ':'}
                                  <span className={`${isMobile ? 'font-700' : 'docViewForTitle'}`}>
                                    {post?.locationEmployeesCount
                                      ? formatCurrency(
                                          post?.locationEmployeesCount
                                        )
                                      : "No Data Available"}
                                  </span>
                                </div>
                                <div className={`form_text1 ${isMobile ? 'pt-2' : ''}`}>
                                  <span className={`${isMobile ? 'col-12' : 'col-2'}`}>Annual hires</span>
                                  {isMobile ? '' : ':'}
                                  <span className={`${isMobile ? 'font-700' : 'docViewForTitle'}`}>
                                    {" "}
                                    {post?.annualHires
                                      ? formatCurrency(post?.annualHires)
                                      : "No Data Available"}
                                  </span>{" "}
                                </div>
                                <div className={`form_text1 ${isMobile ? 'pt-2' : ''}`}>
                                  <span className={`${isMobile ? 'col-12' : 'col-2'}`}>Contact phone</span> 
                                  {isMobile ? '' : ':'}
                                  <span className={`${isMobile ? 'font-700' : 'docViewForTitle'}`}>
                                    {" "}
                                    {post?.phone
                                      ? post.phone
                                      : "No Data Available"}
                                  </span>
                                </div>
                                <div className={`form_text1 ${isMobile ? 'pt-2' : ''}`}>
                                  <span className={`${isMobile ? 'col-12' : 'col-2'}`}>Contact email</span>
                                  {isMobile ? '' : ':'}
                                  <span className={`${isMobile ? 'font-700' : 'docViewForTitle'} ${post.email ? ' text-blue' : ''}`}>
                                    {" "}
                                    {post.email
                                      ? post.email
                                      : "No Data Available"}
                                  </span>
                                </div>
                                <div className={`form_text1 ${isMobile ? 'pt-2' : ''}`}>
                                  <span className={`${isMobile ? 'col-12' : 'col-2'}`}>Annual sales</span> 
                                  {isMobile ? '' : ':'}
                                  <span className={`${isMobile ? 'font-700' : 'docViewForTitle'}`}>
                                    {post?.salesVolume
                                      ? "$" +
                                        formatCurrency(Number(post.salesVolume))
                                      : "No Data Available"}
                                  </span>
                                </div>
                                <div className={`form_text1 ${isMobile ? 'pt-2' : 'd-flex'}`}>
                                  <div className={`viewerTitle ${isMobile ? 'col-12' : 'col-2'}`}>
                                    Credits identified
                                  </div>{" "}
                                  <div className={`col-10 ${isMobile ? '' : 'docViewForTitle'}`}>
                                    <div className="listingTitle">
                                      {post?.taxCredits[0]?.availableTaxCredits
                                        ?.length
                                        ? post?.taxCredits[0]?.availableTaxCredits?.map(
                                            (obj, pIndex) => {
                                              return (
                                                <div
                                                  key={obj.id}
                                                  className={`credits-identified ${isMobile ? '' : 'px-2'} ${
                                                    pIndex > 0 ? "py-2" : ""
                                                  } `}
                                                >
                                                  <div className="fw-bold text-wrap ">
                                                    {obj.name}
                                                  </div>
                                                  <div className="text-wrap">{obj.description}</div>
                                                </div>
                                              );
                                            }
                                          )
                                        : "No Credits Available"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*//        </>*/}
                              {/*//    )*/}
                              {/*//})};*/}
                            </div>
                          </div>
                          {businesses.length === index + 1 && (
                            <div className="py-4 m-auto credits-identified">
                              <div className="credits-identified disclaimer">
                              <InfoOutlinedIcon
                                fontSize="small"
                                sx={{ color: "grey" }}
                              ></InfoOutlinedIcon>{" "}
                              <span className="pl-1 text-grey font-14">
                                Projected savings are based on estimates related
                                to employee hiring and retention, reflecting
                                adjustments to annual hire data. State credits
                                use a 5% participation rate, while federal
                                credits apply a 20% factor. Actual results may
                                vary.
                              </span>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* <div className="bottom-card p-left-80 py-4 mt-5 footer">
                          <div className="font-20 fw-bold">POWERED BY:</div>
                          <img src={HrlogicsTitle} alt="Asure"></img>
                        </div> */}
                      </div>

                      // </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaxCreditAnalysis;
