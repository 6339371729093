import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  useMap,
} from "react-leaflet";
import {formatCurrency} from "../../CommonUtils/currencyUtils";
import { greenIcon, blueIcon, yellowIcon, redIcon, purpleIcon } from "../../icons";
import { TILE_LAYER_URL, TILE_LAYER_ATTRIBUTION } from "../../urls";
import Button from "../components/CommonComponents/Button";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import styles from "./MapView.module.css";
import Slider from "@mui/material/Slider";
import filterIcon from "../../assets/Filter.svg";
import LoadingButton from '@mui/lab/LoadingButton';
import { useMediaQuery } from '@mui/material';
// Fix the default icon issue with Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

const MapView = ({ businesses }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [selectedCompanies, setSelectedCompanies] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [creditValue, setCreditValue] = useState([0, 0]);
  const [employeeCountValue, setEmployeeCountValue] = useState([0, 0]);
  const [isFilterCleared, setIsFilterCleared] = useState(false);
  const [filteredBusinesses, setFilteredBusinesses] = useState([]);
  const [updatedBusinessesList, setUpdatedBusinessesList] = useState([]);
  const customCreditSteps = [0, 50000, 100000, 250000, 500000, 750000, 1000000];
  const creditMarks = customCreditSteps.map((step) => ({
    value: step,
  }));

  const customEmpSteps = [0, 50, 100, 250, 500, 1000, 2500, 5000, 7500, 10000];
  const empMarks = customEmpSteps.map((step) => ({
    value: step,
  }));

  useEffect(() => {
    const initialProcessedBusinesses = processBusinesses(businesses);
    setFilteredBusinesses(initialProcessedBusinesses);
    findBusinessesWithSameLatLang(initialProcessedBusinesses);
  }, [businesses]);


  useEffect(() => {
    if(isFilterCleared) {

      applyFilter();
    }
  }, [isFilterCleared]);

  const handleMarkerClick = (lat, lng, companyName) => {
    const key = `${lat}-${lng}`;
    setSelectedCompanies((prevState) => ({
      ...prevState,
      [key]: prevState[key] ? [...prevState[key], companyName] : [companyName],
    }));
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const processBusinesses = (businessesData) => {
    return businessesData.map((business) => {
      console.log('80',business)
      const totalProjectedTaxCredits = calculateProjectedTaxCredits(
        business.locationEmployeesCount,
        business.taxCredits
      );
      return {
        ...business,
        // totalProjectedTaxCredits,
      };
    });
  };

  // Function to calculate projected tax credits
  const calculateProjectedTaxCredits = (locationEmployeesCount, taxCreditsArray) => {
    let totalProjectedTaxCredits = 0;
console.log('94',taxCreditsArray)
    taxCreditsArray.forEach((taxCreditGroup) => {
      taxCreditGroup.availableTaxCredits.forEach((taxCredit) => {
        const factor =
          taxCredit.name.toLowerCase() === "wotc" ||
          taxCredit.name.toLowerCase() === "work opportunity tax credit"
            ? 0.2
            : 0.35;
        totalProjectedTaxCredits += locationEmployeesCount * factor;
      });
    });

    return totalProjectedTaxCredits;
  };

  const clearFilter = () => {
    setCreditValue([0,0]);
    setEmployeeCountValue([0,0]);
    setIsFilterCleared(true)
    // applyFilter();
  }

  const applyFilter = () => {
    const processedBusinesses = processBusinesses(businesses);
    const filtered = processedBusinesses.filter((x) => {
      var employeeCountInRange;
      if (employeeCountValue[1] && employeeCountValue[1] === 10000) {
        employeeCountInRange = x.employeeCount >= employeeCountValue[0];
      } else {
        employeeCountInRange =
          (employeeCountValue[1]
            ? x.locationEmployeesCount <= employeeCountValue[1]
            : true) && x.locationEmployeesCount >= employeeCountValue[0];
      }
      var creditInRange;
      if (creditValue[1] && creditValue[1] === 1000000) {
        creditInRange =
          x.taxCredits[0].totalProjectedTaxCreditAmount >= creditValue[0];
      } else {
        creditInRange =
          (creditValue[1]
            ? x.taxCredits[0].totalProjectedTaxCreditAmount <= creditValue[1]
            : true) &&
          x.taxCredits[0].totalProjectedTaxCreditAmount >= creditValue[0];
      }
      return employeeCountInRange && creditInRange;
    });
    console.log(filtered);
    setFilteredBusinesses(filtered);
    findBusinessesWithSameLatLang(filtered);
    setShowFilter(!showFilter);
    setIsFilterCleared(false)
  };

  // Function to get marker icon based on projected tax credits
  const getMarkerIcon = (companies) => {
    if (companies && companies?.length === 1) {
      let totalProjectedTaxCredits =
        companies[0].taxCredits[0].totalProjectedTaxCreditAmount;
      if (totalProjectedTaxCredits > 1000000) {
        return greenIcon;
      } else if (
        totalProjectedTaxCredits >= 50000 &&
        totalProjectedTaxCredits <= 1000000
      ) {
        return blueIcon;
      } else if (
        totalProjectedTaxCredits < 50000 &&
        totalProjectedTaxCredits > 0
      ) {
        return yellowIcon;
      } else {
        return redIcon;
      }
    } else {
      return purpleIcon;
    }
  };

  const findBusinessesWithSameLatLang = (companies) => {
    const combinedCompanies = [];
    const companyMap = new Map();
    companies.forEach((company) => {
      const { lat, lng, ...rest } = company;
      const key = `${lat}-${lng}`;

      if (companyMap.has(key)) {
        const existingCompany = companyMap.get(key);
        existingCompany.commonCompanies.push(rest);
      } else {
        companyMap.set(key, {
          lat,
          lng,
          commonCompanies: [rest],
        });
      }
    });
    companyMap.forEach((value) => {
      combinedCompanies.push(value);
    });

    console.log("133S", combinedCompanies);
    setFilteredBusinesses(combinedCompanies);
    return combinedCompanies;
  };

  // const InitialZoomToMarker = ({ position }) => {
  //   const map = useMap();

  //   useEffect(() => {
  //     map.flyTo(position, 9); // Zoom level 13 for a closer view
  //   }, [map, position]);

  //   return null;
  // };

  // const stateBounds = [
  //   // [37.7749, -122.4194], // Southwest corner (lat, lon)
  //   // [37.8049, -122.4494], // Northeast corner (lat, lon)
  //   [25.8371, -106.6456], // Southwest corner (lat, lon)
  //   [36.5007, -93.5083],
  // ];

  // const usBounds = [
  //   [24.396308, -125.0],
  //   [49.384358, -66.93457]
  // ]

  //   // Component to programmatically control the map view
  //   const SetViewOnClick = ({ bounds }) => {
  //     const map = useMap();

  //     useEffect(() => {
  //       map.fitBounds(bounds);
  //     }, [map, bounds]);

  //     return null;
  //   };

  const FitBounds = ({ locations }) => {
    const map = useMap();

    useEffect(() => {
      if (locations.length > 0) {
        const bounds = L.latLngBounds(
          locations.map((loc) => [loc.lat, loc.lng])
        );
        map.fitBounds(bounds);
      }
    }, [map, locations]);

    return null;
  };
  function valuetext(value) {
    return `${value}`;
  }

  function empValuetext(value) {
    return `${value}`;
  }
  const handleChange = (event, newValue) => {
    setCreditValue(newValue);
  };

  const handleEmployeeChange = (event, newValue) => {
    setEmployeeCountValue(newValue);
  };

  const calcAvailableTaxCredtis = (emppCount, avgCredtis, name) => {
    let creditPercentage =
      name.toLowerCase() === "wotc" || name.toLowerCase() === "work opportunity tax credit" ? 0.2 : 0.35;
    let total = emppCount * avgCredtis * creditPercentage.toFixed(2);
    return `$ ${formatCurrency(total)}`;
  };

  return (
    <div style={{height: '100%'}}>
      {/* {JSON.stringify(filteredBusinesses)} */}
      <div className={styles.filter_button_end}>
        <LoadingButton
          variant="outlined"
          color="inherit"
          size="medium"
          sx={{ textTransform: "none", borderRadius: "8px" }}
          onClick={toggleFilter}
        >
          <img src={filterIcon} alt="filterIcon" /> Filter
        </LoadingButton>
        {/* <button className={styles.filter_button} onClick={toggleFilter}>
          <img src={filterIcon} alt="filterIcon" /> Filter
        </button> */}
      </div>
      {showFilter && (
        <div className={styles.filter_popup}>
          {/* <h3>Filter Options</h3> */}
          <div className="filter-option">
            <label htmlFor="credit" className="fw-bold">
              Credit: ${formatCurrency(Number(creditValue[0]))} - $
              {Number(creditValue[1]) === 1000000
                ? `${formatCurrency(1000000)}+`
                : formatCurrency(Number(creditValue[1]))}
              {/* Credit: ${Number(creditValue)} */}
            </label>
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={creditValue}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              min={0}
              max={1000000}
              step={null}
              marks={creditMarks}
              sx={{
                // Custom slider color
                "& .MuiSlider-thumb": {
                  backgroundColor: "#162395", // Thumb color
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#162395", // Track color
                },
                // "& .MuiSlider-rail": {
                //   backgroundColor: "#ffd233", // Rail color with lower opacity for contrast
                // },
              }}
            />
            {/* <Slider
              defaultValue={creditValue}
              getAriaValueText={setCreditValue}
              valueLabelDisplay="auto"
              min={0}
              max={1000000}
              sx={{
                color: "#ffd233", // Custom slider color
                "& .MuiSlider-thumb": {
                  backgroundColor: "#ffd233", // Thumb color
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#ffd233", // Track color
                },
                "& .MuiSlider-rail": {
                  backgroundColor: "#ffd233", // Rail color with lower opacity for contrast
                },
              }}
            /> */}
            {/* <input
              id="credit"
              type="range"
              min="0"
              max="1000000"
              value={creditValue}
              onChange={(e) => setCreditValue(Number(e.target.value))}
            /> */}
          </div>
          <div className={styles.filter_option}>
            <label htmlFor="employeeCount" className="fw-bold">
              Employee Count: {formatCurrency(Number(employeeCountValue[0]))} -{" "}
              {Number(employeeCountValue[1]) === 10000
                ? `${formatCurrency(10000)}+`
                : formatCurrency(Number(employeeCountValue[1]))}
            </label>
            {/* <input
              id="employeeCount"
              type="range"
              min="0"
              max="10000"
              value={employeeCountValue}
              onChange={(e) => setEmployeeCountValue(Number(e.target.value))}
            /> */}
            {/* <Slider
              aria-label="Temperature"
              defaultValue={employeeCountValue}
              getAriaValueText={setEmployeeCountValue}
              valueLabelDisplay="auto"
              max={10000}
              min={0}
              sx={{
                color: "#ffd233", // Custom slider color
                "& .MuiSlider-thumb": {
                  backgroundColor: "#ffd233", // Thumb color
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#ffd233", // Track color
                },
                "& .MuiSlider-rail": {
                  backgroundColor: "#ffd233", // Rail color with lower opacity for contrast
                },
              }}
            /> */}

            <Slider
              getAriaLabel={() => "Temperature range"}
              value={employeeCountValue}
              onChange={handleEmployeeChange}
              valueLabelDisplay="auto"
              getAriaValueText={empValuetext}
              min={0}
              max={10000}
              step={null}
              marks={empMarks}
              sx={{
                // color: "#ffd233", // Custom slider color
                "& .MuiSlider-thumb": {
                  backgroundColor: "#162395", // Thumb color
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#162395", // Track color
                },
                // "& .MuiSlider-rail": {
                //   backgroundColor: "#ffd233", // Rail color with lower opacity for contrast
                // },
              }}
            />
          </div>
          <div className={`${styles.apply_filter_button_end}  gap-2`}>
            <Button
              label="Clear"
              variant="text"
              onClick={clearFilter}
              color="primary"
              className="fw-bold"
              type="button"
            ></Button>
            <Button
              label="Apply"
              variant="contained"
              onClick={applyFilter}
              color="primary"
              className="fw-bold"
              type="button"
            ></Button>
          </div>
        </div>
      )}
      <MapContainer
        center={[37.0902, -95.7129]}
        zoom={4}
        style={{ height: "90%", width: "100%"}}
        maxBounds={[
          [-90, -180],
          [90, 180],
        ]}
        maxBoundsViscosity={1.0}
      >
        <TileLayer
          url={TILE_LAYER_URL}
          attribution={TILE_LAYER_ATTRIBUTION}
          noWrap={true}
        />
        {filteredBusinesses.map((business, index) => (
          // <>
           
            <div key={index + business.lat}>
              {/* {JSON.stringify(commonComp)} */}

              <Marker
                key={index + business.lat}
                position={[parseFloat(business.lat), parseFloat(business.lng)]}
                icon={getMarkerIcon(
                  business.commonCompanies
                  // commonComp.taxCredits[0].totalProjectedTaxCreditAmount
                  //   ? commonComp.taxCredits[0].totalProjectedTaxCreditAmount
                  //   : 0
                )}

                // eventHandlers={{
                //   mouseover: () => {
                //     handleMarkerClick(business.lat, business.lng, business.companyName)
                //   }
                // }}
              >
                <Popup className={styles.leaflet_popup}>
                  {business.commonCompanies.map((commonComp, cIndex) => (
                    <div key={cIndex}>
                      <h6 className="font-700">{commonComp.businessName}</h6>
                      <div className={styles.popup_available_credits}>
                        <div className="col-6 fw-bold text-grey font-12">
                          Projected Tax credit{" "}
                        </div>
                        <div className="col-6 font-700 text-grey font-12">
                        
                          {commonComp.taxCredits[0]
                            .totalProjectedTaxCreditAmount
                            ? "$" + formatCurrency(
                                commonComp.taxCredits[0]
                                  .totalProjectedTaxCreditAmount
                              )
                            : 'No data available'}{" "}
                        </div>
                      </div>

                      <div className={styles.popup_available_credits}>
                        <div className="col-6 fw-bold text-grey font-12">
                          Available Tax Credits:
                        </div>
                        <ol
                          className="col-6 text-grey font-12"
                          style={{ paddingLeft: "13px" }}
                        >
                          {commonComp.taxCredits.flatMap((taxCreditGroup) =>
                            taxCreditGroup.availableTaxCredits.map((credit, index) => (
                              <li key={index} className="font-700">
                                {credit.name}:{""}
                                {credit.projectedTaxCreditAmount ? credit.projectedTaxCreditAmount : ' No data available'}
                                {/* {calcAvailableTaxCredtis(
                                  commonComp.employeeCount,
                                  credit?.avgCreditPerEmployee,
                                  credit.name
                                )} */}
                              </li>
                            ))
                          )}
                        </ol>
                      </div>
                      {business.commonCompanies.length > cIndex + 1 && <hr />}
                    </div>
                  ))}
                </Popup>
                <Tooltip className={styles.hover_tool_tip}>
                  {business.commonCompanies.map((commonComp, cIndex) => (
                    <div key={cIndex}>
                      <div className={styles.hover_tool_tip}>
                        <h6 className="font-700">{commonComp.businessName}</h6>
                        <div className={styles.tooltip_available_credits}>
                          <div className="fw-bold col-6 text-grey font-12">
                            Projected Tax credit{" "}
                          </div>
                          <div className="col-6 font-700 text-grey font-12">
                           
                            {commonComp.taxCredits[0]
                              .totalProjectedTaxCreditAmount
                              ? "$" + formatCurrency(
                                  commonComp.taxCredits[0]
                                    .totalProjectedTaxCreditAmount
                                )
                              : "No data available"}{" "}
                          </div>
                        </div>

                        <div className={styles.tooltip_available_credits}>
                          <div className="fw-bold col-6 text-grey font-12">
                            Available Tax Credits:
                          </div>
                          <ol
                            className={
                              styles.available_credits +
                              ` col-6 text-grey font-12`
                            }
                            style={{ paddingLeft: "13px" }}
                          >
                            {commonComp.taxCredits.flatMap((taxCreditGroup) =>
                              taxCreditGroup.availableTaxCredits.map((credit, index) => (
                                <li key={index} className="font-700">
                                  {credit.name}:
                                  {credit.projectedTaxCreditAmount ? credit.projectedTaxCreditAmount : ' No data available'}
                                  {/* {calcAvailableTaxCredtis(
                                    commonComp.employeeCount,
                                    credit?.avgCreditPerEmployee,
                                    credit.name
                                  )} */}
                                </li>
                              ))
                            )}
                          </ol>
                        </div>
                        {business.commonCompanies.length > cIndex + 1 && <hr />}
                      </div>
                    </div>
                  ))}
                </Tooltip>
              </Marker>
            </div>
            
          // </>
        ))}

        {Object.keys(selectedCompanies).map((key, index) => {
          const latLng = key.split("-");
          const lat = parseFloat(latLng[0] + index);
          const lng = parseFloat(latLng[1]);
          if (isNaN(lat) || isNaN(lng)) return null; // Skip markers with invalid coordinates
          return (
            <Marker key={index} position={[lat, lng]}>
              <Popup>
                <h3>Companies at this location:</h3>
                {selectedCompanies[key].map((company, index) => (
                  <p key={index}>{company}</p>
                ))}
              </Popup>
            </Marker>
          );
        })}
        {/* {filteredBusinesses.length && (
          <InitialZoomToMarker
            position={[
              parseFloat(filteredBusinesses[0]?.lat),
              parseFloat(filteredBusinesses[0]?.lng),
            ]}
          />
        )} */}
        {/* <SetViewOnClick bounds={usBounds} /> */}
        {filteredBusinesses.length && (
          <FitBounds locations={filteredBusinesses} />
        )}
      </MapContainer>
    </div>
  );
};

export default MapView;
