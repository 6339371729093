import { useState } from "react";
import axiosInstance from "../../Fbaseurl";

import "./index.css";

import superAdminLogo from "../../../src/assets/superAdminLogin.svg";
import Logo from "../../../src/assets/Logo.svg";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputField from "../components/CommonComponents/InputField";
const OtpVerification = () => {
  const navigate = useNavigate();
  const [otpInputs, setOTPIinputs] = useState(['', '', '', '', '', '']);
 

    const formik = useFormik({
        initialValues: {
            otpCode: "",
        },


        onSubmit: async () => {

            navigate("/OtpVerification");
        }
    })

  const [isLoading, setLoader] = useState(false);
    const [title, setTtitle] = useState("OTP_VERIFICATION");

  return (
    <div className="login-wrapper">
      <div className="sm-row login-container d-lg-flex d-md-flex justify-content-between position-absolute fixed-bottom h-100">
        <div className="col-lg-7 col-md-7 col-sm-12 left-container text-center overflow-hidden align-content-end">
          <img
            className="login-img"
            src={superAdminLogo}
            alt="super-admin-login"
          />
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 right-container p-4">
          <div className="d-flex flex-column justify-content-around  p-3 h-100">
            <div>
              <img src={Logo} alt="logo" />
            </div>
            <div>
              {title === "OTP_VERIFICATION" && (
                <>
                  <h1 className="form-header fw-bold mb-3 lh-1">
                    OTP Verification ?
                  </h1>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="d-flex flex-column gap-3 form-group"
                                  >
                                  <div>Enter code</div>
                                      <div className="row d-flex">
                                          <span className="col-lg-2 d-flex gap-3">
                                              {otpInputs.map((value, index) => (
                                                  <input className="otpInput"
                                                      title="Email address"
                                              key={index}
                                              type="Number"
                                              value={value}
                                              maxLength={1}
                                              /*onChange={(e) => handleOTPInputChange(index, e.target.value)}*/
                                          />
                                      ))}
                                      </span>
                                  </div>
                    {formik.touched.email && formik.errors.email && (
                      <div className="error ">{formik.errors.email}</div>
                    )}
                    <div className="row">
                      <div className="d-flex justify-content-start"><p>code sent to your email name@gmail.com</p> 
                                         <p> <button
                                              className="border-0 bg-white fw-bold"
                                              onClick={() => navigate("/forgotPassword")}
                                          >
                                             change Email Id
                                          </button> </p>
                       
                      </div>
                      <div className="d-flex justify-content-end mt-2">
                        <button
                          className={`nextBtn border-0 px-5 py-1`}
                          type="submit"
                          role="status"
                        >Resend code
                        </button>
                       </div>
                                          <div className="d-flex justify-content-end mt-2">
                                              <button
                                                  className={`nextBtn border-0 px-5 py-1`}
                                                  type="submit"
                                                  role="status"
                                              >Submit
                                              </button>
                                          </div>
                    </div>
                  </form>
                </>
              )}
              {title === "WRONG_EMAIL" && (
                <>
                  <h1 className="form-header fw-bold mb-3 lh-1">Oops!</h1>
                  <div>{formik.values.email}</div>
                  <div>Email id not found in our server</div>
                  <div className="row">
                    <div className="d-flex justify-content-end">
                      <button
                        className={`nextBtn border-0 px-5 py-1 mt-4`}
                        onClick={() => navigate("/")}
                      >
                        Back To Login
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
