import { useFormik } from "formik";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import InputField from "../../CommonComponents/InputField";
import axiosInstance from "../../../../Fbaseurl";
import { useParams } from "react-router-dom";
const Title = ({ formik }) => {
  const [isLoading, setLoader] = useState(false);
  const { id, type } = useParams();

  return (
    <form>
      <div className="row gap-4">
        <div className="col-6">
          <InputField
            placeholder="Enter name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            title="Name of Tax Credit"
            isRequired={true}
            type="text"
            className="form-control"
            disabled={type === "view" ? true : false}
          />

          {formik.touched.name && formik.errors.name && (
            <div className="error px-1">{formik.errors.name}</div>
          )}
        </div>
        <div className="col-12">
          {/* <div className="input-container form-group">
            <label className="label mb-2 fw-bold" htmlFor="my-input">
              Description
            </label>
            <textarea
              className=" px-3 py-2 form-control"
              name="description"
              type="text"
              placeholder="Text here..."
              value={formik.values.description}
              onChange={formik.handleChange}
              rows={4}
              disabled = {type === 'view' ? true : false}
            />
          </div> */}
          <InputField
            placeholder="Enter name"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            title="Description"
            isRequired={false}
            type="text"
            className="form-control"
            multiline={true}
            disabled={type === "view" ? true : false}
            maxLength={700}
          />
        </div>
      </div>
    </form>
  );
};

export default Title;
