import { combineReducers } from "redux";
import companyReducer from "./companyReducer";
import SuperAdminReducer from "./SuperAdminReducer";
import AppReducer from "./AppReducer";
import SaCompanyReducer from "./SaCompanyReducer";
import TaxCreditsReducer from "./TaxCreditsReducer";
import SalespersonReducer from "./SalespersonReducer";
import ToastMessageReducer from "./ToastMessageReducer";

// import postReducer from "./post.reducer";

const rootReducer = combineReducers({
  company: companyReducer,
  superAdmin: SuperAdminReducer,
  App: AppReducer,
  SaCompany: SaCompanyReducer,
  TaxCredits: TaxCreditsReducer,
  salesPerson: SalespersonReducer,
  ToastMsg: ToastMessageReducer,
});

export default rootReducer;
