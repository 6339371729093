import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedTab: 0,
};

const SalesPersonSlice = createSlice({
    name: "salesPerson",
    initialState,
    reducers: {
        setSalesPersonSelectedTab: (state, actions) => {
            state.selectedTab = actions.payload;
        },
    }
});

export const { setSalesPersonSelectedTab } = SalesPersonSlice.actions;

export default SalesPersonSlice.reducer;