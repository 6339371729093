import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

// import { createBrowserHistory } from 'history';

/*const apiUrl = process.env.REACT_APP_API_BASE_URL;*/
const baseURLs = {
  'localhost': 'https://api-lrportal-dev-eus.azurewebsites.net/api/v1',
  'ui-lrportal-dev-eus.azurewebsites.net': 'https://api-lrportal-dev-eus.azurewebsites.net/api/v1',
  'ui-lrportal-test-eus.azurewebsites.net':'https://api-lrportal-test-eus.azurewebsites.net/api/v1',
  'ui-lrportal-uat-eus.azurewebsites.net':'https://api-lrportal-uat-eus.azurewebsites.net/api/v1',
  // 'ui-lrportal-prod-eus.azurewebsites.net':'https://api-lrportal-prod-eus.azurewebsites.net/api/v1',
  'www.taxraven.com': 'https://api-lrportal-prod-eus.azurewebsites.net/api/v1',
  'ui-lrportal-prod-eus-staging.azurewebsites.net': 'https://api-lrportal-prod-eus-staging.azurewebsites.net/api/v1'
  // Add more mappings as needed
};
const hostName = window.location.hostname;
const apiUrl =  baseURLs[hostName] || `https://api-lrportal-dev-eus.azurewebsites.net/api/v1`;
// const apiUrl = `
// https://api-lrportal-dev-eus.azurewebsites.net/api/v1`;

const axiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
});
// const history = createBrowserHistory();
// const navigate = useNavigate();
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken'); // Get the access token from local storage
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Add a response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem('refreshToken');
 
    // If the error status is 401 and refreshToken exists, try to refresh the token
    if (error.response.status === 401 && refreshToken && !originalRequest._retry) {
      originalRequest._retry = true;
 
      try {
        const response = await axiosInstance.post('/account/refresh', {
          refreshToken,
        });
 
        const newAccessToken = response.data.accessToken;
        localStorage.setItem('accessToken', newAccessToken);
 
        // Update the Authorization header and retry the original request
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // Handle refresh token failure (e.g., redirect to login)
        localStorage.clear();
        if(window.location.pathname !=='/') {
          window.location.pathname = '/';
        }
        return Promise.reject(refreshError);
      }
    }
 
    return Promise.reject(error);
  }
);
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response &&  error.response.status === 401) {
//       // Clear local storage or any other logout logic
      
//       localStorage.clear();

//       // Redirect to login page
//       // history.push('/');
//       if(window.location.pathname !=='/') {
//         window.location.pathname = '/';
//       }
//     }
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;


 

 

 
