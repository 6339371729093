export const formatDate = (utcDateString)=> {
    const date = new Date(utcDateString);

    // Extract the date parts
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
  
    return `${month}/${day}/${year}`;
//    return  Intl.DateTimeFormat('en-US', {day: '2-digit', month: '2-digit', year: 'numeric' }).format(date)
  }

export const todayDate = ()=>{
  const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const todayDateStartsWithDate = (date)=>{
  const today = date ? new Date(date) : new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
}