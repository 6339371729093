import { useState, useEffect } from "react";
import axiosInstance from "../../../Fbaseurl";
import { useNavigate, useLocation } from "react-router-dom";
import backNav from "../../../assets/backNav.svg";
import Logo from "../../../assets/Taxraven.svg";
import Shape from "../../../assets/Shape.svg";
import PDF from "../../../assets/PDF.svg";
import CSV from "../../../assets/CSV.svg";
import { useDispatch, useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import { showToast } from "../../../store/reducers/ToastMessageReducer";
import { updateSelectedCompnies } from "../../../store/actions/companyAction";
import Header from "../../components/CommonComponents/Header";
import { formatCurrency } from "../../../CommonUtils/currencyUtils";
import {todayDateStartsWithDate} from "../../../CommonUtils/dateUtils";
import TaxCreditAnalysisComp from "../../components/SalesPersonComp/TaxCreditAnalysisComp";
import {convertJsonToExcel, prepareRequiredJsonToExcelDownload} from "../../SalesPersonCommonMethods/taxCreditsReport";
import { useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';

import "./index.css";
import CircularProgress from "@mui/material/CircularProgress";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HrlogicsTitle from "../../../assets/HrlogicsTitle.svg"
import HrlogicsLogo from "../../../assets/HrlogicsLogoBlue.svg"
import AsureLogo from "../../../assets/AsureLogo.svg"
import HrlogicsSmallLogo from "../../../assets/HrlogicsSmallLogo.svg"
import LoadingButton from "@mui/lab/LoadingButton";
const TaxCreditAnalysis = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataFetchStatus, setDataFetchStatus] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    // isActive: true,
    logoUrl: null,
  })
  const name = useSelector((state)=> state.App.userName);
  const currentRole = useSelector((state)=> state.App.currentRole);
  const arrayoflist = [];
  let projectedCredits = [];
  const selectedCompnies = useSelector(
    (state) => state.company.selecteCompanies
  );

  const creditedIdentified = selectedCompnies.map((obj) => obj.censusTract);

  const bussinessId = selectedCompnies.map((obj) => obj.prospectBusinessId);
  let payload = [];
  selectedCompnies.forEach((item, index) => {
    var annualHires = null
    if(item.isAnnualHiresEditEnabled) {
      if(Number(item.annualHires) || item.annualHires == '0') {
        annualHires = Number(item.annualHires)
      }
    }
    let obj = {
      prospectBusinessId: item.prospectBusinessId,
      censusTract: item.censusTract || item.censusTractCode,
      annualHiresOverride: annualHires
      // annualHiresOverride: item.isAnnualHiresEditEnabled  ? Number(item.annualHires) ? Number(item.annualHires) : 0 : null
    };
    payload.push(obj);
  });

  const [listOfComapnies, setListOfCompanies] = useState();

  // Fetch data from API when component mounts
  const fetchLokkupData = () => {
    setDataFetchStatus("TRIGGERED");
    axiosInstance
      .post(`/taxCredits/lookup`, payload)
      .then((response) => {
        const updatedData = selectedCompnies.map((selectedCompany) => {
          const matchedItem = response.data.find(
            (item) =>
              item.prospectBusinessId === selectedCompany.prospectBusinessId
          );
          if (matchedItem) {
            return {
              ...selectedCompany,
              taxCredits: [matchedItem],
            };
          } else {
            return selectedCompany;
          }
        });
        setDataFetchStatus("FETCHED_SUCCESSFULLY");
        dispatch(updateSelectedCompnies(updatedData));
      })
      //   projectedCredits = arrayoflist.map(
      //     (item) => item.totalProjectedTaxCreditAmount
      //   );
      .catch((error) => {
        console.log(error);
        let errorMsg = error?.response?.data?.title;
        dispatch(
          showToast({
            type: "error",
            message: errorMsg || `Something went wrong. Please try again `,
          })
        );
        setDataFetchStatus("FAILED_TO_FETCH");
      });
  };

  const fetchCompanyInfo = async ()=>{
    const userInfo = JSON.parse(localStorage.getItem('user'));
      const companyId = userInfo.companyId;
        try {
          const response = await axiosInstance.get(`/companies/${companyId}`);
          if (response.status === 200) {
            setCompanyInfo({
              companyName: response.data.companyName,
              // isActive: !response.data.isActive,
              logoUrl:`${response.data.logoUrl}?${new Date()}`
            });
          }
        } catch (error) {
          console.log(error);
        }
      
  }

  useEffect(() => {
    fetchLokkupData();
    fetchCompanyInfo();
  }, []);

  // const handleDownloadPDF = () => {
  //   const element = document.getElementById("pdf-content");
  //   html2pdf(element, {
  //     preserveAspectRatio: true,
  //   });
  // };
  // const handleDownloadPDF = () => {
  //   const element = document.getElementById("pdf-content");
  //   const options = {
  //     margin: [0, 0, 0, 0],
  //     filename: "document.pdf",
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //     pagebreak: { mode: ["avoid-all", "css", "legacy"] },
  //   };

  //   html2pdf().set(options).from(element).save();
  // };
  const handleDownloadPDF = () => {
    const element = document.getElementById("pdf-content");

    // Temporarily adjust styles
    const disclaimerElement = document.querySelector('.disclaimer');
    const originalDisclaimerStyles = disclaimerElement.style.cssText;
    disclaimerElement.style.cssText = `
     page-break-inside: avoid;
       min-height: 96px;
    `;
    const aboutUsElement = document.querySelector('.aboutus');
    const originalStyles = aboutUsElement.style.cssText;
    aboutUsElement.style.cssText = `
        z-index: 1000 !important;
        position: relative !important;
        margin-top: -100px !important;
    `;

    const textWrapElement = document.querySelectorAll('.text-wrap');
    const originalTextWrapStyles = [];
    textWrapElement.forEach((card, index) => {
      originalTextWrapStyles[index] = card.style.cssText;
      card.style.cssText += `
      width: 100%        
      `;
    });

    const taxCards = document.querySelectorAll('.tax-card');
    const originalTaxCardsStyles = [];
  
    taxCards.forEach((card, index) => {
      originalTaxCardsStyles[index] = card.style.cssText;
      card.style.cssText += `
       
      page-break-before: always;
      page-break-inside: avoid;
      margin: 0px !important;
  
   
            
      `;
    });
    // const contentElements = document.querySelectorAll('.content');
    // const originalContentStyles = [];
    
    // contentElements.forEach((contentElement, index) => {
    //     originalContentStyles[index] = contentElement.style.cssText;
    //     contentElement.style.cssText = `
    //        min-height: 550px !important;
    //     `;
    // });

    let date = dayjs(new Date()).format('DD/MM/YYYY T HH:mm:ss') ;
    const options = {
        margin: [0,0,-10,0],
        filename: `Tax Credits Analysis ${date}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, logging: false, useCORS: true },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        // pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    html2pdf().set(options).from(element).save().finally(() => {
        // Restore original styles after PDF generation
        aboutUsElement.style.cssText = originalStyles;
        originalDisclaimerStyles.style.cssText = originalDisclaimerStyles;
        textWrapElement.forEach((contentElement, index) => {
          contentElement.style.cssText = originalTextWrapStyles[index];
      });
    });
};

const downloadTaxCreditsasCsv = async () => {
  const jsonData = await prepareRequiredJsonToExcelDownload(selectedCompnies);
  await convertJsonToExcel(jsonData, "Tax Credits Analysis");
}

const backNavigation = () => {
  let role = currentRole.toLowerCase();
  navigate(`/${role}/blogs`);
};

const downloadTaxCreditsasPdf = async () => {
  setIsLoading(true)
  try {
    var payload = [];
    selectedCompnies.map((item, index) => {
      payload.push({
        prospectBusinessId: item.prospectBusinessId,
        censusTract: item.censusTract,
        annualHiresOverride: item.annualHiresOverride,
      });
    });
    const response = await axiosInstance.post(`/taxCredits/lookup`, payload, {
      headers: {
        Accept: "application/pdf",
        "content-type": "application/json",
      },
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "Report.zip"; // Default file name if none provided
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    setIsLoading(false)
  } catch (error) {
    console.log(error);
    setIsLoading(false);
    dispatch(showToast({
      type: 'error',
      message: 'Failed to download. Please try again'
    }))
  }
};



  return (
    <div className="taxCreditWrapper">
      <Header />
      {dataFetchStatus === "FETCHED_SUCCESSFULLY" && (
        <div className={isMobile ? "p-3" : "p-4 mx-4"}>
          <div className="row p-0">
            <div className="d-flex justify-content-between px-3">
              <div className="d-flex">
                <div className="p-right">
                  <img
                    src={backNav}
                    height={isMobile ? "24px" : "36px"}
                    width={isMobile ? "24px" : "36px"}
                    onClick={() => backNavigation()}
                    alt="Back Nav"
                  ></img>
                </div>
                <div style={{ paddingBottom: "7px" }}>
                  <h2
                    className={`report-title my-auto ${
                      !isMobile ? "font-32" : " font-20"
                    }`}
                  >
                    Document Viewer
                  </h2>
                </div>
              </div>
              <div
                className="d-flex align-self-end gap-3"
                style={{ float: "right", padding: "4px" }}
              >
                {/* {!isMobile && <div className="font-primary-blue fw-bold my-auto">
                    Download as
                  </div> } */}
                  <LoadingButton
                  variant="contained"
                  color="primary"
                  size="medium"
                  sx={{ textTransform: "none", borderRadius: "8px" }}
                  onClick={downloadTaxCreditsasCsv}
                >
                 {!isMobile && <img
                    src={CSV}
                    width="24px"
                    height="24px"
                    alt="donwmload"
                  ></img> }
                  {isMobile ? 'Excel' : 'Download  Excel'}
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  size="medium"
                  sx={{ textTransform: "none", borderRadius: "8px" }}
                  onClick={downloadTaxCreditsasPdf}
                  loading={isLoading}
                >
                 {!isMobile && <img
                    src={PDF}
                    width="24px"
                    height="24px"
                    alt="donwmload"
                  ></img>}
                  {isMobile ? 'PDF' : 'Download PDF'}
                </LoadingButton>

                {/* <img
                  style={{ float: "right", padding: "4px" }}
                  src={CSV}
                  height="48px"
                  width="48px"
                  alt=""
                  className="cursor-pointer"
                  onClick={downloadTaxCreditsasCsv}
                ></img>
                <img
                  style={{ float: "right", padding: "4px" }}
                  src={PDF}
                  height="48px"
                  width="48px"
                  onClick={downloadTaxCreditsasPdf}
                  alt=""
                  className="cursor-pointer"
                ></img> */}
              </div>
            </div>
          </div>
          <TaxCreditAnalysisComp
            businesses={selectedCompnies}
            companyInfo={companyInfo}
          ></TaxCreditAnalysisComp>
        </div>
      )}
      {dataFetchStatus === "TRIGGERED" && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </div>
  );
};

export default TaxCreditAnalysis;
