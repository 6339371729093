import React from "react";
import AsureLogo from "../../../../assets/AsureLogo.svg";
import HrlogicsLogo from "../../../../assets/HrlogicsLogoBlue.svg";
import HrlogicsTitle from "../../../../assets/HrlogicsTitle.svg";
import BarGraph from "../BarGraph";
import InputField from "../../CommonComponents/InputField";
import {todayDateStartsWithDate} from "../../../../CommonUtils/dateUtils";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import CustomTable from "../../CommonComponents/Table";


import "./index.css";
import { useMediaQuery } from "@mui/material";

const ReportsPdf = ({ selectedDate, pastWeeksDates, informationRequests, activityReports }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTab = useMediaQuery("(max-width:769px)");
  return (
    <div
      className="pdf-container"
      id="pdf-content"
      style={{ maxWidth: "947px" }}
    >
      <div className="intro-card bg-white">
        <div className="d-flex justify-content-between">
          <div className="report-left">
            <img
              src={AsureLogo}
              alt="Hrlogics"
              className="hrLogicsLogo"
              height={isMobile ? "124px" : isTab ? "160px" : ""}
              width={isMobile ? "124px" : isTab ? "240px" : ""}
            ></img>
            <div className="weeklyreport-title">Weekly Report</div>
          </div>
          <div>
            <img
              src={HrlogicsLogo}
              alt="Logo"
              height={isMobile ? "200px" : "240px"}
              width={isMobile ? "100px" : "110px"}
              className="hrlogo"
            ></img>
          </div>
        </div>
      </div>
      <div className="p-4 mx-5" style={{ minHeight: "710px" }}>
        <div className="card p-4">
          <div className="d-lg-flex d-md-flex justify-content-between align-items-center px-3">
            <div className="reports"> Weekly Information</div>
            <InputField
              name="selectedDate"
              value={todayDateStartsWithDate(selectedDate) || selectedDate}
              className="form-control"
            />
          </div>
          <div style={{ maxWidth: "80%" }} className="">
            <BarGraph
              xAxis={pastWeeksDates}
              values={informationRequests}
            ></BarGraph>
          </div>
        </div>
      </div>

      <div
        className={`bottom-card p-left-40  ${
          isMobile ? "py-2 my-3" : "py-3 mt-5 "
        }`}
      >
        <div className="font-20 fw-bold">POWERED BY:</div>
        <img
          src={HrlogicsTitle}
          alt="Asure"
          width={isMobile ? "124px" : ""}
        ></img>
      </div>
      <div>
        {activityReports.map((reports, index) => {
          return (
            <div key={index}>
              <div className="intro-card bg-white mb-4">
                <div className="d-flex justify-content-between">
                  <div className="report-left">
                    <img
                      src={AsureLogo}
                      alt="Hrlogics"
                      className="hrLogicsLogo"
                      height={isMobile ? "124px" : isTab ? "160px" : ""}
                      width={isMobile ? "124px" : isTab ? "240px" : ""}
                    ></img>
                    <div className="weeklyreport-title">Activity Report</div>
                  </div>
                  <div>
                    <img
                      src={HrlogicsLogo}
                      alt="Logo"
                      height={isMobile ? "200px" : "240px"}
                      width={isMobile ? "100px" : "110px"}
                      className="hrlogo"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="card p-4 mx-4" style={{ minHeight: "710px" }}>
              <div className="reports pb-3"> Activity Reports</div>
              <CustomTable
                name="usersList"
                columns={{
                  userName: "Users",
                  logIns: "Log Ins",
                  lastLogin: "Last Log In",
                  requests: "Requests",
                }}
                data={reports}
              />
              </div>
              <div
                className={`bottom-card p-left-40  ${
                  isMobile ? "py-2 my-3" : "py-3 mt-4 "
                }`}
              >
                <div className="font-20 fw-bold">POWERED BY:</div>
                <img
                  src={HrlogicsTitle}
                  alt="Asure"
                  width={isMobile ? "124px" : ""}
                ></img>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReportsPdf;
