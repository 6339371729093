import ReactApexChart from "react-apexcharts";

const BarGraph = ({xAxis, values}) => {
  const chartData = {
    options: {
      chart: {
        id: "apexchart-example",
        toolbar: {
            show: false, // Hide the toolbar
          },
         
      },
      plotOptions: {
        bar: {
        //   borderRadius: 7, // General border radius for the bars
        // borderRadiusApplication: 'around', // Apply radius around the bars
        // borderRadiusWhenStacked: 'all', // Ensure radius when bars are stacked
          dataLabels: {
            position: 'top', // top, center, bottom
          },
          columnWidth: '30%'
        },
       
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20, // Adjust this value to move the label above the bar
        style: {
          fontSize: '14px',
          fontWeight: 700,
          fontFamily: 'IBM Plex Serif',
          colors: ["#FF9900"]
        }
      },
      xaxis: {
        // categories: [1991, 1992, 1993, 1994, 1995],
        categories: xAxis,
        labels: {
          // offsetY: -18,
          style: {
            fontSize: '12px', // Set the font size for x-axis values
            fontFamily: 'IBM Plex Serif', // Set the font family for x-axis values
            colors: ['#615E83'], // Set the color for x-axis values
          },
        },
        title: {
          text: "Weeks", // X-axis label
          style: {
            fontSize: '14px',
            fontFamily: 'IBM Plex Serif',
            fontWeight: 600,
            color: '#615E83',
          },
        },
  
      },

      yaxis: {
        labels: {
          // offsetY: -18,
          style: {
            fontSize: '12px', // Set the font size for x-axis values
            fontFamily: 'IBM Plex Serif', // Set the font family for x-axis values
            colors: ['#615E83'], // Set the color for x-axis values
          },
        },
        title: {
          text: "Request count", // Y-axis label
          offsetX: -10,
          offsetY: 0,
          style: {
            fontSize: '14px',
            fontFamily: 'IBM Plex Serif',
            fontWeight: 600,
            color: '#615E83',
            padding: '30px',
            margin: '15px'
          },
        },
      },
      tooltip: {
        enabled: false, // Disable the tooltip
      },
      colors: ['#162395'], 
    },
    series: [
      {
        name: "series-1",
        // data: [30, 40, 35, 50, 49],
        data: values
      },
    ],
  };

  return (
    <>
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="bar"
      height={320}
    />
    </>
  );
};

export default BarGraph;
