import { useParams } from "react-router-dom";

import InputField from "../../CommonComponents/InputField";
import CustomMultiSelect from "../../CommonComponents/MultiSelect";
import SelectComp from "../../CommonComponents/SelectComp";
import Checkbox from "../../CommonComponents/Checkbox";

const LocationQualification = ({
  formik,
  statesList,
  eligibilityCriteria,
  onChecked,
  isPropertyPresent,
}) => {
  const { id, type } = useParams();
  return (
    <form>
      <div className="row pb-4">
        <div className="col-6">
          {/* <InputField
            placeholder="Enter state"
            name="states"
            value={formik.values.states}
            onChange={formik.handleChange}
            title="Eligible States"
            type="text"
            className="form-control"
            disabled = {type === 'view' ? true : false}
          /> */}
          {/* <CustomMultiSelect
            placeholder="Select State"
            onChange={(value) => formik.setFieldValue("states", value)}
            selectedValues={formik.values.states}
            options={statesList}
            label="Eligible States"
            disabled = {type === 'view' ? true : false}
          /> */}
          <SelectComp
            title="Eligible States"
            value={formik.values.states}
            options={statesList}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              formik.setFieldValue(
                "states",
                typeof value === "string" ? value.split(",") : value
              );
            }}
            name="states"
            isRequired={false}
            isMultiSelect={true}
            disabled={type === "view" ? true : false}
          ></SelectComp>
        </div>
        <div className="col-6">
          <Checkbox
            checked={isPropertyPresent("States")}
            name="States"
            className="mb-2"
            label="Eligibility criteria"
            onChange={() => onChecked("States")}
            disabled={type === "view" ? true : false}
          />
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-6">
          <InputField
            placeholder="Enter Cities"
            name="cities"
            value={formik.values.cities}
            onChange={formik.handleChange}
            title="Cities"
            type="text"
            className="form-control"
            disabled={type === "view" ? true : false}
          />
        </div>
        <div className="col-6">
          <Checkbox
            checked={isPropertyPresent("Cities")}
            name="Cities"
            className="mb-2"
            label="Eligibility criteria"
            onChange={() => onChecked("Cities")}
            disabled={type === "view" ? true : false}
          />
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-6">
          <InputField
            placeholder="Enter codes"
            name="fipsCodes"
            value={formik.values.fipsCodes}
            onChange={formik.handleChange}
            title="FIPS Codes"
            type="text"
            className="form-control"
            disabled={type === "view" ? true : false}
          />
        </div>
        <div className="col-6">
          <Checkbox
            checked={isPropertyPresent("FIPSCodes")}
            name="FIPSCodes"
            className="mb-2"
            label="Eligibility criteria"
            onChange={() => onChecked("FIPSCodes")}
            disabled={type === "view" ? true : false}
          />
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-6">
          <InputField
            placeholder="Enter Tracts"
            name="censusTracts"
            value={formik.values.censusTracts}
            onChange={formik.handleChange}
            title="Census Tracts"
            type="text"
            className="form-control"
            disabled={type === "view" ? true : false}
          />
        </div>
        <div className="col-6">
          <Checkbox
            checked={isPropertyPresent("CensusTracts")}
            name="CensusTracts"
            className="mb-2"
            label="Eligibility criteria"
            onChange={() => onChecked("CensusTracts")}
            disabled={type === "view" ? true : false}
          />
        </div>
      </div>
    </form>
  );
};

export default LocationQualification;
