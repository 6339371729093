import * as React from "react";
import "./index.css";

const CheckBox = ({ checked, onChange, label, disabled, className, name }) => {
  return (
    <div className={`checkbox-container ${className}`}>
      <input name={name} id={name} type="checkbox" disabled={disabled} checked={checked} onChange={onChange} />
      {label && <label htmlFor={name}>{label}</label>}
    </div>
  );
};

export default CheckBox;
