import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./index.css";
import {useState} from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function SelectComp(props) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    setIsFocused(false);
    if (props.onBlur) {
      props.onBlur(event);
    }
  };
  return (
    <FormControl
      size="small"
      sx={{
        width: "100%",
        "& .Mui-focused .MuiInputLabel-root": {
          color: "#162395",
        },
      }}
      variant="outlined"
    >
      <InputLabel id="demo-select-small-label">
        <span>{props.title}</span>{" "}
        {props.isRequired && <span className="mandtoryAsterik"> * </span>}
      </InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={props.value}
        label={props.title}
        size="small"
        color="secondary"
        variant="outlined"
        MenuProps={MenuProps}
        name={props.name}
        onChange={props.onChange}
        multiple={props.isMultiSelect}
        required={props.isRequired}
        disabled={props.disabled}
        onBlur={handleBlur}
        onClose={props.onClose}
      >
        {/* InputLabelProps={{
          shrink: isFocused || !!props.value,
        }} */}
        {/* <MenuItem style={{ fontWeight: "bold", color: "red" }}>
          Clear Selection
        </MenuItem> */}
        {props?.options?.map((item, index) => {
          return (
            <MenuItem
              key={item.id}
              value={props.optionValue ? item[props.optionValue] : item.id}
            >
              {item.key}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
