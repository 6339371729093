import React from "react";
import { Outlet } from "react-router-dom";
import ToastMessage from "./components/CommonComponents/ToastMessage";
import { useSelector, useDispatch } from "react-redux";
import { showToast, hideToast } from "../store/reducers/ToastMessageReducer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "../theme";


const LoginLayout = () => {
  const dispatch = useDispatch();
  const { open, message, type } = useSelector((state) => state.ToastMsg);
  const handleToastClose = (event, reason) => {
    dispatch(hideToast());
  };
  return (
    <>
      <ToastMessage
        open={open}
        handleClose={handleToastClose}
        message={message}
        type={type}
      />
      <ThemeProvider theme={theme}>
        <Outlet />
      </ThemeProvider>
    </>
  );
};

export default LoginLayout;
