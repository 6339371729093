import * as React from "react";
import "./index.css";
// import {Tabs} from "@mui/material/Tabs";
// import {Tab} from "@mui/material/Tab";
import { useDispatch } from "react-redux";
import { Tab, Tabs } from "@mui/material";

const TabsComponent = ({ tabs, withImage, setSelectedTab, selectedTab, navFunc }) => {
  const dispatch = useDispatch();
  
  const handleChange = (event, newValue) => {
     if(navFunc) {
       navFunc(newValue);
     }
    dispatch(setSelectedTab(newValue));
  };
  return (
    <div>
    <div className="tabs-container">
      { (
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor=""
          aria-label="dashboard tabs"
        >
          {tabs.map((tab, index) => (
            <Tab
              icon={withImage ? tab.img : ''}
              iconPosition="start"
              label={tab.name}
              key={index}
            />
          ))}
          <div className="tabs-bottom-border">&nbsp;</div>
        </Tabs>
      ) }
    </div>
    </div>
  );
};

export default TabsComponent;
