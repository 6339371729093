import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./index.module.css";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Button from "../../../components/CommonComponents/Button";

export default function ConfirmationPopup({
  isPopupOpen,
  content,
  icon,
  onCancel,
  onConfirm,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={isPopupOpen}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ "& .MuiPaper-root": { borderRadius: "24px !important" } }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ paddingBottom: "0px !important" }}
        >
          <CancelOutlinedIcon
            className="back-btn float-end"
            onClick={() => {
              onCancel();
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div className="text-center">
            <img className="icon" src={icon} alt="deactivate"></img>
          </div>
          <DialogContentText id="alert-dialog-description">
            <div className={styles.confirmationMsg}>{content}</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mb-3 gap-4" sx={{ alignSelf: "center" }}>
        <Button
            variant="outlined"
            color="primary"
            type="submit"
            label="Yes"
            onClick={onConfirm}
            className={`rounded-pill col-6 fw-bold py-1 `}
          />
          <Button
            label="No"
            onClick={onCancel}
            className={`rounded-pill col-6 fw-bold py-1 `}
            type="button"
            color="primary"
            variant="contained"
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
