import React from "react";
import TabsComponent from "../components/CommonComponents/Tabs";
import Header from "../components/CommonComponents/Header";
// import "./index.css";
import FindBusiness from "../../assets/FindBusiness.svg";
import GenerateMap from "../../assets/GenerateMap.svg";
import handIcon from "../../assets/hand.png";
import usersIcon from "../../assets/users.svg";
import { setSalesPersonSelectedTab } from "../../store/reducers/SalespersonReducer";
import { useSelector } from "react-redux";
import ReviewDetails from "./ReviewDetails";
import CreditMapGenerator from "./CreditMapGenerator";
import { useMediaQuery } from '@mui/material';

const FindBusiNavTabs = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { selectedTab } = useSelector((state) => state.salesPerson);
  const name = useSelector((state) => state.App.userName);

  const tabs = [
    {
      name: "Find a Business",
      img: <img src={FindBusiness} alt="findBusiness" />,
      key: 0
    },
    {
      name: "Generate a Credit Map",
      img: <img src={GenerateMap} alt="generateMap" />,
      key: 1
    },
  ];

  const getGreeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return "Good Morning!";
    } else if (currentHour < 18) {
      return "Good Afternoon!";
    } else {
      return "Good Evening!";
    }
  };
  const findComponent = () => {
    if (selectedTab === 0) {
      return <ReviewDetails></ReviewDetails>;
    } else if (selectedTab === 1) {
      return <CreditMapGenerator></CreditMapGenerator>;
    }
  };
  return (
    <div className="dashboardContainer d-flex flex-column h-100 flex-grow-1">
      <Header
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSalesPersonSelectedTab}
      />
      <div className={`content-wrapper ${isMobile ? 'py-3' : 'py-4'}`}>
        <div className="col-11 m-auto">
          {/* <div className="mb-3 d-flex align-items-center gap-1">
            <span className="fs-1 fw-bold">Hello {name}</span>
            <img src={handIcon} alt="handIcon" />
            <span className="fs-1">{getGreeting()}</span>
          </div> */}

        {!isMobile && <TabsComponent
            tabs={tabs}
            withImage={false}
            selectedTab={selectedTab}
            setSelectedTab={setSalesPersonSelectedTab}
          /> }
          {findComponent()}
        </div>
      </div>
    </div>
  );
};

export default FindBusiNavTabs;
