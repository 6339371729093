import { createTheme } from "@mui/material/styles";
const theme = createTheme({
    palette: {
      primary: {
        main: "#162395"
      },
      secondary: {
        main: "#0D1559", // Custom color
      },
    },
    typography: {
      fontFamily: '"IBM Plex Serif", serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontFamily: '"IBM Plex Serif", serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: '"IBM Plex Serif", serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            fontFamily: '"IBM Plex Serif", serif',
          },
        },
      },
      // Add more MUI components as needed
    },
  });

  export default theme