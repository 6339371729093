// Filename - App.js
// It contains the Form, its Structure
// and Basic Form Functionalities

import { React, useState, useEffect } from "react";

import LoadingButton from '@mui/lab/LoadingButton';
import {
  updateCompanies,
  updateSelectedCompnies,
} from "../../../store/actions/companyAction";
import axiosInstance from "../../../Fbaseurl";
import { showToast } from "../../../store/reducers/ToastMessageReducer";
import InputField from "../../components/CommonComponents/InputField";
import SelectComp from "../../components/CommonComponents/SelectComp";
import CheckCircle from "../../../assets/CheckCircle.svg"
import CloseCircle from "../../../assets/CloseCircle.svg"
import Button from "../../components/CommonComponents/Button";
import Checkbox from "../../components/CommonComponents/Checkbox";



import "./index.css";
import "react-country-state-city/dist/react-country-state-city.css";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from '@mui/material';
// import { updateCompanies, updateSelectedCompnies } from "./store/actions/companyAction";

function ReviewDetails() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const dispatch = useDispatch();
  const currentRole = useSelector((state)=> state.App.currentRole)
  const [state, setState] = useState({
    businessName: "",
    industry: "",
    streetAddress: "",
    city: "",
    state: "",
    zip: "",
  });
  const [errors, setErrors] = useState({
    // businessName: false,

    // streetAddress: false,
    city: false,
    state: false,
    // zip: false,
  });

  const [recordCount, setRecordsCount] = useState();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const [isLoading, setLoader] = useState(false);
  const [isLoadingNext, setLoaderNext] = useState(false);
  const [errormessage, seterrormessage] = useState(null);
  const [errorFindMessage, seterrorFindMessage] = useState(null);
  const [isEaxctNameSearchSelected, setIsExactNameSearchSelected] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    // Fetch data from API
    axiosInstance
      .get("/taxCredits/states")
      .then((response) => {
        const updatedStates = response.data.reduce((acc, cur, ind) => {
          if (cur) {
            acc.push({
              id: cur.code,
              key: cur.name
            });
          }
          return acc;
        }, []);

        setOptions(updatedStates);
      })
      .catch((error) => {
        console.error("Error fetching dropdown options:", error);
      });
  }, []);

  const validateZipCode = (zip) => {
    const zipCodeRegex = /^(?:\d{5}|\d{5}-\d{4})$/;
    return zipCodeRegex.test(zip);
  };
  const zipValidation = (e,id) => {
    const { value } = e.target;
    //   if(value.length) {
    setErrors((prevState) => ({
      ...prevState,
      [id]: value.length ? !validateZipCode(value) : false,
    }));
    //   }
  };
  const handleChange = (e, id, type = "") => {
    let { value } = e.target;
    if (type === "ZIP") {
      if (!/^\d*-?\d*$/.test(value)) {
        e.preventDefault();
        return;
      }
      if (value.length === 9 && !value.includes("-")) {
        value = `${value.slice(0, 5)}-${value.slice(5, 9)}`;
      }
    }

    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setErrors({ ...errors, [id]: false });
    if (e.target.value.length === 0) {
      seterrorFindMessage("");
      setRecordsCount("");
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (isLoadingNext) {
      return;
    }
    setLoaderNext(true);
    axiosInstance
      .get(`/businessSearch`, {
        params: {
          BusinessName: state.businessName,
          Keyword: state.industry,
          StreetAddress: state.streetAddress,
          City: state.city,
          State: state.state,
          Zip: state.zip,
          BusinessNameSearchType: isEaxctNameSearchSelected ? "ExactName" : "StartsWith",
        },
      })
      .then((response) => {
        response.data.forEach((element) => {
          element["isSelected"] = false;
          element["isAnnualHiresEditEnabled"] = false
        });
        dispatch(updateCompanies(response.data));
        if(currentRole === "Salesperson") {
          navigate("/salesperson/blogs", { state: response.data });
        } else if(currentRole === "Manager") {
          navigate("/manager/blogs", { state: response.data });
        } else if(currentRole === "CompanyAdmin") {
          navigate("/companyadmin/blogs", { state: response.data });
        }
        setLoaderNext(false);
      })
      .catch((error) => {
        console.log(error);
        let errorMsg = error?.response?.data?.title;
        dispatch(
          showToast({
            type: "error",
            message: errorMsg || `Something went wrong. Please try again `,
          })
        );
        setLoaderNext(false);
      });
  };
  const handleSelectChange = (event) => {
    let selecteOption = event.target.value;
    //let selectedOption = options.find((item, index) => {
    //    return item.name === name
    //})

    setSelectedOption(selecteOption);
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // setState({ ...state, [id]: value });
    setErrors({ ...errors, [name]: false });
    if (event.target.value.length === 0) {
      seterrorFindMessage("");
      setRecordsCount("");
    }
  };
  //const handleSelectChange = (event) => {

  //    let namevalue = event.target.value;
  //    //let selectedOption = options.find((item, index) => {
  //    //    return item.name === name
  //    //})
  //    setSelectedOption(namevalue)
  //    const { id, value } = event.target;
  //    setState({ ...state, [id]: value });
  //    setErrors({ ...errors, [id]: false });
  //    if (event.target.value.length === 0
  //    ) {
  //        seterrorFindMessage('');
  //        setRecordsCount('');
  //    }
  //};
  const handleFind = (e) => {
    e.preventDefault();
    const mandatoryFields = ["businessName", "city", "state"];
    let hasErrors = false;
    const updatedErrors = { ...errors };

    mandatoryFields.forEach((field) => {
      if (!state[field].trim()) {
        updatedErrors[field] = true;
        hasErrors = true;
      }
    });
    if (state.zip && !/^(?:\d{5}|\d{5}-\d{4})$/.test(state.zip)) {
      updatedErrors.zip = true;
      hasErrors = true;
    }
    setErrors(updatedErrors);

    if (!hasErrors) {
      if (isLoading) {
        return;
      }
      setLoader(true);
      axiosInstance
        .get(`/businessSearch/count`, {
          params: {
            BusinessName: state.businessName,
            Keyword: state.industry,
            StreetAddress: state.streetAddress,
            City: state.city,
            State: state.state,
            Zip: state.zip,
            BusinessNameSearchType: isEaxctNameSearchSelected ? "ExactName" : "StartsWith",
          },
        })
        .then((response) => {
          setRecordsCount(response.data.recordCount);
          //let count = Number(response?.data?.recordCount)
          if (response?.data?.recordCount === 0) {
            seterrorFindMessage("Business not found");
          } else if (response.data.recordCount >= 20) {
            seterrorFindMessage(
              "Too many records retrieved.  Please narrow your search"
            );
          } else {
            seterrorFindMessage("");
          }
          setLoader(false);
        })
        .catch((error) => {
          if (error?.response?.data?.status === 429) {
            seterrorFindMessage(
              "You have met your monthly search limit. Please contact your administrator."
            );
          } else if (error?.response?.data?.status === 400) {
            seterrorFindMessage(
              "The detail you entered is wrong Please check."
            );
          } else {
            let errorMsg = error?.response?.data?.title;
            dispatch(
              showToast({
                type: "error",
                message: errorMsg || `Something went wrong. Please try again `,
              })
            );
          }

          setLoader(false);
        });
    }
  };
  // const isButtonDisabled = Object.values(state).some(value => !value);
  return (
    // <>
    //   <div className="reviewDetailsOfInfo p-4">
    //     <p className="d-flex titleReviewDetails">
    //       Enter full or partial information for this bussiness
    //     </p>
    //     <fieldset>
    //       <form action="#" method="get">
    //         <div
    //           style={{
    //             display: "flex",
    //             justifyContent: "space-between",
    //             gap: "16px",
    //           }}
    //         >
    //           <span className="col-lg-6 col-md-6 col-sm-12 mb-3 ">
    //             <label className="label mb-2 fw-bold float-left">
    //               Business Name <span className="mandatorAsterik"> * </span>
    //             </label>

    //             <input
    //               required
    //               style={{
    //                 borderRadius: "24px",
    //               }}
    //               type="text"
    //               name="businessName"
    //               id="businessName"
    //               placeholder="Enter business name"
    //               value={state.businessName}
    //               onChange={handleChange}
    //             />
    //             <div className="hint-text">
    //               hint: You can enter partial names for broader search results
    //             </div>
    //             {errors.businessName && (
    //               <div className="error">Bussiness name is required </div>
    //             )}
    //           </span>

    //           <span className="col-lg-6 col-md-6 col-sm-12 mb-3">
    //             <label
    //               className="label mb-2 fw-bold float-left"
    //               htmlFor="lastname"
    //             >
    //               Industry keyword
    //             </label>
    //             <input
    //               style={{
    //                 borderRadius: "24px",
    //               }}
    //               type="text"
    //               placeholder="Enter keyword"
    //               name="industry"
    //               id="industry"
    //               value={state.industry}
    //               onChange={handleChange}
    //             />
    //           </span>
    //         </div>
    //         <div
    //           style={{
    //             display: "flex",
    //             justifyContent: "space-between",
    //             gap: "16px",
    //           }}
    //         >
    //           <span className="col-lg-6 col-md-6 col-sm-12 mb-3">
    //             <label
    //               className="label mb-2 fw-bold float-left"
    //               htmlFor="email"
    //             >
    //               Street Address{" "}
    //               {/*<span className="mandatorAsterik"> * </span>*/}{" "}
    //             </label>
    //             <input
    //               style={{
    //                 //width: "505px",
    //                 //height: "38px",
    //                 borderRadius: "24px",
    //               }}
    //               type="text"
    //               name="streetAddress"
    //               placeholder="Enter address"
    //               id="streetAddress"
    //               value={state.streetAddress}
    //               onChange={handleChange}
    //             />
    //             {errors.streetAddress && (
    //               <div className="error px-1">Address is required</div>
    //             )}
    //           </span>
    //           <span className="col-lg-6 col-md-6 col-sm-12 mb-3">
    //             <label className="label mb-2 fw-bold float-left" htmlFor="tel">
    //               City <span className="mandatorAsterik"> * </span>
    //             </label>
    //             <input
    //               style={{
    //                 borderRadius: "24px",
    //               }}
    //               type="text"
    //               name="city"
    //               placeholder="Enter city"
    //               id="city"
    //               value={state.city}
    //               onChange={handleChange}
    //             />
    //             {errors.city && (
    //               <div className="error px-1">City is required</div>
    //             )}
    //           </span>
    //         </div>
    //         <div
    //           style={{
    //             display: "flex",
    //             justifyContent: "space-between",
    //             gap: "16px",
    //           }}
    //         >
    //           <span className="col-lg-6 col-md-6 col-sm-12 mb-3">
    //             <label className="label mb-2 fw-bold float-left" htmlFor="tel">
    //               State <span className="mandatorAsterik"> * </span>
    //             </label>

    //             <select
    //               style={{
    //                 borderRadius: "24px",
    //               }}
    //               name="state"
    //               onChange={(e) => handleSelectChange(e)}
    //             >
    //               <option value="">Select state</option>

    //               {options.map((option) => (
    //                 <option key={option.code} value={option.code}>
    //                   {option.name}
    //                 </option>
    //               ))}
    //             </select>
    //             {errors.state && (
    //               <div className="error px-1">State is required</div>
    //             )}
    //           </span>

    //           <span className="col-lg-6 col-md-6 col-sm-12 mb-3">
    //             <label className="label mb-2 fw-bold float-left" htmlFor="tel">
    //               Zip code{/* <span className="mandatorAsterik"> * </span>*/}
    //             </label>
    //             <input
    //               style={{
    //                 borderRadius: "24px",
    //               }}
    //               type="text"
    //               name="zip"
    //               id="zip"
    //               placeholder="Enter zip code"
    //               value={state.zip}
    //               onChange={(e) => handleChange(e, "ZIP")}
    //               onBlur={zipValidation}
    //             />
    //             {errors.zip && (
    //               <div className="error px-1">
    //                 Zip code should be 5 digits or 9 digits
    //               </div>
    //             )}
    //           </span>
    //         </div>
    //         <hr></hr>

    //         <div>
    //           <span>
    //             <button
    //               className="findButton"
    //               type="submit"
    //               value="submit"
    //               onClick={(e) => handleFind(e)}
    //             >
    //               {isLoading ? (
    //                 <div
    //                   className="spinner-border spinner-border-sm mx-2 p-1"
    //                   role="status"
    //                 ></div>
    //               ) : (
    //                 "Find"
    //               )}
    //             </button>
    //           </span>

    //           {errorFindMessage && (
    //             <div className="errorImage">
    //               <img src="/DismissCircle.png" alt=""></img>
    //               {errorFindMessage}
    //             </div>
    //           )}
    //           <span>
    //             {recordCount > 0 && recordCount < 10 && (
    //               <div className="successImage">
    //                 <img src="/CheckmarkCircle.png" alt=""></img>{" "}
    //                 {recordCount < 11 ? `${recordCount} businesses found` : ""}
    //               </div>
    //             )}
    //           </span>
    //         </div>
    //         <div>
    //           <button
    //             className="submitButton"
    //             type="submit"
    //             value="Submit"
    //             onClick={(e) => handleNext(e)}
    //             disabled={
    //               recordCount === undefined ||
    //               recordCount >= 10 ||
    //               recordCount === 0
    //             }
    //           >
    //             {isLoadingNext ? (
    //               <div
    //                 className="spinner-border spinner-border-sm mx-2 p-1"
    //                 role="status"
    //               ></div>
    //             ) : (
    //               "Next"
    //             )}
    //           </button>
    //         </div>
    //         {errormessage && <div style={{ color: "red" }}>{errormessage}</div>}
    //       </form>
    //     </fieldset>
    //   </div>
    // </>

    <div
      className={`reviewDetailsOfInfo ${
        isMobile ? " mt-0 p-3" : " mt-4 p-4 reviewHeight"
      }`}
    >
      <p className="font-16 font-500">
        Enter full or partial information for this business
      </p>
      <div className={`d-lg-flex d-md-flex ${isMobile ? "mb-3" : "mb-4"}`}>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className={`${!isMobile ? "p-right" : ""}`}>
            <InputField
              name="businessName"
              value={state.businessName}
              onChange={(e) => handleChange(e, "businessName")}
              title="Business Name"
              type="text"
              isRequired={true}
            />
            {/* <div className="hint-text">
              hint: You can enter partial names for broader search results
            </div> */}
            {errors.businessName && (
              <div className="error">Business name is required </div>
            )}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className={`${!isMobile ? "px-2" : errors.businessName ? "pt-2" : ''}`}>
            <Checkbox
              label="Exact Name Search"
              checked={isEaxctNameSearchSelected}
              onChange={(e) => setIsExactNameSearchSelected(e.target.checked)}
              name="isExactNameSearchSelected"
              className="mt-3 mt-md-0 mt-lg-0"
            />
            {/* <InputField
              name="industry"
              value={state.industry}
              onChange={(e) => handleChange(e, "industry")}
              title="Industry Keyword"
              type="text"
              isRequired={false}
            /> */}
          </div>
        </div>
      </div>

      <div className={`d-lg-flex d-md-flex ${isMobile ? "mb-3" : "mb-4"}`}>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className={`${!isMobile ? "p-right" : ""}`}>
            <InputField
              name="industry"
              value={state.industry}
              onChange={(e) => handleChange(e, "industry")}
              title="Industry Keyword"
              type="text"
              isRequired={false}
            />
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 ">
          <div className={`${!isMobile ? "px-2" : "pt-3"}`}>
            <InputField
              name="streetAddress"
              value={state.streetAddress}
              onChange={(e) => handleChange(e, "streetAddress")}
              title="Street Address"
              type="text"
              isRequired={false}
            />
            {errors.streetAddress && (
              <div className="error px-1">Street Address is required</div>
            )}
          </div>
        </div>
      </div>

      <div className={`d-lg-flex d-md-flex ${isMobile ? "mb-2" : "mb-4"}`}>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className={`${!isMobile ? "p-right" : ""}`}>
            <InputField
              name="city"
              value={state.city}
              onChange={(e) => handleChange(e, "city")}
              title="City"
              type="text"
              isRequired={true}
            />
            {errors.city && <div className={`error px-1 ${isMobile ? 'pb-2' : ''}`}>City is required</div>}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 ">
          <div className={`${!isMobile ? "px-2" : "pt-3"}`}>
            <SelectComp
              title="State"
              value={state.state}
              options={options}
              onChange={(e) => handleChange(e, "state")}
              name="state"
              isRequired={true}
              isMultiSelect={false}
            ></SelectComp>

            {errors.state && (
              <div className="error px-1">State is required</div>
            )}
          </div>
        </div>
      </div>
      <div className={`d-lg-flex d-md-flex ${isMobile ? "mb-2" : "mb-4"}`}>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className={`${!isMobile ? "p-right" : ""}`}>
            <InputField
              name="zip"
              value={state.zip}
              onChange={(e) => handleChange(e, "zip", "ZIP")}
              title="Zip Code"
              type="text"
              isRequired={false}
              onBlur={(e) => zipValidation(e, "zip")}
            />
            {errors.zip && (
              <div className="error px-1">
                Zip code should be 5 digits or 9 digits
              </div>
            )}
          </div>
        </div>
      </div>

      {isMobile && (
        <div className="mb-2">
          <hr></hr>
        </div>
      )}
      <div className={`d-flex ${isMobile ? " justify-content-between" : ""}`}>
        <Button
          label="Find"
          className="fw-bold"
          type="button"
          color="primary"
          variant="outlined"
          onClick={(e) => {
            handleFind(e);
          }}
          isLoading={isLoading}
        />
        {isMobile && (
          <Button
            label="Next"
            className="fw-bold"
            type="button"
            color="primary"
            variant="contained"
            onClick={(e) => handleNext(e)}
            disabled={!recordCount || recordCount >= 20 || recordCount === 0}
            loading={isLoadingNext}
          />
        )}
        {!isMobile && errorFindMessage && (
          <span>
            {errorFindMessage && (
              <div className="errorImage mx-4 py-1 px-3">
                <img
                  src={CloseCircle}
                  alt=""
                  height="20px"
                  width="20px"
                  className="my-auto mx-1"
                ></img>
                {errorFindMessage}
              </div>
            )}
          </span>
        )}
        {!isMobile && recordCount > 0 && recordCount < 20 && (
          <span>
            {recordCount > 0 && recordCount < 21 && (
              <div className="successImage mx-4 py-1 px-3">
                <img
                  src={CheckCircle}
                  alt=""
                  height="20px"
                  width="20px"
                  className="my-auto mx-1"
                ></img>{" "}
                {Number(recordCount) === 1
                  ? "1 business found"
                  : `${recordCount} businesses found`}
                {/* {recordCount < 11 ? `${Number(recordCount) === 1 }` ? '1 business found' : `${recordCount} businesses found` : ""} */}
              </div>
            )}
          </span>
        )}
      </div>
      <div className="w-100 ">
        {isMobile && errorFindMessage && (
          <div className="errorImage py-1 px-3 w-100 mt-3">
            <img
              src={CloseCircle}
              alt=""
              height="20px"
              width="20px"
              className="my-auto mx-1"
            ></img>
            {errorFindMessage}
          </div>
        )}
      </div>

      {isMobile && recordCount > 0 && recordCount < 20 && (
        <div>
          {recordCount > 0 && recordCount < 21 && (
            <div className="w-100 successImage mt-3 py-1 px-3">
              <img
                src={CheckCircle}
                alt=""
                height="20px"
                width="20px"
                className="my-auto mx-1"
              ></img>{" "}
              {Number(recordCount) === 1
                ? "1 business found"
                : `${recordCount} businesses found`}
            </div>
          )}
        </div>
      )}
      {!isMobile && (
        <div className="card-footer d-flex justify-content-between align-items-center align-self-end gap-3 mt-auto">
          <Button
            label="Next"
            className="fw-bold"
            type="button"
            color="primary"
            variant="contained"
            onClick={(e) => handleNext(e)}
            disabled={!recordCount || recordCount >= 20 || recordCount === 0}
            loading={isLoadingNext}
          />
        </div>
      )}
    </div>
  );
}

export default ReviewDetails;
