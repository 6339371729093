import "./index.css";
import filterIcon from "../../../../assets/Filter.svg";
import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CheckBox from "../Checkbox";
import Button from "../Button";

const Filter = ({ handleApply, handleClear, filters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const FilterOptions = () => {
    return [
      filters.map((filter, ind) => {
        return (
          <div className="mb-2" key={ind + filter.type}>
            <p className="mb-0 fw-bold">{filter.type}</p>
            <div className="d-flex  flex-wrap">
              {filter.options.map((option) => (
                <MenuItem className="col-5 filter-option" key={option.name}>
                  <CheckBox
                    label={option.name}
                    checked={option.value}
                    onChange={(e) => option.onChange(e.target.checked)}
                    name={option.name}
                  />
                </MenuItem>
              ))}
            </div>
          </div>
        );
      }),
    ];
  };

  return (
    <React.Fragment>
      <div>
        <div
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className="filter-container d-flex align-items-center  px-3 py-2 gap-2"
        >
          <img src={filterIcon} alt="filterIcon" />
          <span className="fs-6 bg-transparent filter-label">Filter</span>
        </div>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1,
                borderRadius: "24px",
                padding: "24px",
                maxWidth: "320px",
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
              },
            },
          }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          {FilterOptions()}
          <div className="d-flex align-items-center justify-content-around mt-4">
            <Button
              label="Cancel"
              type="button"
              color="primary"
              variant="text"
              className="fw-bold"
              onClick={() => {
                handleClear();
                handleClose();
              }}
            />
            <Button
              label="Apply"
              type="button"
             color="primary"
              onClick={() => {
                handleApply();
                handleClose();
              }}
            />
          </div>
        </Menu>
      </div>
    </React.Fragment>
  );
};
export default Filter;
