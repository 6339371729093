import { createSlice } from "@reduxjs/toolkit";
export const LOGOUT = 'LOGOUT';

const initialState = {
    isAuthorised: false,
    authorisedUser: null,
    userName: '',
    currentRole: ''
};

const AppSlice = createSlice({
    name: "App",
    initialState,
    reducers: {
        setIsAuthorised: (state, actions) => {
            state.isAuthorised = actions.payload;
        },
        setAuthorisedUser: (state, actions) => {
            state.authorisedUser = actions.payload;
        },
        setUserName: (state, actions) => {
            state.userName = actions.payload;
        },
        setCurrentRole: (state,actions)=> {
            state.currentRole = actions.payload
        }
    }
});

export const logout = () => ({
    type: LOGOUT,
});

export const { setIsAuthorised, setAuthorisedUser, setUserName, setCurrentRole } = AppSlice.actions;

export default AppSlice.reducer;