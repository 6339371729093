import { useState } from "react";
import { useParams } from "react-router-dom";

import InputField from "../../CommonComponents/InputField";
import Radio from "../../CommonComponents/Radio";
import Rating from "@mui/material/Rating";
import {todayDate} from "../../../../CommonUtils/dateUtils";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const CreditInfo = ({ formik }) => {
  const { id, type } = useParams();
  const [value, setValue] = useState(2);
  const handleChange = (newValue) => {
    setValue(newValue);
    formik.values.qualificationDifficulty = Number(newValue);
  };
  return (
    <form>
      <div className="row pb-4">
        <div className="col-6">
          <p className="mb-2  fw-bold form-radio-header">Difficulty Rating </p>
          <Rating
            name="simple-controlled"
            value={formik.values.qualificationDifficulty}
            onChange={(event, newValue) => {
              handleChange(newValue);
            }}
            size="medium"
            disabled={type === "view" ? true : false}
            sx={{
              "& .MuiRating-iconFilled": {
                color: "#162395",
              },
            }}
          />
        </div>
        <div className="col-6">
          <p className="mb-2  fw-bold form-radio-header">
            Requires Negotiation
            {/* <span className="mandatorAsterik"> *</span> */}
          </p>
          <div className="d-flex align-items-center gap-3 gap-sm-5 ">
            <Radio
              checked={formik.values.requiresNegotiation === "yes"}
              onChange={formik.handleChange}
              name="requiresNegotiation"
              label="Yes"
              value="yes"
              disabled={type === "view" ? true : false}
            />
            <Radio
              checked={formik.values.requiresNegotiation === "no"}
              onChange={formik.handleChange}
              name="requiresNegotiation"
              label="No"
              value="no"
              disabled={type === "view" ? true : false}
            />
          </div>
          {/* {formik.touched.requiresNegotiation &&
            formik.errors.requiresNegotiation && (
              <div className="error px-1">
                {formik.errors.requiresNegotiation}
              </div>
            )} */}
        </div>
      </div>

      <div className="row pb-4">
        <div className="col-6">
          <InputField
            name="avgCreditPerEmployee"
            value={formik.values.avgCreditPerEmployee}
            onChange={formik.handleChange}
            title="Available Credit"
            type="text"
            className="form-control"
            disabled={type === "view" ? true : false}
          />

          {/* {formik.touched.name && formik.errors.name && (
            <div className="error px-1">{formik.errors.name}</div>
          )} */}
        </div>
        <div className="col-6">
          <InputField
            name="avgCreditPerNewHire"
            value={formik.values.avgCreditPerNewHire}
            onChange={formik.handleChange}
            title="New Hire Credit"
            type="text"
            className="form-control"
            disabled={type === "view" ? true : false}
          />
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-6">
          <InputField
            name="avgJobGrowthCreditPerNewHire"
            value={formik.values.avgJobGrowthCreditPerNewHire}
            onChange={formik.handleChange}
            title="Job Growth Credit"
            type="text"
            className="form-control"
            disabled={type === "view" ? true : false}
          />
        </div>
        <div className="col-6">
          <InputField
            name="lookBackYears"
            value={formik.values.lookBackYears}
            onChange={formik.handleChange}
            title="Look Back Months"
            type="text"
            className="form-control"
            disabled={type === "view" ? true : false}
          />
        </div>
      </div>

      <div className="row pb-4">
        <div className="col-6">
          {/* <InputField
            name="expirationDate"
            value={formik.values.expirationDate}
            onChange={formik.handleChange}
            title="Expires"
            type="date"
            className="form-control"
            isRequired={false}
            disabled={type === "view" ? true : false}
            min={todayDate()}
          /> */}
          {/* {formik.touched.expirationDate && formik.errors.expirationDate && (
            <div className="error px-1">{formik.errors.expirationDate}</div>
          )} */}
          {/* {formik.values.expirationDate} */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Expires"
              value={
                formik.values.expirationDate
                  ? dayjs(formik.values.expirationDate)
                  : null
              }
              onChange={(date) => {
                formik.setFieldValue(
                  "expirationDate",
                  date ? dayjs(date).format("YYYY-MM-DD")  : null
                );
              }}
              format="MM/DD/YYYY" 
              slotProps={{
                textField: {
                  variant: "outlined",
                  size: "small",
                  fullWidth: true,
                  InputLabelProps: { shrink: true },
                  InputProps: { style: { fontSize: '14px', height: '40px' } },
                },
              }}
            disabled={type === "view" ? true : false}
            minDate={dayjs(todayDate())}
            />
          </LocalizationProvider>
        </div>
      </div>
    </form>
  );
};

export default CreditInfo;
