import * as React from "react";
import "./index.css";
import SearchIcon from "@mui/icons-material/Search";
import SearchIcon_big from "../../../../assets/Search_big.png";

const Search = ({ value, onChange, onClick }) => {
  return (
    <div>
      <div className="search-continaer d-flex align-items-center">
        <input
          className="input-search-box"
          name="search"
          type="text"
          placeholder="Search..."
          value={value}
          onChange={onChange}
        />
        <img
          src={SearchIcon_big}
          className="SearchIcon_big"
          onClick={onClick}
          alt="Search..."
        ></img>
      </div>
      {/* <div className="hint-text">hint: enter atleast 3 characters</div> */}
    </div>
  );
};

export default Search;
