import L from 'leaflet';

const greenIcon = new L.Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=7880&format=png&color=40C057',
  iconSize: [32, 32], // Set to square size to avoid stretching
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  shadowSize: [32, 32] // Adjusted to match the icon size
});

const blueIcon = new L.Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=7880&format=png&color=228BE6',
  iconSize: [32, 32], // Set to square size to avoid stretching
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  shadowSize: [32, 32] // Adjusted to match the icon size
});

const yellowIcon = new L.Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=7880&format=png&color=FAB005',
  iconSize: [32, 32], // Set to square size to avoid stretching
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  shadowSize: [32, 32] // Adjusted to match the icon size
});

const redIcon = new L.Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=7880&format=png&color=FA5252',
  iconSize: [32, 32], // Set to square size to avoid stretching
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  shadowSize: [32, 32] // Adjusted to match the icon size
});

const purpleIcon = new L.Icon({
  iconUrl: 'https://img.icons8.com/?size=100&id=7880&format=png&color=800080',
  iconSize: [32, 32], // Set to square size to avoid stretching
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  shadowSize: [32, 32] // Adjusted to match the icon size
});

export { greenIcon, blueIcon, yellowIcon, redIcon, purpleIcon };
