import React, { useState } from "react";
import "./index.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import CustomPagination from "../CustomPagination/index";

const CustomTable = ({
  columns,
  data,
  showCount = false,
  name,
  title,
  editHandling,
  activationHandling,
  pagination,
  selectedPage,
  setSelectedPage,
  totalPages,
  handleRowClick,
  sorting,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null);
  const [menuOpenedRow, setMenuOpenedRow] = useState(null);
  const [sortingType, setSorting] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event, row) => {
    event.stopPropagation();
    if(row.disable) {
      return;
    }
    setMenuOpenedRow(row.id);
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpenedRow(null);
  };

  const handleEdit = () => {
    editHandling(selectedRow);
  };

  const handleActivation = () => {
    activationHandling(selectedRow);
  };

  const sortTable = () => {
    sorting(!sortingType);
    setSorting(!sortingType);
  };

  const statusClass = (status) => {
    if(status) {

      if(status === 'Pending') {
       return 'pending-cell'
      } else if (status === 'Active') {
       return 'active-cell'
      } else {
       return 'inactive-cell'
      }
    }
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {Object.values(columns).map((column, index) =>
                column === "Credit name" ? (
                  <TableCell
                    key={index}
                    onClick={() => (sorting ? sortTable() : "")}
                    className={sorting ? "cursor" : ""}
                  >{`${column} ${
                    showCount && index === 0 ? `(${data.length})` : ""
                  }`}</TableCell>
                ) : (
                  <TableCell key={index}>{`${column} ${
                    showCount && index === 0 ? `(${data.length})` : ""
                  }`}</TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {data.length > 0 &&
              data.map((row, index) => (
                <TableRow
                  key={name + row.id + index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => (handleRowClick ? handleRowClick(row) : "")}
                  className={row.disable ? 'row-disable' : handleRowClick  ? "cursor" : ""}
                 
                >
                  {Object.keys(columns).map((column, index) =>
                    column === "actions" ? (
                      <TableCell key={row.id + index} className={`action-cell`}>
                        <div>
                          <MoreVertIcon
                            onClick={ (event) => handleClick(event, row)}
                            className="control-icon align-self-center"
                            key={"icon" + row.id}
                          />
                        </div>
                      </TableCell>
                    ) :  column === "userRole" ? <TableCell  key={row.id + row[column]}>
                      {/* {(row[column])} */}
                      {row[column].map((roleData,rIndex)=>{
                        return <>
                        {roleData} 
                        {row[column].length-1 > rIndex && <span>, </span>}
                        </>
                      })}
                    </TableCell> : (
                      <TableCell 
                        key={row.id + row[column]}
                        className={`${
                          column === "status"
                            ? statusClass(row?.status)
                            : ""
                        } ${
                          column === "company" && row?.onClick
                            ? "clickable-cell"
                            : ""
                        }`}
                        onClick={
                          column === "company" && row?.onClick
                            ? row.onClick
                            : () => {}
                        }
                        // style={index == 0 ? {width: '40%'} : {}}
                      >
                        <div className={`${index == 0 ? 'rowtext-wrap' : ''}`}>

                        {row[column]} 
                        </div>
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!data?.length && <div className="text-center py-4">No data available</div>}
      </TableContainer>

      {totalPages > 0 && pagination && (
        <CustomPagination
          count={totalPages}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        className="table-action-menu"
        id={"table-action-menu"}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              borderRadius: "16px",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
      {selectedRow?.status !== 'Pending' &&  <MenuItem onClick={handleActivation}>{`Make ${title} ${
          selectedRow?.status === "Active" ? "Inactive" : "active"
        }`}</MenuItem> }
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        {/* <MenuItem onClick={handleClose}>Delete</MenuItem> */}
      </Menu>
    </>
  );
};

export default CustomTable;
