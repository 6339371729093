import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedTab: 0,
};

const SuperAdminSlice = createSlice({
    name: "superAdmin",
    initialState,
    reducers: {
        setSelectedTab: (state, actions) => {
            state.selectedTab = actions.payload;
        },
    }
});

export const { setSelectedTab } = SuperAdminSlice.actions;

export default SuperAdminSlice.reducer;