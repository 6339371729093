import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import {setAuthorisedUser, setIsAuthorised,setUserName, setCurrentRole} from "./store/reducers/AppReducer";
import Header from "./pages/components/CommonComponents/Header";




const ProtectedRoute = ({ element: Element, allowedRoles }) => {
  //   const user = useSelector((state) => state.app.authorisedUser);
  //   const isAuthorised = useSelector((state) => state.app.isAuthorised);
  //   const role = user?.roles[0] || "";
  const dispatch = useDispatch();
  const currentRole = localStorage.getItem("currentRole")
  const data = localStorage.getItem("user");
  const user = JSON.parse(data);
  let role;
  let isAuthorised = false;
  let name = localStorage.getItem("userName");
  if(name) {

      dispatch(setUserName(name));
  }
  if (user) {
      dispatch(setIsAuthorised(true));
      dispatch(setAuthorisedUser(user));
      dispatch(setCurrentRole(currentRole))
    isAuthorised = true;
    role = user?.roles[0] || "";
  }

  if (!isAuthorised) {
    return <Navigate to="/" />;
  }

  if (!allowedRoles.includes(currentRole)) {
    return <Navigate to="/" />;
  }

  return (
    <React.Suspense fallback={<>...Loading</>}>
      <Element />
    </React.Suspense>
  );
};

export default ProtectedRoute;
