// src/store/slices/dataSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Fbaseurl";

// Async thunks to fetch data
export const fetchStates = createAsyncThunk("data/fetchStates", async () => {
  try {
    const response = await axiosInstance.get("taxCredits/states");
    if (response.status === 200) {
        const updatedStates = response.data.reduce((acc, cur) => {
            if (cur) {
              acc.push({
                id: cur.code,
                key: cur.name
              });
            }
            return acc;
          }, []);
      return updatedStates;
    }
  } catch (error) {
    console.log(error);
  }
});

export const fetchBusinessSizeCodes = createAsyncThunk("data/fetchBusinessSizeCodes", async () => {
  try {
    const response = await axiosInstance.get("taxCredits/businessSizeCodes");
    if (response.status === 200) {
        const updatedBusinessCodes = response.data.reduce((acc, cur) => {
            if (cur) {
              acc.push({
                id: cur.code,
                key: cur.description
              });
            }
            return acc;
          }, []);
      return updatedBusinessCodes;
    }
  } catch (error) {
    console.log(error);
  }
});

export const fetchHeadCountCodes = createAsyncThunk("data/fetchHeadCountCodes", async () => {
  try {
    const response = await axiosInstance.get("taxCredits/headcountCodes");
    if (response.status === 200) {
        const updatedHeadCountCodes = response.data.reduce((acc, cur) => {
            if (cur) {
              acc.push({
                id: cur.code,
                key: cur.description
              });
            }
            return acc;
          }, []);
      return updatedHeadCountCodes;
    }
  } catch (error) {
    console.log(error);
  }
});

export const fetchYearsInBusinessCodes = createAsyncThunk("data/fetchYearsInBusinessCodes", async () => {
  try {
    const response = await axiosInstance.get("taxCredits/yearsInBusinessCodes");
    if (response.status === 200) {
        const updatedYearsInBusinessCodes = response.data.reduce((acc, cur) => {
            if (cur) {
              acc.push({
                id: cur.code,
                key: cur.description
              });
            }
            return acc;
          }, []);
      return updatedYearsInBusinessCodes;
    }
  } catch (error) {
    console.log(error);
  }
});

// Initial state
const initialState = {
  statesList: [],
  businessSizeCodesList: [],
  headcountCodesList: [],
  yearsInBusinessCodesList: [],
  loading: false,
  error: null,
};

// Create the slice
const taxCreditSlice = createSlice({
  name: "data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStates.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.loading = false;
        state.statesList = action.payload;
      })
      .addCase(fetchStates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchBusinessSizeCodes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBusinessSizeCodes.fulfilled, (state, action) => {
        state.loading = false;
        state.businessSizeCodesList = action.payload;
      })
      .addCase(fetchBusinessSizeCodes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchHeadCountCodes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHeadCountCodes.fulfilled, (state, action) => {
        state.loading = false;
        state.headcountCodesList = action.payload;
      })
      .addCase(fetchHeadCountCodes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchYearsInBusinessCodes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchYearsInBusinessCodes.fulfilled, (state, action) => {
        state.loading = false;
        state.yearsInBusinessCodesList = action.payload;
      })
      .addCase(fetchYearsInBusinessCodes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default taxCreditSlice.reducer;
