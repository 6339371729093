import * as React from "react";
import { LoadingButton } from "@mui/lab";
import "./index.css";

const Button = ({
  onClick,
  label,
  icon,
  className,
  type,
  primary,
  secondary,
  isLoading,
  color,
  variant='contained',
  disabled=false,
  sx='',
  containerClass
}) => {
  return (
    <div className={`button-container ${containerClass}`} >
       <LoadingButton
          fullWidth
          type={type} 
          variant={variant}
          color={color}
          size="medium"
          sx={{ textTransform: "none", borderRadius: "8px", ...sx }}
          onClick={onClick}
          loading={isLoading}
          disabled={disabled}
        >
          {icon && <span className="icon m-right">{icon}</span> }
          {label}</LoadingButton>

      {/* {icon && secondary && (
        <LoadingButton
          type={type} 
          variant="contained"
          color="secondary"
          size="medium"
          sx={{ textTransform: "none" }}
          onClick={onClick}
          loading={isLoading}
        ><span className="icon mr-2">{icon}</span>{label}</LoadingButton>
      )}  
      {icon && primary && (
        <LoadingButton
          type={type} 
          variant="contained"
          color="primary"
          size="medium"
          sx={{ textTransform: "none" }}
          onClick={onClick}
          loading={isLoading}
        ><span className="icon mr-2">{icon}</span>{label}</LoadingButton>
      )}    
      {!icon && primary && (
        <LoadingButton
          type={type} 
          variant="contained"
          color="primary"
          size="medium"
          sx={{ textTransform: "none" }}
          onClick={onClick}
          loading={isLoading}
        >{label}</LoadingButton>
      )}  
      {!icon && secondary && (
        <LoadingButton
          type={type} 
          variant="contained"
          color="secondary"
          size="medium"
          sx={{ textTransform: "none" }}
          onClick={onClick}
          loading={isLoading}
        >{label}</LoadingButton>
      )}   */}
    </div>
  );
};

export default Button;
