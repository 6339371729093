import React, { useState, useEffect, useRef, useCallback } from "react";
import * as Yup from "yup";
import axios from "axios";

import Checkbox from "../../components/CommonComponents/Checkbox";
import { showToast } from "../../../store/reducers/ToastMessageReducer";
import Header from "../../components/CommonComponents/Header";
import ConfirmationPopup from "../../components/CommonComponents/ConfirmationPopup";
import "./index.css";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { setSelectedTab } from "../../../store/reducers/SuperAdminReducer";

import Input from "../../components/CommonComponents/InputField";
import Button from "../../components/CommonComponents/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axiosInstance from "../../../Fbaseurl";
import QuestionCircle from "../../../assets/QuestionCircle.svg";
import upload from "../../../assets/upload.svg";
import uploadDisabled from "../../../assets/uploadDisabled.svg";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import defaultLogo from '../../../assets/defaultLogo.jpg';
import RButton from '@mui/material/Button';
import Cropper from 'react-easy-crop';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
const CompanySetUpForm = () => {
  const baseURLs = {
    'localhost': 'https://api-lrportal-dev-eus.azurewebsites.net/api/v2',
    'ui-lrportal-dev-eus.azurewebsites.net': 'https://api-lrportal-dev-eus.azurewebsites.net/api/v2',
    'ui-lrportal-test-eus.azurewebsites.net':'https://api-lrportal-test-eus.azurewebsites.net/api/v2',
    'ui-lrportal-uat-eus.azurewebsites.net':'https://api-lrportal-uat-eus.azurewebsites.net/api/v2',
    // 'ui-lrportal-prod-eus.azurewebsites.net':'https://api-lrportal-prod-eus.azurewebsites.net/api/v2',
    'www.taxraven.com': 'https://api-lrportal-prod-eus.azurewebsites.net/api/v2',
    'ui-lrportal-prod-eus-staging.azurewebsites.net': 'https://api-lrportal-prod-eus-staging.azurewebsites.net/api/v2'
    // Add more mappings as needed
  };
  const hostName = window.location.hostname;
  const apiUrl =  baseURLs[hostName] || `https://api-lrportal-dev-eus.azurewebsites.net/api/v2`;
  const companyAxiosInstance = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
  });
  companyAxiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem('accessToken'); // Get the access token from local storage
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const cropperRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, type } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('page');
  const [confirmationContent, setConfirmationContent] = useState(
    "Are you sure you want to Cancel ?"
  );
  const [isPopupOpen, setPopup] = useState(false);
  const [initialValues, setInitialValues] = useState({
    companyName: "",
    isActive: true,
    logoUrl: null,
  });
  const [uploadedImage, setUploadedImage] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  // const [croppedImage, setCroppedImage] = useState(null); 
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.5);
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);
  const [croppedFile, setCroppedFile] = useState(null);
  const companyCreationSchema = Yup.object().shape({
    companyName: Yup.string().trim().required("Company Name is required").max(50, "Company Name must be at most 50 characters"),
    logoUrl: Yup.mixed().required("Company Logo is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: companyCreationSchema,
    onSubmit: async (values) => {
      if (Object.keys(formik.errors).length === 0) {

        setIsLoading(true);

        const payload = {
          CompanyName: formik.values.companyName.trim(),
          isActive: id ? !formik.values.isActive : true,
          CompanyLogo: croppedFile
        };
        let url = id ? `/companies/${id}` : `/companies`;
        try {
          const response = id
            ? await companyAxiosInstance.patch(url, payload, {
              headers: {
                  'Content-Type': 'multipart/form-data',
              }
          })
            : await companyAxiosInstance.post(url, payload,   {
              headers: {
                  'Content-Type': 'multipart/form-data',
              }, 
              
          },
         );
          if (response?.data) {
            setIsLoading(false);
            dispatch(
              showToast({
                message: `${formik.values.companyName} ${
                  id ? "updated" : "added"
                } successfully!`,
              })
            );
            navigate(`/superadmin/dashboard/companies?page=${page}`);
            setInitialValues({});
            dispatch(setSelectedTab(0));
          }
        } catch (error) {
          setIsLoading(false);
          let errorMsg = error?.response?.data?.errors?.DuplicateCompanyName
            ? error.response.data.errors.DuplicateCompanyName
            : error?.response?.data?.title;
          dispatch(
            showToast({
              type: "error",
              message:
                errorMsg ||
                `Failed to ${id ? "update" : "add"} ${
                  formik.values.companyName
                } `,
            })
          );
        }
      }
    },
  });

  const getCompanyInfo = async () => {
    try {
      const response = await axiosInstance.get(`/companies/${id}`);
      if (response.status === 200) {
        setInitialValues({
          companyName: response.data.companyName,
          isActive: !response.data.isActive,
          logoUrl:`${response.data.logoUrl}?${new Date()}`
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const navTitle = () => {
    if (!id) {
      return `Company Setup`;
    } else {
      return type === "view" ? "View" : "Edit";
    }
  };

  const cancelConfirmation = () => {
    setPopup(true);
  };

  const onConfirmCancel = () => {
    navigate(`/superadmin/dashboard/companies?page=${page}`);
    dispatch(setSelectedTab(0));
    setPopup(false);
  };

  const onCancelClick = () => {
    setPopup(false);
  };

  const handleFileUpload = (event) => {
    setUploadedImage();
    setIsImagePopupOpen(false);
    var file = event.target.files[0];
    console.log('TYPE', file.type)
    const validFileTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",]
      ;
    if(!validFileTypes.includes(file.type)){
      dispatch(
        showToast({
          type: "error",
          message: "Please upload valid image type",
        })
      )
      event.target.value = null
      return 
    }
    const maxAllowedSize = 2 * 1024 * 1024;
    if(file.size > maxAllowedSize){
      dispatch(
        showToast({
          type: "error",
          message: "File size should be less than 2 MB",
        })
      )
      event.target.value = null
      return
    }
   
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result); // Set the image to state
        setIsImagePopupOpen(true)
      };
      reader.readAsDataURL(file);
    }
    event.target.value = null
  }

  const onCropChange = crop => {
    setCrop(crop)
  }

  const onZoomChange = zoom => {
    setZoom(zoom)
  }

  const closeImagePopup = ()=>{
    setIsImagePopupOpen(false);
  }

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImage = useCallback(async () => {
    if (!uploadedImage || !croppedAreaPixels) return;

    try {
      const { croppedImageUrl, croppedBlob } = await createCroppedImage(uploadedImage, croppedAreaPixels);
      // setInitialValues({...initialValues, logoUrl: croppedImageUrl});
      // setCroppedImage(croppedImageUrl); // Set the cropped image URL
      formik.setFieldValue('logoUrl', croppedImageUrl);
      const file = new File([croppedBlob], "cropped-image.jpg", { type: "image/jpeg" }); // Create file
     
      setCroppedFile(file); // Store the cropped file
    } catch (e) {
      console.error("Failed to crop image", e);
    }
  }, [uploadedImage, croppedAreaPixels]);

  const createCroppedImage = (imageSrc, crop) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width;
        canvas.height = crop.height;

        ctx.drawImage(
          image,
          crop.x, crop.y, crop.width, crop.height, // Source
          0, 0, crop.width, crop.height  // Destination
        );

        // Convert canvas to Blob
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }
          const croppedImageUrl = URL.createObjectURL(blob);
          resolve({ croppedImageUrl, croppedBlob: blob }); // Return URL and Blob
        }, "image/jpeg");
      };

      image.onerror = (error) => reject(error);
    });
  };
  const saveCroppedImage = ()=>{
    getCroppedImage()
    setIsImagePopupOpen(false);
    setUploadedImage(null)
  }
  useEffect(() => {
    if (id) getCompanyInfo();
  }, [id]);

  return (
    <div className="form-wrapper">
      <Header />
      <div className="company-form p-4">
        <div className="form-back-control d-flex align-items-center justify-content-between gap-4 mx-4">
          <div className="d-flex align-items-center gap-4">
            <ArrowBackIcon
              className="back-btn"
              onClick={() => {
                navigate(`/superadmin/dashboard/companies?page=${page}`);
                dispatch(setSelectedTab(0));
              }}
            />
            <p className="fw-bold fs-4 m-0">{navTitle()}</p>
          </div>
          {type === "view" && (
            <Button
              className="float-end"
              primary={true}
              isLoading={isLoading}
              label="Edit"
              icon={<EditOutlinedIcon />}
              onClick={() => {
                navigate(`/superadmin/company/edit/${id}?page=${page}`);
              }}
            />
          )}
        </div>
        <div className="form-container p-4 m-4 d-flex">
          <form
            onSubmit={formik.handleSubmit}
            className="d-flex flex-column form-group w-100"
          >
            <div className="col-6">
              <Input
                placeholder="Enter your company name "
                value={formik.values.companyName}
                onChange={formik.handleChange}
                type="text"
                name="companyName"
                label="Company Name "
                className="col-lg-5 col-md-6 col-sm-12 mb-3 mb-md-auto mb-lg-auto"
                disabled={type === "view" ? true : false}
                title="Company Name"
                isRequired={true}
              />
              {formik.touched.companyName && formik.errors.companyName && (
                <div className="error px-1">{formik.errors.companyName}</div>
              )}
            </div>
            <hr></hr>
            <div className="font-20 fw-bold">Add Company Logo</div>
            {/* {JSON.stringify(croppedFile)} */}
            <img
              src={formik.values.logoUrl ? formik.values.logoUrl : defaultLogo}
              alt="Hrlogics"
              height="163px"
              width="163px"
              className="imgClass my-4"
            ></img>
              <div className="font-12">Supported files are PNG,JPG,JPEG.</div>
              <div className="font-12">Limit size 2MB</div>
            {formik.touched.logoUrl && formik.errors.logoUrl && (
              <div className="error px-1">{formik.errors.logoUrl}</div>
            )}
            <RButton
              variant="outlined"
              size="medium"
              className="mt-3"
              color="secondary"
              sx={{
                textTransform: "none",
                borderRadius: "24px",
                width: "163px",
              }}
              component="label"
              disabled={type==='view' ? true : false}
            >
              <img src={ type === 'view' ? uploadDisabled : upload} alt="upload"></img> Upload
              <input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
            </RButton>
            {/* {id && (
              <Checkbox
                label="Deactivate company"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                name="isActive"
                className="mt-3 mt-md-0 mt-lg-0"
                disabled={type === "view" ? true : false}
              />
            )} */}
            {/* </div> */}

            {type !== "view" && (
              <div className="card-footer d-flex justify-content-between align-items-center align-self-end gap-3 mt-auto">
                <Button
                  label="Cancel"
                  onClick={() => {
                    cancelConfirmation();
                  }}
                  className="fw-bold"
                  type="button"
                  color="primary"
                  variant="text"
                />
                <Button
                  color="primary"
                  isLoading={isLoading}
                  type="submit"
                  label="Save"
                />
              </div>
            )}
          </form>
        </div>
      </div>
      <React.Fragment>
      <Dialog
        open={isImagePopupOpen}
        onClose={closeImagePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ "& .MuiPaper-root": { borderRadius: "24px !important" }, padding: '16px' }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ paddingBottom: "0px !important" }}
          className="d-flex justify-content-between"
        >
          <div className="fw-bold" >Edit Image</div>
          <CloseIcon
            className="back-btn float-end my-auto"
            onClick={() => {
              closeImagePopup();
            }}
          />
        </DialogTitle>
        <DialogContent>
           {uploadedImage &&  <div style={{
              position: 'relative',
              width: '400px',
              height: '400px',
              marginTop: '24px '
            }}>
            <Cropper
              image={uploadedImage}
              crop={crop}
              zoom={zoom}
              aspect={1}
              cropShape="round"
              cropSize={{ width: 256, height: 256 }}
              showGrid={false}
              onCropChange={onCropChange}
              onZoomChange={onZoomChange}
              restrictPosition={true}
              onCropComplete={onCropComplete}
              // ref={cropperRef}
            />
            </div> }
            
        </DialogContent>
        <DialogActions className="mb-3 gap-4 d-flex justify-content-between mx-3">
        <input
            type="range"
            min="1"
            max="3"
            step="any"
            value={zoom}
            onChange={e => onZoomChange(e.target.value)}
            className="Slider"
          />
          <Button
            label="Save"
            onClick={saveCroppedImage}
            className={`rounded-pill col-6 fw-bold py-1 `}
            type="button"
            color="primary"
            variant="contained"
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
      <ConfirmationPopup
        isPopupOpen={isPopupOpen}
        content={confirmationContent}
        icon={QuestionCircle}
        onCancel={onCancelClick}
        onConfirm={onConfirmCancel}
      ></ConfirmationPopup>
    </div>
  );
};

export default CompanySetUpForm;
