import {UPDATE_COMPANIESLIST, UPDATE_SELECTEDCOMPANIES } from '../taskActions.js'


const initialState = {
    companies: [],
    selecteCompanies: [],
   
};

const companiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_COMPANIESLIST:
            return {
                ...state,
                companies:  action.payload,
            };
        case UPDATE_SELECTEDCOMPANIES:
            return {
                ...state,
                selecteCompanies: action.payload,
            }
       
        default:
            return state;
    }
};

export default companiesReducer;