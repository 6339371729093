// Toast.js
import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";

const CustomSnackbar = styled(Snackbar)(({ bgcolor }) => ({
  "& .MuiSnackbarContent-root": {
    backgroundColor: "white",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    color: "black",
    paddingRight: "8px",
    borderRadiuss: "8px",
    fontFamily: "IBM Plex Serif",
  },
}));

const CustomAlert = styled(MuiAlert)(({ theme }) => ({
  backgroundColor: "white",
  color: "black",
  marginTop: "30px",
  padding: 0,
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Added box shadow
  borderRadius: "8px", // Add border radius
  fontFamily: "IBM Plex Serif",
  fontWeight: '500',
  ".MuiAlert-action": {
    padding: "0px",
  },
  ".MuiIconButton-root": {
    marginRight: "16px",
  },
}));

const IconContainer = styled("div")(({ theme, bgColor }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: bgColor || "#4caf50",
  //   borderRadius: "0 4px 4px 0",
  padding: theme.spacing(0.25),
  borderTopLeftRadius: "8px", // Add border radius
  borderBottomLeftRadius: "8px", // Add border radius
  minWidth: "24px", // Maintain a consistent background size
  minHeight: "40px",
}));

const Toast = ({ open, handleClose, message, type }) => {
  const renderIcon = () => {
    switch (type) {
      case "success":
        return {
          icon: <CheckIcon style={{ color: "white", fontSize: "16px"  }} />,
          bgColor: `#4caf50`,
        };
      case "error":
        return {
          icon: <ErrorIcon style={{ color: "white", fontSize: "16px" }} />,
          bgColor: `red`,
        };
      case "warning":
        return {
          icon: <WarningIcon style={{ color: "white", fontSize: "16px" }} />,
          bgColor: `yellow`,
        };
      default:
        return {
          icon: <CheckIcon style={{ color: "white", fontSize: "16px" }} />,
          bgColor: `#4caf50`,
        };
    }
  };

  return (
    <CustomSnackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <CustomAlert
        icon={false}
        style={{ padding: "0px" }}
        className="alert"
        action={
          <>
            <IconContainer bgColor={renderIcon().bgColor}>
              {/* <CheckIcon style={{ color: "white" }} /> */}
              {renderIcon().icon}
            </IconContainer>
            <div className="m-3 my-auto font-16">{message}</div>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
              className="my-auto"
            >
              <CloseIcon fontSize="small" className="my-auto" />
            </IconButton>
          </>
        }
      ></CustomAlert>
    </CustomSnackbar>
  );
};

export default Toast;
