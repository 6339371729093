import React, { useEffect, useState } from "react";
import useDebounce from "../../../Hooks/useDebounce";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import axiosInstance from "../../../Fbaseurl";
import CustomSingleSelect from "../../components/CommonComponents/SingleSelect";
import InputField from "../../components/CommonComponents/InputField";
import { formatDate, todayDate } from "../../../CommonUtils/dateUtils";
import { showToast } from "../../../store/reducers/ToastMessageReducer";
import BarGraph from "../../components/SuperAdminComp/BarGraph";
import SelectComp from "../../components/CommonComponents/SelectComp";
import ReportsPdf from "../../components/SuperAdminComp/ReportsPdf";
import {todayDateStartsWithDate} from "../../../CommonUtils/dateUtils";


// import {convertJsonToExcel} from "../../SalesPersonCommonMethods/taxCreditsReport";


import "./index.css";
import { useNavigate, useLocation } from "react-router-dom";
import CustomTable from "../../components/CommonComponents/Table";

import Filter from "../../components/CommonComponents/Filter";
import { useSelector, useDispatch } from "react-redux";
import LaunchIcon from "@mui/icons-material/Launch";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import CSV from "../../../assets/CSV.svg";
import PDF from "../../../assets/PDF.svg";
import html2pdf from "html2pdf.js";
import {
  Card,
  CardActions,
  CardContent,
  Popover,
  CircularProgress,
} from "@mui/material";
import { number } from "yup";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const Reports = () => {
  let currentDate = JSON.stringify(new Date()).split("T");
  let date = currentDate[0].replace(/['"]+/g, "");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentRole = useSelector((state) => state.App.currentRole);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [companiesList, setCompaniesList] = useState([]);
  const [reportsList, setReportsList] = useState([]);
  const [informationRequests, setInformationRequests] = useState([]);
  const [pastWeeksDates, setPastWeeksdates] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [showActiveCompanies, setShowActiveCompanies] = useState(false);
  const [showInactiveCompanies, setShowInactiveCompanies] = useState(false);
  const [companiesTotalPages, setCompaniesTotalPages] = useState(null);
  // const debouncedSearchKey = useDebounce(selectedCompany, 500);
  const [selectedDate, setSelectedDate] = useState(date);
  const [selectedUserReport, setSelectedUserReport] = useState();
  const [userResearchedCompanies, setUserResearchedCompanies] = useState();
  const [dataFetchStatus, setDataFetchStatus] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [csvReportsList, setCsvReportsList] = useState([]);
  const [pdfReportsList, setPdfReportsList] = useState([]);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const getCompaniesList = async (payload = {}) => {
    try {
      const response = await axiosInstance.get(`/companies`, {params: payload});
      if (response.status === 200) {
        const updatedCompaniesList = response.data.items.reduce((acc, cur) => {
          if (cur) {
            acc.push({
              id: cur.companyId,
              key: cur.companyName,
              // status: cur.isActive ? "Active" : "Inactive",
              // actions: () => navigate(`/superadmin/company/:${cur.companyId}`),
            });
          }
          return acc;
        }, []);
        setCompaniesTotalPages(response.data.totalPages);
        setCompaniesList(updatedCompaniesList);
        setSelectedCompany(updatedCompaniesList[0].id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    window.history.replaceState({}, "", currentPath);
    if (currentRole === "SuperAdmin") getCompaniesList();
  }, []);

  useEffect(() => {
    if(companiesTotalPages > 1) {
      let pageSize = 50*companiesTotalPages;
      let payload = {
        page: 1,
        pageSize: pageSize
      }
      if (currentRole === "SuperAdmin")  {getCompaniesList(payload);}
    }
  }, [companiesTotalPages]);

  const getActivityReports = async (payload = {}, type) => {
    // const payloadWithSearch = {
    //   ...payload,
    // };
    const payloadWithSearch = {
      page: selectedPage,
      pageSize: 10,
      // search: debouncedSearchKey,
      ...payload,
    };
    // if (debouncedSearchKey) {
    //   payloadWithSearch.keyword = debouncedSearchKey;
    // }
    try {
      const response = await axiosInstance.get(
        `/UsersActivity/${selectedCompany}`,
        {
          params: payloadWithSearch,
        }
      );
      if (response.status === 200) {
        const updatedReportsList = response.data.items.reduce(
          (acc, cur, ind) => {
            if (cur) {
              acc.push({
                id: cur.userId,
                userName: cur.lastName
                  ? `${cur.lastName}, ${cur.firstName}`
                  : cur.firstName,
                logIns: cur.totalLogins,
                requests: cur.totalInformationRequests,
                // lastLogin: cur.lastLoginDateUtc,
                lastLogin: cur.lastLoginDateUtc
                  ? formatDate(cur.lastLoginDateUtc)
                  : "",
                action: (
                  <LaunchIcon
                    fontSize="small"
                    className={
                      cur.totalInformationRequests
                        ? "cursor-pointer"
                        : "cursor-disable"
                    }
                    onClick={(e) => {
                      handleSearchCompaniesList(e, cur);
                    }}
                  ></LaunchIcon>
                ),
              });
            }
            return acc;
          },
          []
        );
        if(type === 'CSV') {
          console.log('CSV', updatedReportsList)
          const jsonData = await prepareRequiredJsonToExcelDownload(updatedReportsList);
          await convertJsonToExcel(jsonData, "ActivityReport");
        } else if(type === 'PDF') {
          const subArraySize = 10; // Size of each subarray
          const mainArray = updatedReportsList.reduce(
            (resultArray, item, index) => {
              const chunkIndex = Math.floor(index / subArraySize);
              if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []; // Start a new chunk
              }
              resultArray[chunkIndex].push(item); // Add the item to the current chunk
              return resultArray;
            },
            []
          );
          console.log(mainArray);
          if(!mainArray.length) {
            mainArray.push([{
              "Users": "-",
              "Log Ins": "-",
              "Last Log In": "-",
              "Requests": '-'
            }])
          }
          setPdfReportsList(mainArray);
          // await handleDownloadasPDFReport();
        }
        else {
          setTotalPages(response.data.totalPages);
          setReportsList([...updatedReportsList]);
        }
      } else {
        setReportsList([]);
        setTotalPages(null);
      }
    } catch (error) {
      let errorMsg = error?.response?.data?.title;
      let msg = errorMsg
        ? errorMsg
        : `Failed to fetch the data. Please try again after some time`;
      dispatch(
        showToast({
          type: "error",
          message: msg,
        })
      );
      console.log(error);
    }
  };

  const getInformationRequests = async (payload = {}) => {
    const payloadData = {
      companyId: selectedCompany,
      // dateToLookbackFrom: getDateFiveWeeksBack(selectedDate),
      dateToLookbackFrom: selectedDate,
    };
    try {
      const response = await axiosInstance.get(`/informationRequests`, {
        params: payloadData,
      });
      if (response.status === 200) {
        let dates = [];
        let requestsCount = [];
        response.data.forEach((element) => {
          dates.push(formatDate(element.weekPeriodStart));
          requestsCount.push(element.totalRequests);
        });
        setInformationRequests([...requestsCount]);
        setPastWeeksdates([...dates]);
      }
    } catch (error) {
      console.log(error);
      let errorMsg = error?.response?.data?.title;
      let msg = errorMsg
        ? errorMsg
        : `Failed to fetch the data. Please try again after some time`;
      dispatch(
        showToast({
          type: "error",
          message: msg,
        })
      );
    }
  };

  function getDateFiveWeeksBack(selectedDate) {
    const date = selectedDate ? new Date(selectedDate) : new Date();
    const fiveWeeksInMilliseconds = 5 * 7 * 24 * 60 * 60 * 1000;
    date.setTime(date.getTime() - fiveWeeksInMilliseconds);
    return date;
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchCompaniesList = async (event, cur) => {
    if (!cur.totalInformationRequests) {
      return;
    }
    setSelectedUserReport(cur);
    setAnchorEl(event.currentTarget);
    setDataFetchStatus("TRIGGERED");
    setUserResearchedCompanies([]);
    try {
      const response = await axiosInstance.get(
        `/usersActivity/${cur.userId}/companies`
      );
      if (response.status === 200) {
        setUserResearchedCompanies(response.data.items);
        setDataFetchStatus("FETCHED_SUCCESSFULLY");
      }
    } catch (error) {
      console.log(error);
      setDataFetchStatus("FAILED_TO_FETCH");
      let errorMsg = error?.response?.data?.title;
      let msg = errorMsg
        ? errorMsg
        : `Failed to fetch the data. Please try again`;
      dispatch(
        showToast({
          type: "error",
          message: msg,
        })
      );
    }
  };
const handleDownloadasPDF = async() => {
  let pageSize = 10 * totalPages;
  await getActivityReports({ pageSize: pageSize, page: 1 }, "PDF");
  // await handleDownloadasPDFReport();
}
  const handleDownloadasPDFReport = async() => {
      const element = document.getElementById("pdf-content");
      const options = {
        margin: [0, 0, -10, 0],
        filename: "Report.pdf",
        image: { type: "jpeg", quality: 0.98 },
  
        html2canvas: { scale: 2, logging: true, useCORS: true },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
  
      html2pdf()
        .set(options)
        .from(element)
        .save()
        .finally(() => {
        });
  };
  const handleDownloadasCSV = async () => {
    let pageSize = 10 * totalPages;
    await getActivityReports({ pageSize: pageSize, page: 1 }, "CSV");
    // const jsonData = await prepareRequiredJsonToExcelDownload(csvReportsList);
    // await convertJsonToExcel(jsonData, "Activity report");
    // await getActivityReports();
  };

  const prepareRequiredJsonToExcelDownload = (reportsList) => {
    if(!reportsList.length) {
      const jsonData =  [{
        "Users": '',
        "Log Ins": '',
        "Last Log In": '',
         "Requests": ''
      }];
      return jsonData
    } 
    const jsonData =  reportsList.map((item,index) => {
      return {
        "Users": item.userName,
        "Log Ins": item.logIns,
        "Last Log In": item.lastLogin,
        "Requests": item.requests
      }
    })
    console.log('....',jsonData)
    return jsonData
  }

  const convertJsonToExcel = (jsonData, fileName) => {
    // Prepare the worksheet data from JSON
    const worksheetData = jsonData.map((row) => {
      // Handle arrays of objects within columns
     
        return {
          ...row}
      
    });
  
    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
  
    // Apply styles to the worksheet
    const range = XLSX.utils.decode_range(worksheet["!ref"]);
  
    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
        var cellAddress;
        cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
        if (!worksheet[cellAddress]) continue;
  
        // Example: Make the first row bold (assuming it’s the header)
        console.log("RANGE", worksheet[cellAddress]);
        // if (colNum === 1) {
        //   worksheet[cellAddress] = {
        //     v: worksheet[cellAddress].v,
        //     l: { Target: worksheet[cellAddress].v }, // Set the hyperlink target
        //     s: { font: { color: { rgb: "0000FF" }, underline: true } }, // Optional: style as a typical link
        //   };
        // }
      }
    }
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
    // Write the workbook and save as a file
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, `${fileName}.xlsx`);
  };

  useEffect(() => {
    setSelectedPage(1);
  }, [selectedCompany]);

  useEffect(() => {
    if (currentRole !== "SuperAdmin") {
      let selectedCompany = localStorage.getItem("companyAssigned");
      setSelectedCompany(Number(selectedCompany));
    }
    if (typeof selectedCompany == "number") {
      getActivityReports();
      getInformationRequests();
    }
  }, [selectedCompany, selectedPage]);
  useEffect(() => {
    if (currentRole !== "SuperAdmin") {
      let selectedCompany = localStorage.getItem("companyAssigned");
      setSelectedCompany(Number(selectedCompany));
    }
    if (typeof selectedCompany == "number") {
      getInformationRequests();
    }
  }, [selectedDate]);

  useEffect(() => {
    if(pdfReportsList.length) {

      handleDownloadasPDFReport();
    }
  },[pdfReportsList])

  // const handleApply = () => {
  //   let payload = {
  //     // search: debouncedSearchKey,
  //   };

  //   if (
  //     (!showActiveCompanies && showInactiveCompanies) ||
  //     (showActiveCompanies && !showInactiveCompanies)
  //   ) {
  //     payload.isActive = showActiveCompanies;
  //   }

  //   getActivityReports(payload);
  // };

  // const handleClear = () => {
  //   setShowActiveCompanies(false);
  //   setShowInactiveCompanies(false);
  //   getActivityReports();
  // };

  return (
    <div className="table-content">
      {/* <div className="filters my-3 mt-3 d-lg-flex d-md-flex justify-content-between align-items-center">
        <div className="d-flex flex-column flex-md-row  justify-content-between gap-4 mb-4 pa-2 mb-md-0 mb-lg-0"> */}
      {/* <Search
            value={searchUser}
            onChange={(e) => setSearchUser(e.target.value)}
          /> */}
      <div className="filters d-flex justify-content-between">
        {/* <CustomSingleSelect
          placeholder="Select your company"
          onChange={(value) => setSelectedCompany(value)}
          selectedValue={selectedCompany}
          options={companiesList}
          className="col-4"
        /> */}
        {/* {JSON.stringify(selectedCompany)} */}
        <div className="col-4 mt-3">
          {currentRole === "SuperAdmin" && (
            <SelectComp
              title=""
              value={selectedCompany}
              options={companiesList}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                setSelectedCompany(value);
              }}
              name="selectedCompany"
              isMultiSelect={false}
            ></SelectComp>
          )}
        </div>
        <div className="d-flex gap-3 my-auto" style={{ float: "right" }}>
          <LoadingButton
            variant="contained"
            color="primary"
            size="medium"
            sx={{ textTransform: "none", borderRadius: "8px" }}
            onClick={handleDownloadasPDF}
          >
            <img src={PDF} width="24px" height="24px" alt="donwmload"></img>{" "}
            Download PDF
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="primary"
            size="medium"
            sx={{ textTransform: "none", borderRadius: "8px" }}
            onClick={handleDownloadasCSV}
          >
            <img src={CSV} width="24px" height="24px" alt="donwmload"></img>{" "}
            Download Excel
          </LoadingButton>
        </div>
      </div>
      {/* className="col-lg-5 col-md-6 col-sm-12 mb-3 mb-md-0 mb-lg-0" */}
      {/* </div>
      </div> */}
      <div className="row d-flex d-lg-flex d-md-flex flex-column gap-4 form-group w-100 ">
        <div className="d-lg-flex d-md-flex  gap-4">
          <div className="col-lg-7 col-md-7 col-sm-12">
            <div className="card p-4">
              <div className=" mb-3 d-lg-flex d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-column flex-md-row  justify-content-between gap-0 mb-4 mb-md-0 mb-lg-0">
                  <div className="reports"> Activity Reports</div>
                </div>
                {/* <Filter
              handleApply={handleApply}
              handleClear={handleClear}
              filters={[
                {
                  type: "Type",
                  options: [
                    {
                      name: "Active",
                      value: showActiveCompanies,
                      onChange: setShowActiveCompanies,
                    },
                    {
                      name: "Inactive",
                      value: showInactiveCompanies,
                      onChange: setShowInactiveCompanies,
                    },
                  ],
                },
              ]}
            /> */}
              </div>
              <CustomTable
                name="usersList"
                columns={{
                  userName: "Users",
                  logIns: "Log Ins",
                  lastLogin: "Last Log In",
                  requests: "Requests",
                  action: "",
                }}
                data={reportsList}
                showCount={true}
                totalPages={totalPages}
                pagination={true}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </div>
          </div>

          <div className="col-5 ">
            <div className="card p-4">
              <div className="d-lg-flex d-md-flex justify-content-between align-items-center px-3">
                {/* <div className="d-flex flex-column flex-md-row  justify-content-between gap-4 mb-4 mb-md-0 mb-lg-0"> */}
                <div className="reports"> Weekly Reports</div>
                {/* </div> */}
                {/* <InputField
                  name="selectedDate"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  type="date"
                  className="form-control"
                  max={todayDate()}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={selectedDate ? dayjs(selectedDate) : null}
                    // onChange={(date) => {
                    //   setSelectedDate(date ? dayjs(date).format("YYYY-MM-DD") : null);
                    // }}
                    onChange={(date) => {
                      setSelectedDate(date ? dayjs(date).format("MM/DD/YYYY") : null);  // Store formatted date
                    }}                
                    format="MM/DD/YYYY"
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        size: "small",
                        
                        InputLabelProps: { shrink: true },
                        InputProps: {
                          style: { fontSize: "14px", height: "40px" },
                        },
                      },
                    }}
                    maxDate={dayjs(todayDate())}
                  />
                </LocalizationProvider>
                {/* <Filter
              handleApply={handleApply}
              handleClear={handleClear}
              filters={[
                {
                  type: "Type",
                  options: [
                    {
                      name: "Active",
                      value: showActiveCompanies,
                      onChange: setShowActiveCompanies,
                    },
                    {
                      name: "Inactive",
                      value: showInactiveCompanies,
                      onChange: setShowInactiveCompanies,
                    },
                  ],
                },
              ]}
            /> */}
              </div>
              <BarGraph
                xAxis={pastWeeksDates}
                values={informationRequests}
              ></BarGraph>
            </div>
          </div>
        </div>
      </div>
      {selectedUserReport && dataFetchStatus === "FETCHED_SUCCESSFULLY" && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className="ml-4"
        >
          <Card style={{ minWidth: "430px" }}>
            {dataFetchStatus === "FETCHED_SUCCESSFULLY" && (
              <CardContent>
                <div className="d-flex justify-content-between">
                  <h6 className="font-700">
                    {selectedUserReport.lastName
                      ? `${selectedUserReport.lastName}, ${selectedUserReport.firstName}`
                      : selectedUserReport.firstName}
                  </h6>
                  <HighlightOffOutlinedIcon
                    sx={{ color: "#2B1E70" }}
                    onClick={() => handleClose()}
                  ></HighlightOffOutlinedIcon>
                </div>
                <div className="font-12 font-700 comp-count">
                  {" "}
                  List of companies (
                  {selectedUserReport.totalInformationRequests})
                </div>
                <div className="comp-list p-2">
                  {userResearchedCompanies?.map((item, index) => {
                    return (
                      <div
                        className="font-12 font-500 p-2 comp-name"
                        key={index}
                      >
                        {item.businessName}
                      </div>
                    );
                  })}
                </div>
              </CardContent>
            )}
            {dataFetchStatus === "TRIGGERED" && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Card>
        </Popover>
      )}
      <div id="pdfId" className="reportsCls">
        <ReportsPdf
          selectedDate={selectedDate}
          pastWeeksDates={pastWeeksDates}
          informationRequests={informationRequests}
          activityReports={pdfReportsList}
        ></ReportsPdf>
      </div>
    </div>
  );
};

export default Reports;
