import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import backNav from "../../../assets/backNav.svg";
import { useNavigate } from "react-router-dom";
import Link from "../../../assets/Link.svg";
import Person from "../../../assets/Person.svg";
import AnnualHires from "../../../assets/AnnualHires.svg";
import Button from "../../components/CommonComponents/Button";

import './index.css'
import { useDispatch, useSelector } from "react-redux";
import {
  updateCompanies,
  updateSelectedCompnies,
} from "../../../store/actions/companyAction";
// import axiosInstance from "./Fbaseurl";
import CheckBox from "../../components/CommonComponents/Checkbox";

/*import Header from "./pages/components/Header/index";*/
import Header from "../../components/CommonComponents/Header";
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import { useMediaQuery } from '@mui/material';
const Blog = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.company.companies);
  const currentRole = useSelector((state)=> state.App.currentRole);
  const navigate = useNavigate();
  const location = useLocation();
  const responseData = location.state;

  const [open, setOpen] = React.useState(false);
  const [list, setList] = useState(responseData || companies);

  const [error, setError] = useState(null);
  const [userinfo, setUserInfo] = useState({
    subscribe: [],
    response: [],
  });
  const [isLoading, setLoader] = useState(false);
  const [isAllSelected, setAllSelected] = useState(false);

  useEffect(() => {
    if (list.every((item) => item.isSelected)) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [list]);
  const handleChange = (e) => {
    // Destructuring

    const { value, checked } = e.target;
    const { subscribe } = userinfo;
    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfo({
        subscribe: [...subscribe, value],
        response: [...subscribe, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfo({
        subscribe: subscribe.filter((e) => e !== value),
        response: subscribe.filter((e) => e !== value),
      });
    }
  };

  const navigateToTaxCredits = (e) => {
    const updatedList = list.map((item) => {
      var annualHires = null
      if(item.isAnnualHiresEditEnabled) {
        if(Number(item.annualHires) || item.annualHires == '0') {
          annualHires = Number(item.annualHires)
        }
      }
      return {
        ...item, annualHiresOverride: annualHires
      }
    }
    );
    setList(updatedList);
    dispatch(updateCompanies(updatedList));
    if (isAtleastOneSelected()) {
      setError("");
      let selectedList = updatedList.filter((item, index) => {
        return item.isSelected;
      });
      dispatch(updateSelectedCompnies(selectedList));
      
      if(currentRole === "Salesperson") {
        navigate("/salesperson/taxCreditAnalysis", { state: selectedList });
      } else if(currentRole === "Manager") {
        navigate("/manager/taxCreditAnalysis", { state: selectedList });
      } else if(currentRole === "CompanyAdmin") {
        navigate("/companyadmin/taxCreditAnalysis", { state: selectedList });
      }
    } else {
      setError("Please select atleast one business for review");
    }
  };

  const handleCheckBoxChange = (post) => {
    // post.isSelected = !post.isSelected;
    const updatedList = list.map((checkbox) =>
      checkbox.businessId === post.businessId
        ? { ...checkbox, isSelected: !checkbox.isSelected }
        : checkbox
    );
    setList(updatedList);
    dispatch(updateCompanies(updatedList));

    if (updatedList.length > 0) {
      setError("");
    }
  };
  const selectAll = () => {
    console.log("Data");
    let selected = !isAllSelected;
    const updatedList = list.map((checkbox) => ({
      ...checkbox,
      isSelected: selected,
    }));
    setList(updatedList);
    setAllSelected(selected);
    if (updatedList.length > 0) {
      setError("");
    }
  };

  function isAtleastOneSelected() {
    return list.some((item) => item.isSelected);
  }

  const updateAnnualHires = (post) => {
    const updatedList = list.map((item) =>
      item.businessId === post.businessId
        ? { ...item, isAnnualHiresEditEnabled: true }
        : item
    );
    setList(updatedList);
    dispatch(updateCompanies(updatedList));
  };

  const updateAnnualHiresValue = (e, post) => {
    if (!/^\d*\.?\d*$/.test(e.target.value)) {
      e.preventDefault();
      return;
    } 
    if(e.target.value.length > 10) {
      return
    }
    const updatedList = list.map((item) =>
      item.businessId === post.businessId
        ? { ...item, annualHires: e.target.value }
        : item
    );
    setList(updatedList);
    dispatch(updateCompanies(updatedList));
  };

  const backNavigation = () => {
    if(currentRole === 'Salesperson') {
      navigate("/salesperson/findBusinavTab")
    } else {
      navigate(`/${currentRole.toLowerCase()}/dashboard/findbusiness`)
    }
  }
  

  return (
    <>
      <Header />
      <div className={isMobile ? 'p-3' :'p-4 mx-4'}>
        <div className="row">
          <div className="d-flex ">
            <div className="p-right">
              <img
                src={backNav}
                height={isMobile ? "24px" : "36px"}
                width={isMobile ? "24px" : "36px"}
                onClick={() => backNavigation()}
                alt="Back Nav"
              ></img>
            </div>
            <div >
              <h2 className={`blogs-title ${!isMobile ? 'font-32' : ' font-20'}`}>
                Review & Update Information
              </h2>
              {/* <div className="text-center">Location details</div>*/}
            </div>
          </div>
        </div>
       
        <div className={`locationDetails blogs-layout ${!isMobile ? ' p-4 blogs-height' : 'py-2'}`}>
        {error && (
          <div className={`errorImageCredit ${isMobile ? ' px-1 mx-1' : ' px-2 mx-3'}`}>
            <img src="/DismissCircle.png" alt="" height="16px" width="16px" className="my-auto"></img>
            <span className="my-auto">
            {error}
              </span>
          </div>
        )}
          <div className="card-title px-3">
            <div className="font-14 font-700">{list.length === 1 ? '1 Record' : `${list.length} Records`} </div>
            <CheckBox
              label="Select all"
              checked={isAllSelected}
              onChange={selectAll}
              name="subscribe"
              className="font-14 font-500"
            />
            {/*<div className="expand-all">*/}
            {/*    <input type="checkbox" name="subscribe" id="subscribe" value={isAllSelected} onChange={selectAll} multiple /> <label for="subscribe">Select All</label></div>*/}
          </div>
          <div className={`  px-3 d-lg-flex d-md-flex mb-4 ${isMobile ? 'py-3' : ' row py-4'}`}>
          
            {list.map((post,index) => {
              return (
                <div
                  className={`col-lg-4 col-md-6 col-sm-12 ${isMobile ? ' pb-3' : ' pb-4'}`}
                  key={index}
                >
                  <div
                    className={
                      post.isSelected
                        ? "card selected-card p-2 reviewDetailsBlogs"
                        : "card p-2 reviewDetailsBlogs"
                    }
                  >
                    {/*<input*/}
                    {/*  type="checkbox"*/}
                    {/*  value={post.isSelected}*/}
                    {/*  onChange={() => handleCheckBoxChange(post)}*/}
                    {/*  id="subscribe"*/}
                    {/*  name="subscribe"*/}
                    {/*  multiple*/}
                    {/*  checked={post.isSelected}*/}
                    {/*/>*/}
                    <div className="card-body" style={{ position: "relative" }}>
                      <div className=" d-flex justify-content-between">
                        <div>
                          <CheckBox
                            checked={post.isSelected}
                            onChange={() => handleCheckBoxChange(post)}
                            name="subscribe"
                            className="mb-2"
                          />
                        </div>
                        <div>
                          <EditIcon
                            fontSize="small"
                            onClick={() => updateAnnualHires(post)}
                          ></EditIcon>
                        </div>
                      </div>
                      <div className="card-title card-align">
                        <div>
                          <div className="title font-700 font-20">
                            {" "}
                            {post.businessName}
                          </div>
                          <div className="text-lightgrey font-14 ">
                            {post.physicalAddress},{post.physicalCity},
                            {post.state}
                          </div>
                        </div>
                        <a
                          href={`https://${post.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-link p-0"
                        >
                          <img src={Link} alt="link"></img>
                        </a>
                      </div>

                      <div className="text-lightgrey pt-2">
                        Category: {post.siC01Description}
                      </div>

                      <div
                        className={` ${!isMobile ? ' card-title pt-3' : 'pt-2'}`}
                        >
                        {/* style={{ position: " absolute", bottom: "0" }} */}
                        <div className="p-right">
                          <img
                            src={Person}
                            width="20px"
                            height="20px"
                            alt="Person"
                            className="my-auto"
                          ></img>
                          <span className="font-14 font-500 my-auto">
                            Employees:
                            <span className="font-700 ">
                              {post?.locationEmployeesCount
                                ? post.locationEmployeesCount
                                : "No Data "}
                            </span>
                          </span>
                        </div>
                        <div>
                          <img
                            src={AnnualHires}
                            width="20px"
                            height="20px"
                            alt="PersonAccount"
                          ></img>
                          <span className="font-14 font-500">
                            Annual hires:{post.isAnnualHiresEditEnabled}
                            {!post.isAnnualHiresEditEnabled ? (
                              <span className="font-700">
                                {post?.annualHires
                                  ? post?.annualHires
                                  : "No Data "}
                              </span>
                            ) : (
                              <span>
                                <input
                                  value={post.annualHires}
                                  type="text"
                                  onChange={(e) =>
                                    updateAnnualHiresValue(e, post)
                                  }
                                  className="annual-hires-input"
                                ></input>
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={`d-flex justify-content-end ${isMobile ? ' px-3 pb-3' : 'mt-2'}`}>
          <Button
          label="Research Tax Credits"
          className="fw-bold"
          type="button"
          color="primary"
          variant="contained"
          onClick={() => {
            navigateToTaxCredits();
          }}
          isLoading={isLoading}
        />

            {/* <button
              style={{ marginRight: "22px" }}
              className={`nextBtn border-0 px-3 py-1 mb-3 fw-bold fs-14`}
              type="submit"
              role="status"
              onClick={(e) => navigateToTaxCredits()}
            >
              {isLoading ? (
                <div
                  className="spinner-border spinner-border-sm mx-2 p-1"
                  role="status"
                ></div>
              ) : (
                "Research Tax Credits"
              )}
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
