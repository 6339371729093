import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  open: false,
  duration: 5000,
  type: 'success'
};

const ToastMessageSlice = createSlice({
  name: "ToastMessage",
  initialState,
  reducers: {
    showToast: (state, actions) => {
      state.open = true;
      state.message = actions.payload.message;
      state.duration = actions.payload.duration;
      state.type = actions.payload.type;
    },
    hideToast: (state, actions) => {
      state.open = false;
      state.message = "";
      state.type = ''
    },
  },
});

export const { showToast, hideToast } = ToastMessageSlice.actions;

export default ToastMessageSlice.reducer;
