import React from "react";
//import api from "../../utils/api";
import useScript from "../../../../Hooks/useScript";
import axiosInstance from "../../../../Fbaseurl";
//import { VALIDATE_RECAPTCHA } from "../../utils/apiUrls";

const ReCaptcha = ({ onDataReceived, setLoader }) => {
  useScript("https://www.google.com/recaptcha/api.js", true);

  window.onCaptchaResponse = async (token) => {
    const value = await validateToken(token);
    onDataReceived(value);
  };

  window.onCaptchaExpired = () => {
    onDataReceived(false);
  };

  window.onCaptchaError = () => {
    onDataReceived(false);
  };

  const validateToken = async function (token) {
    try {
      setLoader(true)
      const response = await axiosInstance.post(
        "/account/validateRecaptcha",
        {
          userResponseToken: token
        },
      );

      if (response.status == 200) {
        setLoader(false)
        // if (result === false) {
        //     window.grecaptcha.reset();
        // }
        return true;
      } else {
        setLoader(false)
        window.grecaptcha.reset();
        return false;
      }
    } catch (ex) {
      setLoader(false)
      console.error(ex);
      window.grecaptcha.reset();
      return false;
    }
  };

  return (
    <div className="recaptcha-container">
      <div
        className="g-recaptcha"
        data-sitekey="6LfC0gEqAAAAANHd9d_6z_8c_DrRg5cExil3WgMP"
        data-callback="onCaptchaResponse"
        data-expired-callback="onCaptchaExpired"
        data-error-callback="onCaptchaError"
      ></div>
    </div>
  );
};

export default ReCaptcha;
