import React, { useEffect, useState } from "react";


import axiosInstance from "../../../../Fbaseurl";
import {setSelectedTab} from "../../../../store/reducers/SuperAdminReducer";
import {showToast} from "../../../../store/reducers/ToastMessageReducer";
import {setSalesPersonSelectedTab} from "../../../../store/reducers/SalespersonReducer";
import Support from "../Support";




import "./index.css";
import Logo from "../../../../assets/Taxraven.svg";
import SuperAdminIcon from "../../../../assets/SuperAdminIcon.svg";
import salesPersonIcon from "../../../../assets/SalespersonIcon.svg";
import CompanyAdminIcon from "../../../../assets/CompanyAdminIcon.svg";
import SalespersonManagerIcon from "../../../../assets/SalespersonManagerIcon.svg"
import LogoutIcon from "../../../../assets/LogoutIcon.svg"
import closeOutline from "../../../../assets/closeOutline.svg"
import mail from "../../../../assets/mail.svg"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  logout,
  setCurrentRole
  // setAuthorisedUser,
  // setIsAuthorised,
} from "../../../../store/reducers/AppReducer";
import CircularProgress from "@mui/material/CircularProgress";
import { useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, IconButton, Box, Typography } from '@mui/material';
import RButton from '@mui/material/Button';

const Header = (props) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentRole = useSelector((state)=>state.App.currentRole)
  const authorisedUser = useSelector((state) => state.App.authorisedUser);
  const name = useSelector((state)=> state.App.userName);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataFetchStatus, setDataFetchStatus] = useState("")
  const open = Boolean(anchorEl);
  const [tabsMenuopen, setTabsMenuOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      const response = await axiosInstance.post(`/account/logout`, {});
      if (response) {
        localStorage.removeItem("user");
        localStorage.removeItem("userName");
        handleClose();
        dispatch(logout());
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCurrentRole()
  }, []);
  const findUserIcon = () => {
    switch (currentRole) {
      case "Salesperson":
        return  salesPersonIcon ;
      case "SuperAdmin":
        return SuperAdminIcon ;
      case "Manager":
        return  SalespersonManagerIcon ;
      case "CompanyAdmin":
        return  CompanyAdminIcon ;
      default:
        return  SuperAdminIcon ;
    }
  };
  const roles = () => {
    const roles = [];
    authorisedUser.roles.map((item, index) => {
      switch (item) {
        case "Salesperson":
          return roles.push({
            name: "Salesperson",
            to: "/salesperson/findBusinavTab",
            key: "Salesperson",
            icon: salesPersonIcon
          });
        case "SuperAdmin":
          return roles.push({
            name: "Super admin",
            to: "/superadmin/dashboard/companies",
            key: "SuperAdmin",
            icon: SuperAdminIcon
          });
        case "Manager":
          return roles.push({
            name: "Manager",
            to: "/manager/dashboard/users",
            key: "Manager",
            icon: SalespersonManagerIcon
            
          });
        case "CompanyAdmin":
          return roles.push({
            name: "Company Admin",
            to: "/companyadmin/dashboard/users",
            key: "CompanyAdmin",
            icon: CompanyAdminIcon
          });
        default:
          return roles.push({
            name: "",
            to: "",
            key: "",
            icon: ""
          });
      }
    });
    return roles;
  };

  const handleNavigation =async (role) => {
    setDataFetchStatus("TRIGGERED")
    try {
      const response = await axiosInstance.put(`/users/currentRole/${role.key}`);
      if (response) {
        localStorage.setItem("currentRole", role.key);
        if(role.key === 'Salesperson') {
          dispatch(setSalesPersonSelectedTab(0));
        } else {
          dispatch(setSelectedTab(0));
        }
        dispatch(setCurrentRole(role.key));
        navigate(role.to);
        setDataFetchStatus('FETCHED_SUCCESSFULLY')
      }
    } catch (error) {
      let errorMsg = error?.response?.data?.title;
      dispatch(
        showToast({
          type: "error",
          message: errorMsg || `Failed to switch the account. Please try again`,
        })
      );
      setDataFetchStatus('FAILED_TO_FETCH')
      console.log(error);
    }
    // window.location.reload()
  };

  const getCurrentRole =async ()=> {
    try {
      const response = await axiosInstance.get(`/users/currentRole`)
      if(response){
        console.log('124:',response);
        if(response.data !== 'None') {
          localStorage.setItem("currentRole", response.data);
          dispatch(setCurrentRole(response.data));
        }
      }
    } catch(error) {
      console.log(error)
    }
  }

  const toggleDrawer = (event,open) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setTabsMenuOpen(open);
  }

  const handleTabChange = (event, newValue) => {
    dispatch(props.setSelectedTab(newValue.key));
    setTabsMenuOpen(false);
  };

  const navigateToDashboard = ()=>{
    dispatch(setSelectedTab(0));
    dispatch(setSalesPersonSelectedTab(0));
    switch (currentRole) {
      case "SuperAdmin":
        return navigate("/superadmin/dashboard/companies");
      case "Salesperson":
        return navigate("/salesperson/findBusinavTab");
      case "Manager":
        return navigate("/manager/dashboard/users");
      case "CompanyAdmin":
        return navigate("/companyadmin/dashboard/users");
      default:
      return "";
    }
  }

  
  return (
    <div
      className={`hidden-on-print header-wrapper d-lg-flex d-md-flex d-flex justify-content-between align-items-center ${
        isMobile ? " px-3" : " px-5"
      }`}
    >
      {isMobile && props.tabs && props.tabs.length && (
        <div>
          <MenuIcon onClick={(e) => toggleDrawer(e, true)}></MenuIcon>
        </div>
      )}
      <div className="text-center mb-lg-0 mb-md-0">
        <img src={Logo} alt="logo" width={95} height={!isMobile ? 40 : null} className="cursor-pointer" onClick={navigateToDashboard}/>
      </div>

      <div
        className={` d-flex  align-items-center  py-2 gap-2 ${
          isMobile ? "" : " p-2 "
        }`}
      > 
      {/* <div className={`${!isMobile  ? 'px-2 admin-controls' : '' }`}>

        <RButton
          variant="outlined"
          size={isMobile ? 'small' : 'medium'}
          className=" my-auto"
          color="secondary"
          sx={{
            textTransform: "none",
            borderRadius: "8px",
          }}
          onClick={()=>setIsPopupOpen(true)}
        >
          <img src={mail} alt="mail" className="px-1"></img> Support
        </RButton>
      </div> */}

        <img
          src={findUserIcon()}
          alt="SuperAdminIcon"
          width={`${isMobile ? "24px" : "40px"}`}
          height={`${isMobile ? "24px" : "40px"}`}
          onClick={(e) => (isMobile ? handleClick(e) : "")}
        />
        {!isMobile && <p className="admin-name m-0 fw-bold">{name}</p>}
        <div>
          {!isMobile && (
            <ExpandMoreIcon
              onClick={handleClick}
              className="control-icon align-self-center"
            />
          )}
          <Menu
            anchorEl={anchorEl}
            id="header-account-menu"
            className="header-account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 2.2,
                  border: "1px solid #EBEBEC",
                  // borderRadius: "16px",
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 45,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {roles()?.length && roles().length > 1 && (
              <div>
                <div className="p-3 font-14 font-700 text-lightgrey">
                  Change role
                </div>
                {roles().map((role, index) => {
                  return (
                    <div>
                      <MenuItem
                        key={index}
                        onClick={() => handleNavigation(role)}
                        className={`px-3 py-2 ${
                          currentRole === role.key ? "selected-role" : ""
                        }`}
                      >
                        <img
                          src={role.icon}
                          alt="SuperAdminIcon"
                          width="24px"
                          height="24px"
                          className="m-right"
                        />{" "}
                        {role.name}
                      </MenuItem>
                      <hr className="m-0 p-0"></hr>
                    </div>
                  );
                })}
                <hr></hr>
              </div>
            )}
            <MenuItem onClick={handleLogout}>
              <img
                src={LogoutIcon}
                alt="SuperAdminIcon"
                width="24px"
                height="24px"
                className="m-right"
              />
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
      {dataFetchStatus === "TRIGGERED" && (
        <>
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        </>
      )}
      <Support
        isPopupOpen={isPopupOpen}
        onCancel={() => setIsPopupOpen(false)}
      ></Support>
      <Drawer
        anchor="top"
        open={tabsMenuopen}
        onClose={(e) => toggleDrawer(e, false)}
        PaperProps={{
          style: {
            width: "100%",
            height: isMobile ? "100%" : "auto",
          },
        }}
      >
        <Box
          className="tab-menu-bg"
          // display: 'flex',
          // flexDirection: 'column',
          // alignItems: 'center',
          // justifyContent: 'center',
          sx={{
            height: "100%",
          }}
        >
          <MenuItem className="d-flex justify-content-between py-3 px-2">
            <div className="fw-bold font-24">Menu</div>
            <div>
              <img
                src={closeOutline}
                alt="close"
                onClick={(e) => toggleDrawer(e, false)}
              ></img>
            </div>
          </MenuItem>
          <hr className="m-0 p-0"></hr>
          {props.tabs &&
            props.tabs.length > 1 &&
            props.tabs.map((tab, index) => {
              return (
                <div>
                  <MenuItem
                    key={index}
                    onClick={(e) => handleTabChange(e, tab)}
                    className={`px-3 py-2 ${
                      props.selectedTab === tab.key ? "selected-role" : ""
                    }`}
                  >
                    {tab.name}
                  </MenuItem>
                  <hr className="m-0 p-0"></hr>
                </div>
              );
            })}
        </Box>
      </Drawer>
    </div>
  );
};

export default Header;
