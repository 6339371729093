import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import CustomSingleSelect from "../../components/CommonComponents/SingleSelect";
import Checkbox from "../../components/CommonComponents/Checkbox";
import { showToast } from "../../../store/reducers/ToastMessageReducer";
import ConfirmationPopup from "../../components/CommonComponents/ConfirmationPopup";
import SelectComp from "../../components/CommonComponents/SelectComp";

import "./index.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { setSelectedTab } from "../../../store/reducers/SuperAdminReducer";
import Header from "../../components/CommonComponents/Header";
import Input from "../../components/CommonComponents/InputField";
import Button from "../../components/CommonComponents/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axiosInstance from "../../../Fbaseurl";
import QuestionCircle from "../../../assets/QuestionCircle.svg";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CircularProgress from "@mui/material/CircularProgress";

const CompanySetUpForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, type } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('page');
  const currentUserEmail = localStorage.getItem('userId')

  const currentRole = useSelector((state) => state.App.currentRole);
  const [isLoading, setIsLoading] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [managersList, setManagersList] = useState([]);
  const [screen, setScreen] = useState(1);
  const [companiesTotalPages, setCompaniesTotalPages] = useState(null);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    userEmail: "",
    companiesAssigned: [],
    managerId: null,
    isActive: true,
    roleAssignments: {
      isCompanyAdmin: false,
      isManager: false,
      isSalesperson: false,
    },
  });
  const [confirmationContent, setConfirmationContent] = useState(
    ""
  );
  const [isPopupOpen, setPopup] = useState(false);
  const [isEmailConfirmed, setisEmailConfirmed] = useState(null);
  const [confirmationType,setConfirmationType] = useState('');
  const [email, setEmail] = useState('');
  const [dataFetchStatus, setDataFetchStatus] = useState("");
  // const companies = useSelector((state) => state.SaCompany.saCompniesList);

  const getCompaniesList = async (payload={}) => {

    try {
      const response = await axiosInstance.get(`/companies`, {
        params: { ...payload, active: true },
      });
      if (response.status === 200) {
        const updatedCompaniesList = response.data.items.reduce((acc, cur) => {
          if (cur) {
            acc.push({
              id: cur.companyId,
              key: cur.companyName,
              // status: cur.isActive ? "Active" : "Inactive",
              // // actions: () => navigate(`/superadmin/company/:${cur.companyId}`),
              // actions: "",
            });
          }
          return acc;
        }, []);
        setCompaniesTotalPages(response.data.totalPages);
        setCompaniesList(updatedCompaniesList);
        return updatedCompaniesList;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserInfo = async (companies) => {
    setDataFetchStatus("TRIGGERED");
    try {
      const response = await axiosInstance.get(`/users/${id}`);
      if (response.status === 200) {
        // let selCompny = companies.find((comp, index) => {
        //   return comp.key === response.data.companyName;
        // });
        setInitialValues({
          userEmail: response.data.userName,
          firstName: `${response.data.firstName}`,
          lastName: `${response.data?.lastName}`,
          companiesAssigned: response.data.companyId,
          isActive: !response.data.isActive,
          managerId: response.data.managerId,
          roleAssignments: {
            isCompanyAdmin: response.data.roles.includes("CompanyAdmin"),
            isManager: response.data.roles.includes("Manager"),
            isSalesperson: response.data.roles.includes("Salesperson"),
          },
        });
        setisEmailConfirmed(response.data.isEmailConfirmed);
        if(!response.data.isEmailConfirmed) {
          setEmail(response.data.userName);
        }
        setDataFetchStatus("FETCHED_SUCCESSFULLY");
      }
    } catch (error) {
      console.log(error);
      setDataFetchStatus("FAILED_TO_FETCH");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const companies = await getCompaniesList();
      if (id) {
        await getUserInfo(companies);
      } else {
        setDataFetchStatus("FETCHED_SUCCESSFULLY");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if(companiesTotalPages > 1) {
      let pageSize = 50*companiesTotalPages;
      let payload = {
        page: 1,
        pageSize: pageSize
      }
       getCompaniesList(payload);
    }
   }, [companiesTotalPages]);

  const userCretionSchema = Yup.object().shape({
    firstName: Yup.string().trim().required("First Name is required"),
    lastName: Yup.string().trim().required("Last Name is required"),
    userEmail: Yup.string().trim()
      .required("Email is required")
      .matches(/.+@.+\..+/, "Invalid email"),
    // companiesAssigned: Yup.object().shape({}).required("Company is required"),
    companiesAssigned: Yup.string().required("Company assigned is required"),
    roleAssignments: Yup.object().test(
      "at-least-one-role",
      "At least one role must be selected",
      (value) => Object.values(value).some((v) => v === true)
    ),
    /* companiesAssigned: Yup.string().required("Company Assigned is required")*/
    //userEmail: Yup.string()
    //  .required("Email is required")
    //  .email("Invalid email"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: userCretionSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      if (Object.keys(formik.errors).length === 0) {
        setIsLoading(true);
        // let fullName = formik.values.userName.trim();
        // let name = fullName.split(" ");
        let payload = {
          firstName: formik.values.firstName || "",
          lastName: formik.values.lastName || "",
          email: id ? email : formik.values.userEmail,
          companyId: formik.values?.companiesAssigned || null,
          isActive: id ? !formik.values.isActive : true,
          roleAssignments: formik.values.roleAssignments,
          managerId: formik.values.managerId,
        };
        if(id && !isEmailConfirmed && email !== formik.values.userEmail) {
          payload.newEmailAddress = formik.values.userEmail
        }
        let url = id ? `/users/${id}` : `/users`;
        try {
          const response = id
            ? await axiosInstance.patch(url, payload)
            : await axiosInstance.post(url, payload);
          if (response) {
            setIsLoading(false);
            if(id && !isEmailConfirmed) {
             await resendConfimrationMail()
            }
            dispatch(
              showToast({
                message: `${formik.values.firstName} ${
                  formik.values.lastName
                } ${id ? "updated" : "added"} successfully!`,
              })
            );
            // if (currentRole === "")
              // navigate("/superadmin/dashboard");
              // dispatch(setSelectedTab(1));
              navigateToDashboard();
          }
        } catch (error) {
          setIsLoading(false);
          let errorMsg = error?.response?.data?.errors?.DuplicateEmail
            ? error.response.data.errors.DuplicateEmail
            : error?.response?.data?.title;

          dispatch(
            showToast({
              type: "error",
              message:
                errorMsg ||
                `Failed to ${id ? "update" : "add"} ${
                  formik.values.firstName
                } ${formik.values.lastName}`,
            })
          );
          console.log(error);
        }
      }
    },
  });

  const resendConfimrationMail = async() => {
    try {
        const response = await axiosInstance.post(`/account/resendConfirmationEmail`, {
          email: formik.values.userEmail
        })
    }
    catch(error) {
      console.log(error);
    }
  }

  const navTitle = () => {
    if (screen === 1) {
      if (!id) {
        return `User Setup`;
      } else {
        return type === "view" ? "View" : "Edit";
      }
    }
    if (screen === 2) {
      return "Assign Role";
    }
  };

  const cancelConfirmation = () => {
    setPopup(true);
    setConfirmationContent('Are you sure you want to Cancel ?');
    setConfirmationType('CANCEL')
  };

  const onConfirmCancel = () => {
    // navigate("/superadmin/dashboard");
    // dispatch(setSelectedTab(1));
    navigateToDashboard();
    setPopup(false);
  };

  const onCancelClick = () => {
    setPopup(false);
  };

  const onConfirm = ()=> {
    if(confirmationType === 'CANCEL') {
      onConfirmCancel();
    } else {
      formik.handleSubmit();
      setPopup(false);
    }
  }

  const backNavigation = () => {
    if (screen === 1) {
      navigateToDashboard();
    } else {
      setScreen(1);
    }
  };

  const navigateToDashboard = () => {
    if (currentRole === "CompanyAdmin") {
      navigate(`/companyadmin/dashboard/users?page=${page}`);
      dispatch(setSelectedTab(0));
    } else if (currentRole === "Manager") {
      navigate(`/manager/dashboard/users?page=${page}`);
      dispatch(setSelectedTab(0));
    } else {
      navigate(`/superadmin/dashboard/users?page=${page}`);
      dispatch(setSelectedTab(1));
    }
  };

  const onEditClick = () => {
    if (currentRole === "CompanyAdmin") {
      navigate(`/companyadmin/user/edit/${id}?page=${page}`);
    } else if (currentRole === "Manager") {
      navigate(`/manager/user/edit/${id}?page=${page}`);
    } else {
      navigate(`/superadmin/user/edit/${id}?page=${page}`);
    }
  };

  const handleCheckboxChange = (fieldName) => (event) => {
    const { checked } = event.target;
    formik.setFieldValue(`roleAssignments.${fieldName}`, checked);
    
   if (
     !formik.values.roleAssignments.isSalesperson &&
     !formik.values.roleAssignments.isManager
   ) {
     formik.setFieldValue("managerId", null);
   }
  };

  const fetchManagers = async () => {
    if(id) {
      var payload ={
        userIdToExclude: id
      }
    }
    try {
      const response = await axiosInstance.get(
        `/users/managers/${formik.values.companiesAssigned}`, {
          params: payload
        }
      );
      console.log("222", response);
      const updatedManagersList = response.data.reduce((acc, cur) => {
        if (cur) {
          acc.push({
            id: cur.userId,
            key: `${cur.firstName} ${cur.lastName}`,
            // status: cur.isActive ? "Active" : "Inactive",
            // // actions: () => navigate(`/superadmin/company/:${cur.companyId}`),
            // actions: "",
          });
        }
        return acc;
      }, []);
      setManagersList(updatedManagersList);
      return updatedManagersList;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      let errorMsg = error?.response?.data?.title;

      dispatch(
        showToast({
          type: "error",
          message: errorMsg || `Failed to fetch the managers `,
        })
      );
    }
  };

  const validateScreen = () => {
    const emailRegex = /.+@.+\..+/;
    return !(
      formik.values.firstName &&
      formik.values.lastName &&
      formik.values.userEmail && 
     emailRegex.test( formik.values.userEmail) &&
      !Array.isArray(formik?.values?.companiesAssigned) &&
      formik.values.companiesAssigned
    );
  };
  const confirmationToSave = async() => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      formik.setTouched({
        ...formik.touched,
        ...Object.keys(errors).reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {}
        ),
      });
      return;
    }
    if(isEmailConfirmed) {
      formik.handleSubmit()
    } else {
      setPopup(true);
      setConfirmationContent(`Are you sure you want to ${id ? ' resend' : ' send'} an Email?`);
      setConfirmationType('SUBMIT');
    }
  }
  useEffect(() => {
    let selectedCompany = localStorage.getItem("companyAssigned");
    if (currentRole === "CompanyAdmin" && selectedCompany) {
      formik.setFieldValue("companiesAssigned", selectedCompany);
    }
    if (
      !Array.isArray(formik?.values?.companiesAssigned) &&
      formik.values.companiesAssigned
    ) {
      fetchManagers();
    }
  }, [formik.values.companiesAssigned]);
  return (
    <div className="form-wrapper">
      <Header />
      {dataFetchStatus === "FETCHED_SUCCESSFULLY" &&
      <div className="company-form p-4">
        <div className="form-back-control d-flex align-items-center justify-content-between gap-4 mx-4">
          <div className="d-flex align-items-center gap-4">
            <ArrowBackIcon
              className="back-btn"
              onClick={() => backNavigation()}
            />
            <p className="fw-bold fs-4 m-0">{navTitle()}</p>
          </div>
          {type === "view" &&
            currentRole !== "Manager" &&
            currentUserEmail !== formik.values.userEmail && (
              <Button
                className="float-end"
                color="primary"
                isLoading={isLoading}
                label="Edit"
                icon={<EditOutlinedIcon></EditOutlinedIcon>}
                onClick={onEditClick}
              />
            )}
        </div>
        <div className="form-container p-4 m-4 d-flex">
          <form
            onSubmit={formik.handleSubmit}
            className="row d-flex d-lg-flex d-md-flex flex-column gap-4 form-group w-100"
          >
            {/* {JSON.stringify(formik.values)} */}
            {screen === 1 && (
              <>
                <div className="d-lg-flex d-md-flex align-items-center gap-4">
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <Input
                      placeholder="Enter name"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      type="text"
                      name="firstName"
                      label="First Name"
                      className="col-lg-5 col-md-6 col-sm-12 mb-3 mb-md-auto mb-lg-auto"
                      title="First Name "
                      isRequired={true}
                      disabled={type === "view" ? true : false}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div className="error px-1">
                        {formik.errors.firstName}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <Input
                      placeholder="Enter name"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      type="text"
                      name="lastName"
                      label="Last Name"
                      className="col-lg-5 col-md-6 col-sm-12 mb-3 mb-md-auto mb-lg-auto"
                      title="Last Name "
                      isRequired={true}
                      disabled={type === "view" ? true : false}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <div className="error px-1">{formik.errors.lastName}</div>
                    )}
                  </div>
                </div>
                <div className="d-lg-flex d-md-flex align-items-center gap-4">
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <Input
                      placeholder="Enter email id"
                      value={formik.values.userEmail}
                      onChange={formik.handleChange}
                      type="text"
                      name="userEmail"
                      label=" User Email"
                      className="col-lg-5 col-md-6 col-sm-12 mb-3 mb-md-auto mb-lg-auto"
                      title="User Email"
                      isRequired={true}
                      disabled={
                        type === "view" || (id && isEmailConfirmed)
                          ? true
                          : false
                      }
                    />
                    {formik.touched.userEmail && formik.errors.userEmail && (
                      <div className="error px-1">
                        {formik.errors.userEmail}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-3 ">
                    {/* <CustomSingleSelect
                  name="companiesAssigned"
                  placeholder="Select your company"
                  onChange={(value) =>
                    formik.setFieldValue("companiesAssigned", value)
                  }
                  selectedValue={formik.values.companiesAssigned}
                  label="Companies Assigned"
                  isRequired={true}
                  className="col-lg-5 col-md-6 col-sm-12 mb-3 mb-md-0 mb-lg-0"
                  options={companiesList}
                  disabled={type === "view" ? true : false}
                /> */}

                    <SelectComp
                      title="Companies Assigned"
                      value={formik.values.companiesAssigned}
                      options={companiesList}
                      onChange={(event) => {
                        const {
                          target: { value },
                        } = event;
                        formik.setFieldValue("companiesAssigned", value);
                      }}
                      name="companiesAssigned"
                      isRequired={true}
                      isMultiSelect={false}
                      disabled={
                        type === "view" || currentRole === "CompanyAdmin"
                          ? true
                          : false
                      }
                    ></SelectComp>

                    {formik.touched.companiesAssigned &&
                      formik.errors.companiesAssigned && (
                        <div className="error px-1">
                          {formik.errors.companiesAssigned}
                        </div>
                      )}
                  </div>
                </div>
              </>
            )}
            {screen === 2 && (
              <>
                <div>
                  <div className="role-title pb-2">Select Role</div>
                  <div className="d-lg-flex d-md-flex align-items-center ">
                    {/* <div className="col-lg-12 col-md-12 col-sm-12 mb-3 d-lg-flex d-md-flex gap-4"> */}
                    {/* {JSON.stringify(formik.values)} */}
                    <div className="col-4">
                      <div className="role-card p-4 m-right">
                        <Checkbox
                          label="Salesperson"
                          checked={formik.values.roleAssignments.isSalesperson}
                          onChange={handleCheckboxChange("isSalesperson")}
                          name="isSalesperson"
                          className="mt-3 mt-md-0 mt-lg-0"
                          disabled={type === "view" ? true : false}
                        />
                      </div>
                    </div>
                    <div className="col-4 ">
                      <div className="role-card p-4 m-right">
                        <Checkbox
                          label="Manager"
                          checked={formik.values.roleAssignments.isManager}
                          onChange={handleCheckboxChange("isManager")}
                          name="isManager"
                          className="mt-3 mt-md-0 mt-lg-0"
                          disabled={type === "view" ? true : false}
                        />
                      </div>
                    </div>
                    <div className="col-4 ">
                      <div className="role-card p-4">
                        <Checkbox
                          label="Company admin"
                          checked={formik.values.roleAssignments.isCompanyAdmin}
                          onChange={handleCheckboxChange("isCompanyAdmin")}
                          name="isCompanyAdmin"
                          className="mt-3 mt-md-0 mt-lg-0"
                          disabled={type === "view" ? true : false}
                        />
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                  {/* {formik.touched.roleAssignments &&
                    formik.errors.roleAssignments && (
                      <div className="error px-1">
                        {formik.errors.roleAssignments}
                      </div>
                    )} */}
                  {formik.touched.roleAssignments &&
                    !formik.values.roleAssignments.isSalesperson &&
                    !formik.values.roleAssignments.isManager &&
                    !formik.values.roleAssignments.isCompanyAdmin && (
                      <div className="error px-1">
                        Atleast one role must be selected
                      </div>
                    )}
                </div>
                {(formik.values.roleAssignments.isSalesperson ||
                  formik.values.roleAssignments.isManager) && (
                  <div>
                    <div className="role-title pb-2">Select Manager</div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                      <SelectComp
                        title="Manager"
                        value={formik.values.managerId}
                        options={managersList}
                        onChange={(event) => {
                          const {
                            target: { value },
                          } = event;
                          formik.setFieldValue("managerId", value);
                        }}
                        name="managerId"
                        isRequired={false}
                        isMultiSelect={false}
                        disabled={type === "view" ? true : false}
                      ></SelectComp>
                    </div>
                  </div>
                )}
              </>
            )}
            {/* {( id &&
              <Checkbox
                label="Deactivate User"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                name="isActive"
                className="mt-3 mt-md-0 mt-lg-0"
                disabled={type === "view" ? true : false}
              />
            )} */}

            <div className="card-footer d-flex justify-content-end align-items-center align-self-end gap-3 mt-auto">
              {type !== "view" && (
                <Button
                  label="Cancel"
                  onClick={() => {
                    cancelConfirmation();
                  }}
                  className="fw-bold"
                  type="button"
                  color="primary"
                  variant="text"
                />
              )}
              {screen === 1 && (
                <Button
                  color="primary"
                  type="button"
                  label="Next"
                  onClick={() => {
                    setScreen(2);
                  }}
                  disabled={validateScreen()}
                />
              )}
              {screen === 2 && type !== "view" && (
                <Button
                  color="primary"
                  isLoading={isLoading}
                  label={id && isEmailConfirmed ? "Save" : "Submit"}
                  onClick={confirmationToSave}
                />
                // type="submit"
              )}
            </div>
          </form>
        </div>
      </div>}
      {dataFetchStatus === "TRIGGERED" && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
      <ConfirmationPopup
        isPopupOpen={isPopupOpen}
        content={confirmationContent}
        icon={QuestionCircle}
        onCancel={onCancelClick}
        onConfirm={onConfirm}
      ></ConfirmationPopup>
    </div>
  );
};

export default CompanySetUpForm;
