import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../../components/CommonComponents/Button";
import useDebounce from "../../../Hooks/useDebounce";
import axiosInstance from "../../../Fbaseurl";
import { showToast } from "../../../store/reducers/ToastMessageReducer";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../components/CommonComponents/Table";
import "./index.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Search from "../../components/CommonComponents/Search";
import Filter from "../../components/CommonComponents/Filter";
import ConfirmationPopup from "../../components/CommonComponents/ConfirmationPopup";
import PersonDeactivate from "../../../assets/PersonDeactivate.svg";
import PersonActivate from "../../../assets/PersonActivate.svg";
import CircularProgress from "@mui/material/CircularProgress";

const UsersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentRole = useSelector((state) => state.App.currentRole);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = Number(queryParams.get('page'));
  const [selectedPage, setSelectedPage] = useState(page || 1);
  const [totalPages, setTotalPages] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [searchUser, setSearchUser] = useState("");
  const [showActiveUsers, setShowActiveUsers] = useState(false);
  const [showInactiveUsers, setShowInactiveUsers] = useState(false);
  const [showPendingUsers, setShowPendingUsers] = useState(false);
  const debouncedSearchKey = useDebounce(searchUser, 500);
  const [currentUser, setCurrentUser] = useState(null);
  const [confirmationContent, setConfirmationContent] = useState("");
  const [isPopupOpen, setPopup] = useState(false);
  const [dataFetchStatus, setDataFetchStatus] = useState("");
  const currentUserEmail = localStorage.getItem('userId')

  const getUsersList = async (payload = {}) => {
    setDataFetchStatus("TRIGGERED");
    const payloadWithSearch = {
      page: selectedPage,
      pageSize: 10,
      // search: debouncedSearchKey,
      ...payload,
    };
    console.log(showPendingUsers, ',,,,')
    if(!showActiveUsers && !showInactiveUsers && !showPendingUsers) {
      payloadWithSearch.active = null;
      payloadWithSearch.verified = null;
    } else {
      if(showActiveUsers && showInactiveUsers) {
        if(showPendingUsers) {
          payloadWithSearch.verified = null;
        } else {
          payloadWithSearch.verified = true;
        }
        payloadWithSearch.active = null;
     }
    //  else if (
    //    (!showActiveUsers && showInactiveUsers) ||
    //    (showActiveUsers && !showInactiveUsers)
    //  ) {
    //    payload.active = showActiveUsers;
    //  }
    //  if(showPendingUsers) {
    //   payloadWithSearch.verified = !showPendingUsers;
    //  } else {
    //   payloadWithSearch.verified = null;
    //  }
    }
    // if(showPendingUsers) {
    //   payloadWithSearch.verified = !showPendingUsers
    // } 
    if (debouncedSearchKey && debouncedSearchKey.length > 2) {
      payloadWithSearch.keyword = debouncedSearchKey;
    }
    try {
      const response = await axiosInstance.get("/users", {
        params: payloadWithSearch,
      });

      if (response.status === 200) {
        const updatedUsersList = response.data.items.reduce((acc, cur, ind) => {
          if (cur) {
            acc.push({
              id: cur.userId,
              userName: cur.lastName
                ? `${cur.lastName}, ${cur.firstName}`
                : cur.firstName,
              companyName: cur.companyName,
              userRole: cur.roles,
                // cur.roles[0] === "SuperAdmin" ? "Super Admin" : "Salesperson",
              status: !cur.isEmailConfirmed ? 'Pending' : cur.isActive ? "Active" : "Inactive",
              actions: "",
              isActive: cur.isActive,
              disable: cur.userName === currentUserEmail ? true : false
              // onrowClick: navigate(`/superadmin/user/${cur.userId}`)

              // actions: () => navigate(`/superadmin/company/:${cur.userId}`),
            });
          }
          return acc;
        }, []);
        setTotalPages(response.data.totalPages);
        setUsersList([...updatedUsersList]);
        setDataFetchStatus("FETCHED_SUCCESSFULLY");
      } else {
        setUsersList([]);
        setTotalPages(null);
        setDataFetchStatus("FAILED_TO_FETCH");
      }
    } catch (error) {
      console.log(error);
      let errorMsg = error?.response?.data?.title;
      let msg = errorMsg
        ? errorMsg
        : `Failed to fetch the data. Please try again after some time`;
      dispatch(
        showToast({
          type: "error",
          message: msg,
        })
      );
      setDataFetchStatus("FAILED_TO_FETCH");
    }
  };

  const navigateToDashboard = () => {
    if (currentRole === "CompanyAdmin") {
      navigate(`/companyadmin/dashboard/users?page=${selectedPage}`);
    } else if (currentRole === "Manager") {
      navigate(`/manager/dashboard/users?page=${selectedPage}`);
    } else {
      navigate(`/superadmin/dashboard/users?page=${selectedPage}`);
    }
  };

  useEffect(() => {
    if (!debouncedSearchKey.length) {
      searchData();
    }
  }, [debouncedSearchKey]);
  useEffect(() => {
    if (currentRole !== "Salesperson") {
      let payload = {};
      if (
        (!showActiveUsers && showInactiveUsers) ||
        (showActiveUsers && !showInactiveUsers)
      ) {
        payload.active = showActiveUsers;
      }
      if(showPendingUsers) {
        payload.verified = !showPendingUsers;
      }
      getUsersList(payload);
      navigateToDashboard();
    }
  }, [selectedPage]);
  useEffect(() => {
    if (currentRole !== "Salesperson" && currentRole !== "SuperAdmin") {
      let payload = {};
      if (
        (!showActiveUsers && showInactiveUsers) ||
        (showActiveUsers && !showInactiveUsers)
      ) {
        payload.active = showActiveUsers;
      }
      if(showPendingUsers) {
        payload.verified = !showPendingUsers;
      }
      getUsersList(payload);
      navigateToDashboard();
    }
  }, [currentRole]);

  const handleApply = () => {
    let payload = {};
    if (debouncedSearchKey) {
      payload.keyword = debouncedSearchKey;
    }
    setSelectedPage(1);
    if (
      (!showActiveUsers && showInactiveUsers) ||
      (showActiveUsers && !showInactiveUsers)
    ) {
      payload.active = showActiveUsers;
    }
    if(showPendingUsers) {
      payload.verified = !showPendingUsers;
    }
    if (selectedPage == 1) {
      getUsersList(payload);
    }
  };

  const handleClear = () => {
    if(showPendingUsers || (!showActiveUsers && showInactiveUsers) ||
    (showActiveUsers && !showInactiveUsers)) {
      if(selectedPage === 1) {
      getUsersList();
    }
  }
    setShowActiveUsers(false);
    setShowInactiveUsers(false);
    setShowPendingUsers(false)
    setSelectedPage(1);
    // getUsersList();
  };

  const editHandling = (row) => {
    if (currentRole === "CompanyAdmin") {
      navigate(`/companyAdmin/user/edit/${row.id}?page=${selectedPage}`);
    } else {
      navigate(`/superadmin/user/edit/${row.id}?page=${selectedPage}`);
    }
  };

  const navigateToView = (row) => {
    if (currentRole === "CompanyAdmin") {
      navigate(`/companyAdmin/user/view/${row.id}?page=${selectedPage}`);
    } else if (currentRole === "Manager") {
      navigate(`/manager/user/view/${row.id}?page=${selectedPage}`);
    }else {
      navigate(`/superadmin/user/view/${row.id}?page=${selectedPage}`);
    }
  };

  const activationHandling = async (row) => {
    let payload = {
      isActive: !currentUser.isActive,
    };
    try {
      const response = await axiosInstance.patch(
        `/users/${currentUser.id}`,
        payload
      );
      if (response.status === 200) {
        let status = !currentUser.isActive ? "Active" : "Inactive";
        // setTotalPages(Math.cell(response.data));
        dispatch(
          showToast({
            message: `${currentUser.userName} ${
              currentUser.isActive ? "deactivated" : "activated"
            } successfully!`,
          })
        );
        setUsersList((prevUsers) => {
          return prevUsers.map((user) =>
            user.id === currentUser.id
              ? { ...user, isActive: !currentUser.isActive, status: status }
              : user
          );
        });
      }
    } catch (error) {
      let errorMsg = error?.response?.data?.title;
      let msg = errorMsg
        ? errorMsg
        : `Failed to ${currentUser.isActive ? "deactivate" : "activate"} ${
            currentUser.userName
          }`;
      dispatch(
        showToast({
          type: "error",
          message: msg,
        })
      );
      console.log(error);
    }
    setPopup(false);
  };

  const confirmActivation = (row) => {
    setCurrentUser(row);
    setPopup(true);
    let title = row.isActive
      ? "Are you sure you want to deactivate ?"
      : "Are you sure you want activate ?";
    setConfirmationContent(title);
  };

  const onCancelClick = () => {
    setPopup(false);
  };

  const setSearchValue = (e) => {
    setSearchUser(e.target.value);
    if (!e.target.value) {
      setSelectedPage(1);
    }
  };

  const searchData = () => {
    let payload = {};
    if (
      (!showActiveUsers && showInactiveUsers) ||
      (showActiveUsers && !showInactiveUsers)
    ) {
      payload.active = showActiveUsers;
    }
    if(showPendingUsers) {
      payload.verified = !showPendingUsers;
    }
    getUsersList(payload);
  };

  const applySearch = () => {
    if (searchUser.length > 2 || !searchUser) {
      setSelectedPage(1);
      searchData();
    }
  };

  const columns = () => {
    if (currentRole === "Manager") {
      return {
        userName: "Users",
        userRole: "Role",
        status: "Status",
      };
    } else if (currentRole === "CompanyAdmin") {
      return {
        userName: "Users",
        userRole: "Role",
        status: "Status",
        actions: "Actions",
      };
    } else {
      return {
        userName: "Users",
        companyName: "Company Name",
        userRole: "Role",
        status: "Status",
        actions: "Actions",
      };
    }
  };

  const navigateToUserSetup = () => {
    if (currentRole === "CompanyAdmin") {
      navigate("/companyadmin/usersetup");
    } else if (currentRole === "Manager") {
      navigate("/manager/usersetup");
    } else {
      navigate("/superadmin/usersetup");
    }
  };

  return (
    <div className="table-content">
      {dataFetchStatus === "FETCHED_SUCCESSFULLY" && (
        <>
          <div className="filters mb-3 d-lg-flex d-md-flex justify-content-between align-items-center">
            <div className="d-flex flex-column flex-md-row  justify-content-between gap-4 mb-4 mb-md-0 mb-lg-0">
              <Search
                value={searchUser}
                onChange={(e) => setSearchValue(e)}
                onClick={applySearch}
              />
              <Filter
                handleApply={handleApply}
                handleClear={handleClear}
                filters={[
                  {
                    type: "Type",
                    options: [
                      {
                        name: "Active",
                        value: showActiveUsers,
                        onChange: setShowActiveUsers,
                      },
                      {
                        name: "Inactive",
                        value: showInactiveUsers,
                        onChange: setShowInactiveUsers,
                      },
                      {
                        name: "Pending",
                        value: showPendingUsers,
                        onChange: setShowPendingUsers,
                      },
                    ],
                  },
                ]}
              />
            </div>
            {currentRole !== "Manager" && (
              <Button
                label="Add User"
                icon={<AddCircleOutlineIcon />}
                type="button"
                color="primary"
                onClick={navigateToUserSetup}
              />
            )}
          </div>
          <CustomTable
            name="usersList"
            columns={columns()}
            data={usersList}
            showCount={true}
            title="user"
            editHandling={editHandling}
            activationHandling={confirmActivation}
            handleRowClick={navigateToView}
            totalPages={totalPages}
            pagination={true}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
        </>
      )}
      {dataFetchStatus === "TRIGGERED" && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
      <ConfirmationPopup
        isPopupOpen={isPopupOpen}
        content={confirmationContent}
        icon={currentUser?.isActive ? PersonDeactivate : PersonActivate}
        onCancel={onCancelClick}
        onConfirm={activationHandling}
      ></ConfirmationPopup>
    </div>
  );
};

export default UsersList;
